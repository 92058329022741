import { ArrowRightSharp } from '@material-ui/icons';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import { SubCategoryProps } from '../interfaces';

// Style
import '../styles/SubCategoryStyles.scss';

// Components
import Product from './Product';

function SubCategory(props: SubCategoryProps): ReactElement {
  const {
    subCategory = null,
    partner,
    startsExpanded,
    isFromSearch = false,
    searchResponse = [],
    searchText = '',
  } = props;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const [expand, setExpand] = useState<boolean>(startsExpanded);

  if (expand) {
    if (isFromSearch) {
      return (
        <div className="sub-category-container">
          <div
            className="name-sub-category"
            onClick={() => setExpand(false)}
          >
            <p>
              <ArrowRightSharp className="icon" />
              {`${t('categoryView.search')} "${searchText}"`}
            </p>
          </div>
          <div className="products-grid">
            {searchResponse.map((product, i) => (
              <Product
                key={product.id}
                index={i}
                product={product}
                partner={partner}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className="sub-category-container">
        <div
          className="name-sub-category"
          onClick={() => setExpand(false)}
        >
          <p>
            <ArrowRightSharp className="icon" />
            {subCategory.title}
          </p>
        </div>
        <div className="products-grid">
          {subCategory.data.map((product, i) => (
            <Product
              key={product.id}
              index={i}
              product={product}
              partner={partner}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className="sub-category-container"
      onClick={() => setExpand(true)}
    >
      <div className="name-sub-category-grid">
        <p>
          <ArrowRightSharp className="icon2" />
          {(isFromSearch) ? `${t('categoryView.search')} "${searchText}"` : subCategory.title}
        </p>
      </div>
    </div>
  );
}

export default SubCategory;
