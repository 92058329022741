function validateRut(rutCompleto: string): boolean {
  if (!/^[1-9]{1}\d{0,2}.\d{3}.\d{3}-[\dkK]{1}$/.test(rutCompleto)) {
    return false;
  }
  const valor = rutCompleto.split('.').join('');
  const tmp = valor.split('-');
  let digv = tmp[1];
  const rut = tmp[0];
  if (digv === 'K') digv = 'k';
  // Calcular Dígito Verificador
  let suma = 0;
  let multiplo = 2;
  let i = 1;
  // Para cada dígito del Cuerpo
  for (i = 1; i <= rut.length; i += 1) {
    // Obtener su Producto con el Múltiplo Correspondiente
    const index = multiplo * parseInt(rut.charAt(rut.length - i), 10);
    suma += index;
    if (multiplo < 7) { multiplo += 1; } else { multiplo = 2; }
  }
  // Calcular Dígito Verificador en base al Módulo 11
  let dvEsperado: number | string = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  if (dvEsperado === 10) {
    dvEsperado = 'k';
  } else if (dvEsperado === 11) {
    dvEsperado = 0;
  }
  if (digv !== `${dvEsperado}`) {
    return false;
  }
  return true;
}

export default validateRut;
