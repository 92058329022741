import React, {
  ReactElement,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// Views
import LandingView from './views/LandingView';
import PartnersView from './views/PartnersView';
import LoginView from './views/LoginView';
import PasswordRecoveryView from './views/PasswordRecoveryView';
import SignUpView from './views/SignUpView';
import CatalogView from './views/CatalogView';
import CategoryView from './views/CategoryView';
import PurchasesHistoryView from './views/PurchasesHistoryView';
import NotFoundView from './views/NotFoundView';

// Store import
import { initialState, reducer } from './store/reducer';
import GlobalContext from './store/GlobalContext';
import getGlobalContext from './store/getGlobalContext';

// LocalStorage hook
import UseLocalStorage from './helpers/useLocalStorage';

// Components
import Loader from './components/Loader';

// Styles
import './styles/App.scss';

function App(): ReactElement {
  // Context
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalContext = getGlobalContext(dispatch);
  const { getCart } = globalContext;

  // Local storage
  const [localState] = UseLocalStorage('state', initialState);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (localState.token.length > 0) {
      getCart(localState.token);
    }

    dispatch({ type: 'INITIALIZE', data: localState });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 0) {
      if (window.location.pathname !== '/' && window.innerWidth < 768) {
        window.location.href = 'https://links.dealingapp.com/download';
      }
    }
  }, [window.innerWidth]);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <GlobalContext.Provider value={{ state, context: globalContext }}>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            {(state.token.length === 0)
              ? <LandingView />
              : <Redirect to="/partners" />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/login">
            {(state.token.length === 0)
              ? <LoginView />
              : <Redirect to="/partners" />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/signup">
            {(state.token.length === 0)
              ? <SignUpView />
              : <Redirect to="/partners" />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/password-recovery">
            {(state.token.length === 0)
              ? <PasswordRecoveryView />
              : <Redirect to="/partners" />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/partners">
            {(state.token.length === 0)
              ? <Redirect to="/" />
              : <PartnersView />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/catalog/:id">
            {(state.token.length === 0)
              ? <Redirect to="/" />
              : <CatalogView />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/catalog/:id/category/:idCategory/:idSubCategory?">
            {(state.token.length === 0)
              ? <Redirect to="/" />
              : <CategoryView />}
            {state.isLoading && <Loader />}
          </Route>
          <Route exact path="/purchases">
            {(state.token.length === 0)
              ? <Redirect to="/" />
              : <PurchasesHistoryView />}
            {state.isLoading && <Loader />}
          </Route>
          <Route path="*">
            <NotFoundView />
          </Route>
        </Switch>
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;
