import { useState, useLayoutEffect } from 'react';

export default function useMediaQuery(): number[] {
  const [screenSize, setScreenSize] = useState<number[]>([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
}
