import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Icons
import {
  SearchOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  InfoOutlined,
  SentimentDissatisfied,
  ExitToApp,
  Close,
  ChevronRight,
} from '@material-ui/icons';

// Interfaces
import {
  ContextInterface,
  PartnerInterface,
  StateInterface,
  ProductsByPartner,
} from '../interfaces';

// Styles
import '../styles/PartnersStyles.scss';

// Assets
import GlobalContext from '../store/GlobalContext';

// Components
import Alert from '../components/Alert';
import Sidebar from '../components/Sidebar';
import SidebarCheckout from '../components/SidebarCheckout';
import SearchResult from '../components/SearchResult';
import Navbar from '../components/Navbar';

// Helpers
import validatePassword from '../helpers/validatePassword';
import formatNumber from '../helpers/formatNumber';
import groupByPartner from '../helpers/groupByPartner';

function PartnersView(): ReactElement {
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const {
    getPartners,
    signOut,
    changePassword,
    search,
  } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  // State
  const [partners, setPartners] = useState<PartnerInterface[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [searchResponse, setSearchResponse] = useState<ProductsByPartner[]>([]);
  const [showSearchError, setShowSearchError] = useState<boolean>(false);
  const [showSearchEmpty, setShowSearchEmpty] = useState<boolean>(false);
  const [showGetProductError, setShowGetProductError] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [isvalidPassword, setIsValidPassword] = useState<boolean>(false);
  const [openEye, setOpenEye] = useState<boolean>(false);
  const [actualPartner, setActualPartner] = useState<PartnerInterface>();
  const [showPartner, setShowPartner] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const findPartner = (id: number) : PartnerInterface => partners.find((p) => p.id === id);
  const partnerIds = useMemo(() => partners?.map((p) => p.id), [partners]);

  // Change password
  async function handleSubmit() {
    if (password.length > 0) {
      const changed = await changePassword(state.token, password);
      if (changed) {
        setModalVisible(false);
      }
      setTimeout(() => { setModalVisible(!changed); }, 1500);
    }
  }

  function informationClick(partner: PartnerInterface) {
    setActualPartner(partner);
    setShowPartner(true);
  }

  function handleSubmitSearch() {
    search(searchText)
      .then((res) => {
        if (res.length > 0) {
          setSearchResponse(groupByPartner(res).filter((p) => partnerIds.includes(p.id)));
          setSearchActive(true);
        } else {
          setShowSearchEmpty(true);
        }
      })
      .catch(() => {
        setShowSearchError(true);
      });
  }

  function closeSearch() {
    setSearchText('');
    setSearchActive(false);
  }

  // Load Partners
  useEffect(() => {
    setModalVisible(state.passwordRecovery);
    getPartners(state.token)
      .then((response) => {
        setPartners(response);
      })
      .then(() => {
        setMounted(true);
      })
      .catch(() => {
        setMounted(true);
        setShowError(true);
      });
  }, []);

  useEffect(() => {
    if (validatePassword(password)) {
      setIsValidPassword(true);
    } else if (password) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(false);
    }
  }, [password]);

  useEffect(() => {
    if (showPartner) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('partner-modal');
        if (event.target === modal) {
          setShowPartner(false);
        }
      };
    }
  }, [showPartner]);

  useEffect(() => {
    if (searchText.length === 0) {
      closeSearch();
    }
  });

  if (partners.length === 0 && mounted) {
    return (
      <div className="partners-container">
        <Navbar />
        <div className="patners-content">
          <div className="info-bar">
            <div className="info1">
              <ChevronRight className="icon" />
              <div className="label-container">
                <p className="p-label">
                  {t('partnersView.message1')}
                </p>
              </div>
            </div>
            <div className="info2">
              <input
                type="text"
                className="search-input"
                placeholder={t('partnersView.searchInput')}
              />
              <SearchOutlined className="search-icon" />
            </div>
          </div>
          {(modalVisible)
            ? (
              <div className="modal">
                <div className="-password">
                  <div className="modal-main">
                    <p className="modal-title">
                      {t('partnersView.modalMessage')}
                    </p>
                    <div className="input-container">
                      <input
                        value={password}
                        type={(openEye) ? 'text' : 'password'}
                        className="modal-input"
                        placeholder={t('partnersView.modalInput')}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <p className="password-message">
                        {t('partnersView.modalInfoPassword')}
                      </p>
                      {(openEye)
                        ? (
                          <VisibilityOutlined
                            className="eye-icon"
                            onClick={() => setOpenEye(false)}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            className="eye-icon"
                            onClick={() => setOpenEye(true)}
                          />
                        )}
                    </div>
                    <button
                      className={isvalidPassword ? 'modal-button' : 'modal-button-not-ready'}
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      <p>
                        {t('partnersView.modalButton')}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal">
                <div className="modal-content2">
                  <div className="modal-main2">
                    <SentimentDissatisfied className="icon-face" />
                    <p className="modal-title2">
                      {(showError)
                        ? t('partnersView.modalErrorPartners.title') : t('partnersView.modalNonePartners.title')}
                    </p>
                    <p className="modal-message">
                      {(showError)
                        ? t('partnersView.modalErrorPartners.message1') : t('partnersView.modalNonePartners.message1')}
                    </p>
                    <p className="modal-message">
                      {(showError)
                        ? t('partnersView.modalErrorPartners.message2') : t('partnersView.modalNonePartners.message2')}
                    </p>
                    <ExitToApp
                      className="icon-exit"
                      onClick={() => signOut()}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }

  return (
    <div className="partners-container">
      {(showSearchError) && (
        <Alert
          buttonText={t('searchBar.error.button')}
          buttonFunction={setShowSearchError}
        >
          <p>
            {t('searchBar.error.text1')}
          </p>
          <p className="bold">
            {t('searchBar.error.text2')}
          </p>
        </Alert>
      )}
      {(showSearchEmpty) && (
        <Alert
          buttonText={t('searchBar.empty.button')}
          buttonFunction={setShowSearchEmpty}
        >
          <p>
            {t('searchBar.empty.text1')}
          </p>
          <p className="bold">
            {t('searchBar.empty.text2')}
          </p>
        </Alert>
      )}
      {(showGetProductError) && (
        <Alert
          buttonText={t('searchBar.getProductError.button')}
          buttonFunction={setShowGetProductError}
        >
          <p>
            {t('searchBar.getProductError.text1')}
          </p>
          <p className="bold">
            {t('searchBar.getProductError.text2')}
          </p>
        </Alert>
      )}
      <Navbar />
      <Sidebar />
      <SidebarCheckout />
      <div className="patners-content">
        <div className="info-bar">
          <div className="info1">
            <ChevronRight className="icon" />
            <div className="label-container">
              <p className="p-label">
                {t('partnersView.message1')}
              </p>
            </div>
          </div>
          <div className="info2">
            <input
              type="text"
              className="search-input"
              placeholder={t('partnersView.searchInput')}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitSearch();
                }
              }}
            />
            <SearchOutlined
              className="search-icon"
              onClick={() => handleSubmitSearch()}
            />
            {(searchActive) && (
              <Close
                className="close-icon"
                onClick={() => closeSearch()}
              />
            )}
          </div>
        </div>
        {(searchActive)
          ? (
            <div className="partners-grid">
              {searchResponse.map((res) => (
                <SearchResult
                  key={res.id}
                  partner={findPartner(res.id)}
                  informationClick={informationClick}
                  products={res.products}
                  setShowGetProductError={setShowGetProductError}
                />
              ))}
            </div>
          ) : (
            <div className="partners-grid">
              {partners.map((partner) => (
                <div
                  key={partner.id}
                  className="partner-container"
                >
                  <div
                    className="partner"
                    onClick={() => history.push(`/catalog/${partner.id}`)}
                  >
                    <img
                      src={partner.image}
                      alt={partner.name}
                      className="partner-image"
                    />
                  </div>
                  <div className="info-container">
                    <div
                      className="info"
                      onClick={() => informationClick(partner)}
                    >
                      <InfoOutlined className="info-icon" />
                      <p>
                        {`${t('partnersView.deliver')} 
                        ${moment(partner.predictedDelivery).format('dddd D')}`}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        {(showPartner) && (
          <div
            className="modal"
            id="partner-modal"
          >
            <div className="modal-content-partner">
              <Close
                className="modal-close"
                onClick={() => setShowPartner(false)}
              />
              <div className="modal-main-partner">
                <img
                  src={actualPartner.image}
                  alt={actualPartner.name}
                />
                <p className="modal-message">
                  {(actualPartner?.predictedDelivery)
                  && (`${t('partnersView.modalPartner.deliveryDate')}
                  ${moment(actualPartner?.predictedDelivery).format('dddd D')} `)}
                  {`${t('partnersView.modalPartner.between')} ${actualPartner?.deliveryHours[0]} 
                  ${t('partnersView.modalPartner.and')} ${actualPartner?.deliveryHours[1]} 
                  ${t('partnersView.modalPartner.hours')}`}
                </p>
                <p className="modal-title">
                  {`${t('partnersView.modalPartner.minBuy')} ${formatNumber(actualPartner.minBuy, '$', '')}`}
                </p>
                {(actualPartner.description?.length > 0) && (
                  <p className="modal-message modal-description">
                    {actualPartner.description}
                  </p>
                )}
                {(actualPartner.brands.length > 1) && (
                  <p className="modal-message bold">
                    {`${t('partnersView.modalPartner.workWith')}`}
                  </p>
                )}
                {(actualPartner.brands.length > 1) && (
                  <div className="modal-brands">
                    {actualPartner.brands.map((brand, i) => (
                      <div
                        className="brand"
                        key={i.toString()}
                      >
                        <p>
                          {brand}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {(modalVisible) && (
          <div className="modal">
            <div className="modal-content-password">
              <div className="modal-main">
                <p className="modal-title">
                  {t('partnersView.modalMessage')}
                </p>
                <div className="input-container">
                  <input
                    value={password}
                    type={(openEye) ? 'text' : 'password'}
                    className="modal-input"
                    placeholder={t('partnersView.modalInput')}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p className="password-message">
                    {t('partnersView.modalInfoPassword')}
                  </p>
                  {(openEye)
                    ? (
                      <VisibilityOutlined
                        className="eye-icon"
                        onClick={() => setOpenEye(false)}
                      />
                    ) : (
                      <VisibilityOffOutlined
                        className="eye-icon"
                        onClick={() => setOpenEye(true)}
                      />
                    )}
                </div>
                <button
                  className={isvalidPassword ? 'modal-button' : 'modal-button-not-ready'}
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  <p>
                    {t('partnersView.modalButton')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PartnersView;
