import React, {
  ReactElement,
  useContext, useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useHistory, useParams } from 'react-router-dom';

// Icons
import {
  SearchOutlined,
  ChevronRight,
  SentimentDissatisfied,
  Close,
} from '@material-ui/icons';

// Context
import GlobalContext from '../store/GlobalContext';

// Interfaces
import {
  BannerInterface,
  CategoryInterface,
  ContextInterface,
  StateInterface,
  PartnerInterface,
  CatalogUrlParams,
  ProductCTB,
  ProductsBySubcategory,
} from '../interfaces';

// Styles
import '../styles/CatalogStyles.scss';

// Assets
import { ReactComponent as MenuGrid } from '../assets/images/grid-menu.svg';
import { ReactComponent as MenuLines } from '../assets/images/list-menu.svg';

// Components
import Banners from '../components/Banners';
import Category from '../components/Category';
import Sidebar from '../components/Sidebar';
import SidebarCheckout from '../components/SidebarCheckout';
import Navbar from '../components/Navbar';
import SearchResult from '../components/SearchResult';
import Alert from '../components/Alert';

// Helpers
import formatNumber from '../helpers/formatNumber';
import groupBySubCategory from '../helpers/groupBySubCategory';

// Constants
import CTBProductsRestrictions from '../constants/CTBProductsRestriction';

function CatalogView(): ReactElement {
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { getCategories, search } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();
  const { id } : CatalogUrlParams = useParams();

  // State
  const [partner, setPartner] = useState<PartnerInterface>();
  const [categories, setCategories] = useState<CategoryInterface[]>([]);
  const [bannersData, setBannersData] = useState<BannerInterface[]>([]);
  const [dropdownData, setDropdownData] = useState<CategoryInterface[]>([]);
  const [listView, setListView] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [isCTB, setIsCTB] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [searchResponse, setSearchResponse] = useState<ProductsBySubcategory[]>([]);
  const [showSearchError, setShowSearchError] = useState<boolean>(false);
  const [showSearchEmpty, setShowSearchEmpty] = useState<boolean>(false);
  const [showGetProductError, setShowGetProductError] = useState<boolean>(false);

  // Partner name without id number
  function partnerName(name : string): string {
    return (name.split('.')[1])
      ? name.split('.')[1].substring(1).toUpperCase()
      : name.toUpperCase();
  }

  function handleSubmitSearch() {
    search(searchText, id)
      .then((res) => {
        if (res.length > 0) {
          setSearchResponse(groupBySubCategory(res));
          setSearchActive(true);
        } else {
          setShowSearchEmpty(true);
        }
      })
      .catch(() => {
        setShowSearchError(true);
      });
  }

  function closeSearch() {
    setSearchText('');
    setSearchActive(false);
  }

  // Load Categories
  useEffect(() => {
    if (!id.match(/^\d+$/)) {
      setShowError(true);
      return;
    }
    getCategories(state.token, +id, state.industryId)
      .then((response) => {
        setCategories(response.categories);
        // Filter categories data
        const categoriesFiltred: CategoryInterface[] = [];
        response.categories.forEach((category: CategoryInterface) => {
          categoriesFiltred.push({ id: category.id, name: category.name, image: category.image });
        });
        setPartner(response.partner);
        setBannersData(response.banners);
        setDropdownData(categoriesFiltred);
        setIsCTB(partnerName(response.partner.name) === 'COMERCIAL TU BODEGA');
        setMounted(true);
      })
      .catch(() => {
        setShowError(true);
      });
  }, []);

  if (!mounted) {
    return (
      <div className="catalog-container">
        <Navbar
          back
          backText={t('catalogView.back')}
          backFunction={() => history.push('/partners')}
        />
        {(showError) && (
          <div className="modal">
            <div className="modal-content2">
              <div className="modal-main2">
                <SentimentDissatisfied className="icon-face" />
                <p className="modal-title2">
                  {t('catalogView.modalError.title')}
                </p>
                <p className="modal-message">
                  {t('catalogView.modalError.message1')}
                </p>
                <p className="modal-message">
                  {t('catalogView.modalError.message2')}
                </p>
                <p className="back" onClick={() => history.push('/partners')}>
                  {t('catalogView.modalError.back')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="catalog-container">
      {(showSearchError) && (
        <Alert
          buttonText={t('searchBar.error.button')}
          buttonFunction={setShowSearchError}
        >
          <p>
            {t('searchBar.error.text1')}
          </p>
          <p className="bold">
            {t('searchBar.error.text2')}
          </p>
        </Alert>
      )}
      {(showSearchEmpty) && (
        <Alert
          buttonText={t('searchBar.empty.button')}
          buttonFunction={setShowSearchEmpty}
        >
          <p>
            {t('searchBar.empty.text1')}
          </p>
          <p className="bold">
            {t('searchBar.empty.text2')}
          </p>
        </Alert>
      )}
      {(showGetProductError) && (
        <Alert
          buttonText={t('searchBar.getProductError.button')}
          buttonFunction={setShowGetProductError}
        >
          <p>
            {t('searchBar.getProductError.text1')}
          </p>
          <p className="bold">
            {t('searchBar.getProductError.text2')}
          </p>
        </Alert>
      )}
      <Navbar
        back
        backText={t('catalogView.back')}
        backFunction={() => history.push('/partners')}
      />
      <Sidebar />
      <SidebarCheckout />
      <div className="info-bar">
        <div className="info1">
          <ChevronRight className="icon" />
          <div className="label-container">
            <p className="p-label">
              {partnerName(partner.name)}
            </p>
            <p className="p-info">
              {(partner.predictedDelivery)
              && (
                `${t('catalogView.minBuy')} 
                ${formatNumber(partner.minBuy, '$', '')} - 
                ${t('catalogView.deliveryDate')} 
                ${moment(partner.predictedDelivery).format('dddd D')} 
                ${t('catalogView.between')} ${partner.deliveryHours[0]} 
                ${t('catalogView.and')} ${partner.deliveryHours[1]} 
                ${t('catalogView.hours')}`
              )}
            </p>
          </div>
        </div>
        <div className="info2">
          <div className="distribution-options">
            <MenuGrid
              className={(listView) ? 'distribution-icon' : 'distribution-icon selected'}
              onClick={() => setListView(false)}
            />
            <MenuLines
              className={(!listView) ? 'distribution-icon none-margin' : 'distribution-icon none-margin selected'}
              onClick={() => setListView(true)}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              className="search-input"
              placeholder={t('catalogView.searchInput')}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitSearch();
                }
              }}
            />
            <SearchOutlined
              className="search-icon"
              onClick={() => handleSubmitSearch()}
            />
            {(searchActive) && (
              <Close
                className="close-icon"
                onClick={() => closeSearch()}
              />
            )}
          </div>
        </div>
      </div>
      {(searchActive) ? (
        <div className="search-grid">
          {searchResponse.map((res) => (
            <SearchResult
              partner={partner}
              products={res.products}
              categoryId={res.categoryId}
              subCategoryName={res.name}
              subCategoryId={res.id}
              setShowGetProductError={setShowGetProductError}
            />
          ))}
        </div>
      ) : (
        <>
          <Banners banners={bannersData} partner={partner} />
          {(listView)
            ? (
              <div className="categories-grid">
                {categories.map((category) => (
                  <Category
                    key={category.id}
                    category={category}
                    dropdownData={dropdownData}
                    partner={partner}
                    listView={listView}
                  />
                ))}
              </div>
            ) : (
              <div>
                {categories.map((category) => (
                  <Category
                    key={category.id}
                    category={category}
                    dropdownData={dropdownData}
                    partner={partner}
                  />
                ))}
              </div>
            )}
          {(showError) && (
            <div className="modal">
              <div className="modal-content2">
                <div className="modal-main2">
                  <SentimentDissatisfied className="icon-face" />
                  <p className="modal-title2">
                    {t('catalogView.modalError.title')}
                  </p>
                  <p className="modal-message">
                    {t('catalogView.modalError.message1')}
                  </p>
                  <p className="modal-message">
                    {t('catalogView.modalError.message2')}
                  </p>
                  <p className="back" onClick={() => history.push('/partners')}>
                    {t('catalogView.modalError.back')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {(isCTB) && (
            <div className="ctb-modal-view">
              <div className="ctb-modal-container">
                <p className="title">
                  {t('catalogView.ctbModal.title')}
                </p>
                <div className="content">
                  <p className="description">
                    {t('catalogView.ctbModal.description')}
                  </p>
                  {CTBProductsRestrictions.map((ctbProduct : ProductCTB, i) => (
                    <div
                      key={ctbProduct.id}
                      className={(i === 0) ? 'element first' : 'element'}
                    >
                      <p className="data bold">
                        &#8226;
                        {` ${ctbProduct.productName}: `}
                      </p>
                      <p className="data">
                        {t('catalogView.ctbModal.max')}
                      </p>
                      <p className="data bold">
                        {` ${ctbProduct.maxDisplays} `}
                        {t('catalogView.ctbModal.display')}
                      </p>
                      <p className="data">
                        {(ctbProduct.displayUnits) && ` (${ctbProduct.maxUnits} ${t('catalogView.ctbModal.units')})`}
                        {(ctbProduct.sku) && ` ${t('catalogView.ctbModal.sku')}`}
                        {` ${t('catalogView.ctbModal.week')}`}
                      </p>
                    </div>
                  ))}
                </div>
                <button
                  className="button"
                  type="button"
                  onClick={() => setIsCTB(false)}
                >
                  {t('catalogView.ctbModal.accept').toUpperCase()}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CatalogView;
