import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/LoginStyles.scss';

// Icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import { ChevronRight } from '@material-ui/icons';

// Assets
import logoWhite from '../assets/images/dealing.png';

// Components
import WingsBackground from '../components/WingsBackground';
import { ContextInterface } from '../interfaces';
import GlobalContext from '../store/GlobalContext';

function LoginView(): ReactElement {
  // Context
  const { context }: { context: ContextInterface } = useContext(GlobalContext);
  const { logIn } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [inSubmit, setInSubmit] = useState<boolean>(false);
  const [inModal, setInModal] = useState<boolean>(false);

  // Set blank button when email and password exists
  const [blankButton, setBlankButton] = useState<boolean>(false);

  useEffect(() => {
    if (email && password) {
      setBlankButton(true);
    } else {
      setBlankButton(false);
    }
  }, [email, password]);

  // Bool for open/close eye (password visible/not visible)
  const [openEye, setOpenEye] = useState<boolean>(false);

  function setModal(b: boolean) {
    setOpenModal(b);
    setInSubmit(false);
    setInModal(b);
  }

  function handleSubmit() {
    setInSubmit(true);
    logIn(email, password)
      .catch(() => {
        setModal(true);
      });
  }

  useEffect(() => {
    if (inSubmit) {
      return null;
    }
    const listener = (event: { code: string; }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (blankButton && !inModal) {
          handleSubmit();
        } else if (inModal) {
          setOpenModal(false);
          setInModal(false);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  useEffect(() => {
    if (openModal) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          setOpenModal(false);
        }
      };
    }
  }, [openModal]);

  return (
    <form className="login-container">
      <WingsBackground />
      <div
        onClick={() => history.push('/')}
        className="landing-link"
      >
        <img
          className="logo"
          src={logoWhite}
          alt="logo"
        />
      </div>
      <div className="login-label">
        <ChevronRight className="icon" />
        <p className="message">
          {t('loginView.title')}
        </p>
      </div>
      <input
        value={email}
        type="email"
        className="login-email"
        id="prueba"
        placeholder={t('loginView.email')}
        onChange={(e) => setEmail(e.target.value.trim())}
      />
      <div className="input-container">
        <input
          value={password}
          type={openEye ? 'text' : 'password'}
          className="login-password"
          placeholder={t('loginView.password')}
          onChange={(e) => setPassword(e.target.value)}
        />
        {(openEye)
          ? (
            <VisibilityOutlinedIcon
              className="eye-icon"
              onClick={() => setOpenEye(false)}
            />
          ) : (
            <VisibilityOffOutlinedIcon
              className="eye-icon"
              onClick={() => setOpenEye(true)}
            />
          )}
      </div>
      <div className="forget-container">
        <ArrowRightSharpIcon className="icon" />
        <p
          className="message"
          onClick={() => history.push('/password-recovery')}
        >
          {t('loginView.passwordRecovery')}
        </p>
      </div>
      <button
        className={(blankButton) ? 'confirm-button-white' : 'confirm-button'}
        type="button"
        onClick={(blankButton) ? () => handleSubmit() : undefined}
      >
        {t('loginView.button')}
      </button>
      <div className="signup-link-container">
        <p className="message">
          {t('loginView.signUpMessage')}
        </p>
        <p
          onClick={() => history.push('/signup')}
          className="link"
        >
          {t('loginView.signUp')}
        </p>
      </div>
      {(openModal)
        && (
          <div
            className="modal"
            id="myModal"
          >
            <div className="modal-content">
              <div className="modal-main">
                <p className="modal-title">
                  {t('loginView.loginProblemMessage1')}
                </p>
                <p className="modal-title2">
                  {t('loginView.loginProblemMessage2')}
                </p>
                <button
                  className="modal-button"
                  type="button"
                  onClick={() => setModal(false)}
                >
                  <p>
                    {t('loginView.loginProblemButton')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        )}
    </form>
  );
}

export default LoginView;
