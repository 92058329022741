import React from 'react';
import axios from 'axios';

// Store import
import { initialState } from './reducer';

// Interfaces
import {
  CartArrayInterface,
  ContextInterface,
  PartnerInterface,
  PromoInterface,
  ReducerActionInterface,
  StoresResponse,
  SectorInterface,
  CreditsInterface,
  SearchResponse,
} from '../interfaces';

// Services
import {
  setSentryTags,
  fireSentryEvent,
  setContextTag,
} from '../services/Sentry';

// Helpers
import UseLocalStorage from '../helpers/useLocalStorage';

// Env vars
const {
  REACT_APP_API_URL,
  REACT_APP_API_VERSION,
} = process.env;

function GetGlobalContext(
  dispatch: React.Dispatch<ReducerActionInterface>,
): ContextInterface {
  // Local storage
  const [localState, setLocalState] = UseLocalStorage('state', initialState);

  const globalContext: ContextInterface = {
    logIn: async (email, password) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/log-in/`;
      await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          email,
          password,
        },
      })
        .then((response) => response.data)
        .then(async (data) => {
          const { industryId } = data;
          const user = {
            name: data.userName,
            lastName: data.userLastName,
            email: data.email,
          };
          const store = {
            id: data.storeId,
            name: data.storeName,
            rut: data.rut,
            phone: data.phone,
            businessName: data.businessName,
            order: data.order,
            storeSector: data.storeSector,
            storeStreet: data.storeStreet,
            storeNumber: data.storeNumber,
            storeLocal: data.storeLocal,
            billingSector: data.billingSector,
            billingStreet: data.billingStreet,
            billingNumber: data.billingNumber,
            billingLocal: data.billingLocal,
          };
          setSentryTags(data.email, data.storeName);
          setLocalState({
            ...localState,
            industryId,
            token: data.token,
            isEmailConfirmed: data.activated,
            user,
            store,
            passwordRecovery: data.passwordRecovery,
          });
          return {
            ...data,
            user,
            store,
            industryId,
          };
        })
        .then((data) => {
          dispatch({ type: 'LOG_IN', data });
          if (!data.activated) {
            dispatch({ type: 'LOADING' });
          }
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setSentryTags(email, '');
          if (err.response.status >= 500) {
            setContextTag(route, err.response.data.message);
            fireSentryEvent(err);
          }
          throw err;
        });
    },
    signUp: async (user, store) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/sign-up/`;
      const {
        firstName,
        lastName,
        email,
        password,
      } = user;
      const {
        storeName,
        rut,
        storeSector,
        order,
        businessName,
        storeExists,
      } = store;
      const token = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          firstName,
          lastName,
          email,
          password,
          storeName,
          rut,
          storeSector,
          storeExists,
          order,
          businessName,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          setSentryTags(email, storeName);
          return data.token;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return token;
    },
    signOut: () => {
      setLocalState(initialState);
      dispatch({ type: 'SIGN_OUT' });
    },
    verifyEmail: async (email) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/verify-email/`;
      await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          email,
        },
      })
        .then(() => {
          dispatch({ type: 'LOADING' });
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          if (err.response.status !== 400) {
            setContextTag(route, err.response.data.message);
            fireSentryEvent(err);
          }
          throw err;
        });
    },
    confirmEmail: async (token, user) => {
      const route = `/store-api/${REACT_APP_API_VERSION}/verify/email-confirmation/`;
      await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          let userInfo = user;
          let store = {
            id: 0,
            name: '',
            rut: '',
            phone: '',
            businessName: '',
            order: '',
            storeSector: '',
            storeStreet: '',
            storeNumber: '',
            storeLocal: '',
            billingSector: '',
            billingStreet: '',
            billingNumber: '',
            billingLocal: '',
          };
          let industryId = null;
          if (data.activated) {
            industryId = data.information.industryId;
            userInfo = {
              name: data.information.userName,
              lastName: data.information.userLastName,
              email: data.information.email,
            };
            store = {
              id: data.information.storeId,
              name: data.information.storeName,
              rut: data.information.rut,
              phone: data.information.phone,
              businessName: data.information.businessName,
              order: data.information.order,
              storeSector: data.information.storeSector,
              storeStreet: data.information.storeStreet,
              storeNumber: data.information.storeNumber,
              storeLocal: data.information.storeLocal,
              billingSector: data.information.billingSector,
              billingStreet: data.information.billingStreet,
              billingNumber: data.information.billingNumber,
              billingLocal: data.information.billingLocal,
            };
            setLocalState({
              ...localState,
              industryId,
              user: userInfo,
              store,
              token,
            });
            dispatch({
              type: 'EMAIL_CONFIRMED',
              data: {
                activated: data.activated,
                user: userInfo,
                store,
                industryId,
                token,
              },
            });
          }
        })
        .catch((err) => {
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
    },
    getStores: async (rut) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/rut-stores/`;
      const rutInfo: StoresResponse = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        params: {
          rut,
        },
      })
        .then((res) => {
          dispatch({ type: 'LOADING' });
          return res.data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return rutInfo;
    },
    getPartners: async (token) => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/partners/`;
      const partners: PartnerInterface[] = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: 'LOADING' });
          return data;
        })
        .catch(async (err) => {
          dispatch({ type: 'LOADING' });
          if (err.response.status !== 403) {
            setContextTag(route, err.response.data.message);
            fireSentryEvent(err);
          }
          throw err;
        });
      return partners;
    },
    getCategories: async (token, partnerId, industryId) => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/general-category/`;
      const res = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          partnerId,
          industryId,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: 'ACTIVE_PURCHASE', data: { purchase: data.purchase } });
          dispatch({ type: 'LOADING' });
          return data;
        })
        .catch(async (err) => {
          dispatch({ type: 'ACTIVE_PURCHASE', data: { purchase: false } });
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return res;
    },
    getSubCategories: async (token, partnerId, categoryId, industryId) => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/specific-category/`;
      const res = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          partnerId,
          categoryId,
          industryId,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: 'LOADING' });
          return data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return res;
    },
    getCategory: async (token, categoryId, partnerId) => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/specific-category/`;
      const res = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          categoryId,
          partnerId,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: 'LOADING' });
          return data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return res;
    },
    getProduct: async (token, specificId) => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/specific-product/`;
      const res = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          specificId,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: 'LOADING' });
          return data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return res;
    },
    passwordRecovery: async (email) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/password-recovery/`;
      const userFound = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          email,
        },
      })
        .then((response) => response.data.userFound)
        .catch((err) => {
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      dispatch({ type: 'LOADING' });
      return userFound;
    },
    changePassword: async (token, password) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/password-recovery/1/`;
      const changed = await axios({
        method: 'put',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          password,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          dispatch({ type: 'LOADING' });
          return true;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      setLocalState({
        ...localState,
        passwordRecovery: false,
      });
      return changed;
    },
    changeUser: async (token, email, name, lastName) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/update-user/1/`;
      const status: number = await axios({
        method: 'put',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          email,
          firstName: name,
          lastName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          const user = {
            email: res.data.newData.email,
            name: res.data.newData.firstName,
            lastName: res.data.newData.lastName,
          };
          setLocalState({
            ...localState,
            user,
          });
          dispatch({ type: 'CHANGE_USER', data: { user } });
          dispatch({ type: 'LOADING' });
          return res.status;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          return err.request.status;
        });
      return status;
    },
    getDeliveryPredictions: async (currentState, ids) => {
      const route = `/purchase-api/${REACT_APP_API_VERSION}/delivery-predictions/`;
      const dates = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        params: {
          partnersIds: `[${ids.map((id: number) => id)}]`,
          storeId: currentState.store.id,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          setContextTag(`${route}`, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return dates;
    },
    updateCart: async (currentState, product, partner, cartView = false) => {
      if (!cartView) {
        dispatch({ type: 'LOADING' });
      }
      const newCart = { ...currentState.cart };
      const partnerCart = newCart[product.specific.partnerId];
      // If partner is present in the current cart
      if (partnerCart) {
        const partnerProduct = partnerCart.cart[product.id];
        // If product is present in current partner cart, we modify its quantity,
        // else we add it
        if (partnerProduct) {
          newCart[product.specific.partnerId].total
            -= (product.totalProduct) ? product.totalProduct : partnerProduct.quantity * product.specific.price;
          // If quantity = 0, we delete the product
          // and discount from itemsCount
          if (product.quantity === 0) {
            delete newCart[product.specific.partnerId].cart[product.id];
            // If it is the only product, we delete the partner from the cart
            if (newCart[product.specific.partnerId].total < 1) {
              delete newCart[product.specific.partnerId];
            }
            setLocalState({
              ...localState,
              cart: newCart,
              itemsCount: currentState.itemsCount - 1,
            });
            dispatch({ type: 'MODIFY_CART', data: { cart: newCart, itemsCount: currentState.itemsCount - 1 } });
          } else {
            // Update the product quantity, itemsCount remains the same
            newCart[product.specific.partnerId].cart[product.id].quantity = product.quantity;
            newCart[product.specific.partnerId].cart[product.id].totalProduct = product.quantity
              * product.specific.price;
            newCart[product.specific.partnerId].total += product.quantity * product.specific.price;
          }
        } else {
          // Add the product, itemsCount increases by 1
          newCart[product.specific.partnerId].cart[product.id] = {
            ...product,
            totalProduct: product.quantity * product.specific.price,
          };
          newCart[product.specific.partnerId].total += product.quantity * product.specific.price;
        }
      } else {
        // If its not present, we add it and add the product to the partner's cart
        // itemsCount increases by 1
        newCart[product.specific.partnerId] = partner;
        newCart[product.specific.partnerId].cart = {};
        newCart[product.specific.partnerId].total = product.quantity * product.specific.price;
        newCart[product.specific.partnerId].cart[product.id] = {
          ...product,
          totalProduct: product.quantity * product.specific.price,
        };
      }
      const finalPromotions: PromoInterface[] = [];
      product.specific.promotions.forEach((promo) => {
        if (promo.details) {
          finalPromotions.push({
            longDescription: promo.longDescription,
            promotionId: promo.promotionId,
            scaleType: promo.scaleType,
            shortDescription: promo.shortDescription,
            type: promo.type,
          });
        } else {
          finalPromotions.push(promo);
        }
      });
      const route = (product.quantity !== 0)
        ? `/purchase-api/${REACT_APP_API_VERSION}/cart-product/`
        : `/purchase-api/${REACT_APP_API_VERSION}/cart-product/${partner.id}-${product.id}/`;
      await axios({
        method: (product.quantity !== 0) ? 'post' : 'delete',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          partner: {
            ...partner,
            total: (newCart[product.specific.partnerId])
              ? newCart[product.specific.partnerId].total
              : 0,
            industryId: currentState.industryId,
          },
          product: {
            ...product,
            specific: {
              ...product.specific,
              promotions: finalPromotions,
            },
          },
          cart: newCart,
          storeId: currentState.store.id,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((response) => response.data.cart)
        .then((responseCart) => {
          let count = 0;
          const cartArr: PartnerInterface[] = Object.values(responseCart);
          for (let index = 0; index < cartArr.length; index += 1) {
            const newPartnerCart = Object.values(cartArr[index].cart);
            count += newPartnerCart.length;
          }
          setLocalState({
            ...localState,
            cart: responseCart,
            itemsCount: count,
          });
          dispatch({ type: 'MODIFY_CART', data: { cart: responseCart, itemsCount: count } });
        })
        .then(() => {
          if (!cartView) {
            dispatch({ type: 'LOADING' });
          }
        })
        .catch((err) => {
          if (!cartView) {
            dispatch({ type: 'LOADING' });
          }
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
    },
    replaceCart: async (newCart) => {
      let count = 0;
      const cartArr: CartArrayInterface = Object.values(newCart);
      for (let index = 0; index < cartArr.length; index += 1) {
        const partnerCart = Object.values(cartArr[index].cart);
        count += partnerCart.length;
      }
      setLocalState({
        ...localState,
        cart: newCart,
        itemsCount: count,
      });
      dispatch({ type: 'MODIFY_CART', data: { cart: newCart, itemsCount: count } });
    },
    checkFirstOrder: async (currentState, toCheckout = false) => {
      if (!toCheckout) {
        dispatch({ type: 'LOADING' });
      }
      const route = `/purchase-api/${REACT_APP_API_VERSION}/check-first/`;
      const response = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          storeId: currentState.store.id,
          cart: currentState.cart,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((res) => {
          dispatch({ type: 'LOADING' });
          return res.data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return response;
    },
    checkout: async (currentState, data) => {
      dispatch({ type: 'LOADING' });
      const route = `/purchase-api/${REACT_APP_API_VERSION}/cart-confirm/`;
      const response = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          ...data,
          storeId: currentState.store.id,
          cart: currentState.cart,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((res) => {
          setLocalState({
            ...localState,
            cart: {},
            itemsCount: 0,
          });
          dispatch({ type: 'CHECKOUT' });
          return res.data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return response;
    },
    getPurchaseHistory: async (currentState) => {
      dispatch({ type: 'LOADING' });
      const route = `/purchase-api/${REACT_APP_API_VERSION}/purchases/`;
      const purchases = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((response) => {
          dispatch({ type: 'LOADING' });
          return response.data.purchases;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return purchases;
    },
    getPurchase: async (currentState, verificationCode) => {
      dispatch({ type: 'LOADING' });
      const route = `/purchase-api/${REACT_APP_API_VERSION}/past-purchase/`;
      const response = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          verificationCode,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((res) => {
          dispatch({ type: 'LOADING' });
          return res.data;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return response;
    },
    addOldCartToCart: async (currentState, cart) => {
      dispatch({ type: 'LOADING' });
      await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}/purchase-api/${REACT_APP_API_VERSION}/update-purchase/`,
        data: {
          cart,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then((res) => {
          dispatch({ type: 'LOADING' });
          return res.data.cart;
        })
        .then((responseCart) => {
          let count = 0;
          const cartArr: PartnerInterface[] = Object.values(responseCart);
          for (let index = 0; index < cartArr.length; index += 1) {
            const partnerCart = Object.values(cartArr[index].cart);
            count += partnerCart.length;
          }
          setLocalState({
            ...localState,
            cart: responseCart,
            itemsCount: count,
          });
          dispatch({ type: 'MODIFY_CART', data: { cart: responseCart, itemsCount: count } });
        })
        .catch(() => {
          dispatch({ type: 'LOADING' });
          // Alert.alert('Error', 'Hubo un problema al agregar esta compra', [{ text: 'Ok' }]);
        });
    },
    contactUs: async (subject, message, contact) => {
      dispatch({ type: 'LOADING' });
      const route = `/store-api/${REACT_APP_API_VERSION}/contact-us/`;
      const sent = await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          subject,
          message,
          contact,
        },
      })
        .then((response) => response.data.sent)
        .catch((err) => {
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      dispatch({ type: 'LOADING' });
      return sent;
    },
    getCart: async (token) => {
      const route = `/purchase-api/${REACT_APP_API_VERSION}/cart-product/`;
      await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}/purchase-api/${REACT_APP_API_VERSION}/cart-product/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.data.cart)
        .then((responseCart) => {
          let count = 0;
          const cartArr: PartnerInterface[] = Object.values(responseCart);
          for (let index = 0; index < cartArr.length; index += 1) {
            const partnerCart = Object.values(cartArr[index].cart);
            count += partnerCart.length;
          }
          setLocalState({
            ...localState,
            cart: responseCart,
            itemsCount: count,
          });
          dispatch({ type: 'MODIFY_CART', data: { cart: responseCart, itemsCount: count } });
        })
        .catch((err) => {
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
        });
    },
    getCredits: async (currentState) => {
      dispatch({ type: 'LOADING' });
      const route = `${REACT_APP_API_URL}/store-api/${REACT_APP_API_VERSION}/get-credits/`;
      const credits: CreditsInterface[] = await axios({
        method: 'get',
        url: route,
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
        params: {
          storeId: currentState.store.id,
        },
      })
        .then((response) => {
          dispatch({ type: 'LOADING' });
          return response.data.credits;
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return credits;
    },
    sidebarCheckoutSwitch: async () => {
      dispatch({ type: 'SIDEBAR_CHECKOUT_SWITCH' });
    },
    checkStock: async (currentState, toCheckout = false) => {
      dispatch({ type: 'LOADING' });
      const route = `/purchase-api/${REACT_APP_API_VERSION}/check-cart-stock/`;
      await axios({
        method: 'post',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          cart: currentState.cart,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then(() => {
          if (!toCheckout) {
            dispatch({ type: 'LOADING' });
          }
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          if (err.response.status >= 500) {
            setContextTag(route, err.response.data.message);
            fireSentryEvent(err);
          }
          throw err;
        });
    },
    sidebarSwitch: () => {
      dispatch({ type: 'SIDEBAR_SWITCH' });
    },
    changeStore: async (currentState, data) => {
      dispatch({ type: 'LOADING' });
      const realBillingSectorId = data.billingSector.id < 1 ? null : data.billingSector.id;
      const route = `/store-api/${REACT_APP_API_VERSION}/update-store/1/`;
      await axios({
        method: 'put',
        url: `${REACT_APP_API_URL}${route}`,
        data: {
          storeId: data.storeId,
          name: data.name,
          phone: data.phone,
          storeStreet: data.storeStreet,
          storeNumber: data.storeNumber,
          storeLocal: data.storeLocal,
          billingStreet: data.billingStreet,
          billingNumber: data.billingNumber,
          billingLocal: data.billingLocal,
          storeSectorId: data.storeSector.id,
          billingSectorId: realBillingSectorId,
        },
        headers: {
          Authorization: `Bearer ${currentState.token}`,
        },
      })
        .then(async (res) => {
          const store = {
            id: data.storeId,
            name: res.data.store.name,
            rut: res.data.store.rut,
            phone: res.data.store.phone,
            businessName: res.data.store.businessName,
            order: res.data.store.order,
            storeSector: data.storeSector.name,
            storeStreet: res.data.store.storeStreet,
            storeNumber: res.data.store.storeNumber,
            storeLocal: res.data.store.storeLocal,
            billingSector: data.billingSector.name,
            billingStreet: data.billingStreet,
            billingNumber: data.billingNumber,
            billingLocal: data.billingLocal,
          };
          setLocalState({
            ...localState,
            token: res.data.token,
            store,
          });
          dispatch({ type: 'CHANGE_STORE', data: { token: res.data.token, store } });
          dispatch({ type: 'LOADING' });
        })
        .catch((err) => {
          dispatch({ type: 'LOADING' });
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
    },
    getSectors: async (toCheckout = false) => {
      if (!toCheckout) {
        dispatch({ type: 'LOADING' });
      }
      const route = `/store-api/${REACT_APP_API_VERSION}/sectors/`;
      const sectors: SectorInterface[] = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
      })
        .then((res) => {
          if (!toCheckout) {
            dispatch({ type: 'LOADING' });
          }
          return res.data.sectors;
        })
        .catch((err) => {
          if (!toCheckout) {
            dispatch({ type: 'LOADING' });
          }
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });
      return sectors;
    },
    search: async (text, partnerId = '') => {
      dispatch({ type: 'LOADING' });
      const route = `/partner-api/${REACT_APP_API_VERSION}/search/`;
      const response: SearchResponse[] = await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}${route}`,
        params: {
          search: encodeURI(text),
          limit: 100,
          partner_id: partnerId,
        },
      })
        .then((res) => res.data.results)
        .catch((err) => {
          setContextTag(route, err.response.data.message);
          fireSentryEvent(err);
          throw err;
        });

      dispatch({ type: 'LOADING' });
      return response;
    },
  };

  return globalContext;
}

export default GetGlobalContext;
