import React, { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import Swiper core and required components
import SwiperCore, {
  Pagination,
  Mousewheel,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  BannerInterface,
  ContextInterface,
  ProductInterface,
  StateInterface,
  PartnerInterface,
} from '../interfaces';

// Import Swiper styles
import '../styles/BannersStyles.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/thumbs/thumbs.scss';

// Context
import GlobalContext from '../store/GlobalContext';

// Components
import Product from './Product';

// install Swiper components
SwiperCore.use([Pagination, Mousewheel, Autoplay]);

function Banners({ banners, partner }: { banners: BannerInterface[], partner: PartnerInterface }): ReactElement {
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { getProduct } = context;

  const history = useHistory();

  const [showProductBanner, setShowProductBanner] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductInterface>();

  function clickOnBanner(banner: BannerInterface) {
    if (banner.url.includes('/product/')) {
      const productId = +banner.url.slice(banner.url.indexOf('/product/') + 9);
      getProduct(state.token, productId)
        .then((res) => {
          setProduct(res.product);
          setShowProductBanner(true);
        });
    } else if (banner.url.includes('/category/')) {
      const ids = banner.url.slice(banner.url.indexOf('/category/') + 10);
      const idsList = ids.split('/');
      const partnerId = +idsList[0];
      const categoryId = +idsList[1];
      const subCategoryId = +idsList[2];
      history.push(`/catalog/${partnerId}/category/${categoryId}/${subCategoryId}`);
    }
  }

  if (banners.length > 0) {
    const wrapper: HTMLCollectionOf<Element> = document.getElementsByClassName('swiper-wrapper');
    if (wrapper[0] && banners.length < 3) {
      wrapper[0].className += ' center-banners';
    }
    return (
      <div className="swiper-container">
        <Swiper
          slidesPerView={3}
          pagination={banners.length > 3 && { clickable: true }}
          mousewheel={{ forceToAxis: true }}
          autoplay={{ disableOnInteraction: false, delay: 5000 }}
          id="swiper"
        >
          {banners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <img
                src={banner.image}
                alt={banner.description}
                className="banner-image"
                onClick={() => clickOnBanner(banner)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {(showProductBanner)
          && (
            <Product
              product={product}
              partner={partner}
              showProductExtern={showProductBanner}
              setShowProductExtern={setShowProductBanner}
            />
          )}
      </div>
    );
  }

  return null;
}

export default Banners;
