import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import {
  ChevronRight,
  VisibilityOutlined,
  VisibilityOffOutlined,
  LockOutlined,
  Close,
} from '@material-ui/icons';

// Interfaces
import {
  ContextInterface,
  ModalProps,
  StateInterface,
} from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

// Helpers
import validatePassword from '../helpers/validatePassword';
import emailRegex from '../helpers/emailRegex';

// Styles
import '../styles/UserProfileModal.scss';

// Components
import Alert from './Alert';

function UserProfileModal(props : ModalProps) : ReactElement {
  // Props
  const { visible } = props;

  // Context
  const { state, context } : { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { changeUser, changePassword } = context;

  // Translation
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(true);
  const [changePasswordMode, setChangePasswordMode] = useState<boolean>(false);
  const [openEye1, setOpenEye1] = useState<boolean>(false);
  const [openEye2, setOpenEye2] = useState<boolean>(false);
  const [name, setName] = useState<string>(state.user.name);
  const [lastName, setLastName] = useState<string>(state.user.lastName);
  const [email, setEmail] = useState<string>(state.user.email);
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<string>('');
  const [successResponse, setSuccessResponse] = useState<boolean>(false);
  const [failedResponse, setFailedResponse] = useState<boolean>(false);

  const closeModal = () => {
    visible(false);
    setChangePasswordMode(false);
    setOpenModal(false);
  };

  const errorTypes = {
    missingData: 'missingData',
    emailBadFormat: 'emailBadFormat',
    emailInUse: 'emailInUse',
    invalidPassword: 'invalidPassword',
    noCoincidencePassword: 'noCoincidencePassword',
  };

  const checkInput = () => {
    if (!changePasswordMode) {
      if (!email || !name || !lastName) {
        setErrorType(errorTypes.missingData);
        setError(true);
        return false;
      }
      if (!emailRegex.test(email)) {
        setErrorType(errorTypes.emailBadFormat);
        setError(true);
        return false;
      }
    } else {
      if (!newPassword || !repeatPassword) {
        setErrorType(errorTypes.missingData);
        setError(true);
        return false;
      }
      if (!validatePassword(newPassword)) {
        setErrorType(errorTypes.invalidPassword);
        setError(true);
        return false;
      }
      if (newPassword !== repeatPassword) {
        setErrorType(errorTypes.noCoincidencePassword);
        setError(true);
        return false;
      }
    }
    return true;
  };

  const handleRequest = async () => {
    if (!checkInput()) {
      return;
    }
    if (changePasswordMode) {
      const changed = await changePassword(state.token, newPassword);
      if (changed) {
        setSuccessResponse(true);
      } else {
        setFailedResponse(true);
      }
      return;
    }
    const status = await changeUser(state.token, email.toLowerCase(), name, lastName);
    if (status === 200) {
      setSuccessResponse(true);
    } else if (status === 409) {
      setErrorType(errorTypes.emailInUse);
      setError(true);
    } else {
      setFailedResponse(true);
    }
  };

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (state.isLoading) {
        return;
      }
      if (event.code === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  useEffect(() => {
    if (openModal) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          closeModal();
        }
      };
    }
  }, [openModal]);

  function closeSuccessAlert(value: boolean): void {
    setSuccessResponse(value);
    if (changePasswordMode) {
      closeModal();
    }
  }

  if (!openModal) {
    return null;
  }

  if (error) {
    switch (errorType) {
      case errorTypes.missingData:
        return (
          <Alert
            buttonText={t('userProfileModal.alert.button')}
            buttonFunction={setError}
          >
            <p>
              {t('userProfileModal.alert.missingData.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('userProfileModal.alert.missingData.line2')}
            </p>
          </Alert>
        );
      case errorTypes.emailBadFormat:
        return (
          <Alert
            buttonText={t('userProfileModal.alert.button')}
            buttonFunction={setError}
          >
            <p>
              {t('userProfileModal.alert.emailBadFormat.line1')}
            </p>
            <p>
              {t('userProfileModal.alert.emailBadFormat.line2')}
            </p>
            <p className="bold">
              {t('userProfileModal.alert.emailBadFormat.line3')}
            </p>
          </Alert>
        );
      case errorTypes.invalidPassword:
        return (
          <Alert
            buttonText={t('userProfileModal.alert.button')}
            buttonFunction={setError}
          >
            <p>
              {t('userProfileModal.alert.invalidPassword.line1')}
            </p>
            <p className="bold">
              {t('userProfileModal.alert.invalidPassword.line2')}
            </p>
            <p className="bold">
              {t('userProfileModal.alert.invalidPassword.line3')}
            </p>
          </Alert>
        );
      case errorTypes.noCoincidencePassword:
        return (
          <Alert
            buttonText={t('userProfileModal.alert.button')}
            buttonFunction={setError}
          >
            <p>
              {t('userProfileModal.alert.noCoincidencePassword.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('userProfileModal.alert.noCoincidencePassword.line2')}
            </p>
          </Alert>
        );
      case errorTypes.emailInUse:
        return (
          <Alert
            buttonText={t('userProfileModal.alert.button')}
            buttonFunction={setError}
          >
            <p>
              {t('userProfileModal.alert.emailInUse.line1')}
            </p>
            <p className="bold">
              {t('userProfileModal.alert.emailInUse.line2')}
            </p>
          </Alert>
        );
      default:
        break;
    }
  }

  if (failedResponse) {
    return (
      <Alert
        buttonText={t('userProfileModal.alert.button')}
        buttonFunction={setFailedResponse}
      >
        <p>
          {t('userProfileModal.alert.failed.line1')}
        </p>
        <p>
          {t('userProfileModal.alert.failed.line2')}
        </p>
        <p className="bold">
          {t('userProfileModal.alert.failed.line3')}
        </p>
      </Alert>
    );
  }

  if (successResponse) {
    return (
      <Alert
        buttonText={t('userProfileModal.alert.button')}
        buttonFunction={closeSuccessAlert}
      >
        <p className="bold">
          {t('userProfileModal.alert.success.line1')}
        </p>
        <p className="bold">
          {t('userProfileModal.alert.success.line2')}
        </p>
      </Alert>
    );
  }

  return (
    <div
      id="myModal"
      className="modal-user"
    >
      <div className="content">
        <Close
          role="button"
          id="modal-close"
          onClick={() => closeModal()}
        />
        <div className="main">
          <p className="title">
            {t('userProfileModal.title').toUpperCase()}
          </p>
          <div className="form-container">
            {(!changePasswordMode)
              ? (
                <div className="section">
                  <div className="title-section">
                    <ChevronRight className="icon" />
                    <p className="text">
                      {t('userProfileModal.userData.title').toUpperCase()}
                    </p>
                  </div>
                  <p className="label">
                    {t('userProfileModal.userData.name')}
                  </p>
                  <div className="input-container">
                    <input
                      className="field"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <p className="label">
                    {t('userProfileModal.userData.lastName')}
                  </p>
                  <div className="input-container">
                    <input
                      className="field"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <p className="label">
                    {t('userProfileModal.userData.email')}
                  </p>
                  <div className="input-container">
                    <input
                      className="field"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="title-section button"
                    onClick={() => setChangePasswordMode(true)}
                  >
                    <LockOutlined className="icon" />
                    <p className="text clickable-text">
                      {t('userProfileModal.changePassword.title').toUpperCase()}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="section">
                  <div className="title-section">
                    <LockOutlined className="icon" />
                    <p className="text">
                      {t('userProfileModal.changePassword.title').toUpperCase()}
                    </p>
                  </div>
                  <p className="label">
                    {t('userProfileModal.changePassword.newPassword')}
                  </p>
                  <div className="input-container">
                    <input
                      className="field-password"
                      type={(openEye1) ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {(openEye1)
                      ? (
                        <VisibilityOutlined
                          className="eye-icon"
                          onClick={() => setOpenEye1(false)}
                        />
                      ) : (
                        <VisibilityOffOutlined
                          className="eye-icon"
                          onClick={() => setOpenEye1(true)}
                        />
                      )}
                  </div>
                  <p className="label">
                    {t('userProfileModal.changePassword.repeatPassword')}
                  </p>
                  <div className="input-container">
                    <input
                      className="field-password"
                      type={(openEye2) ? 'text' : 'password'}
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    {(openEye2)
                      ? (
                        <VisibilityOutlined
                          className="eye-icon"
                          onClick={() => setOpenEye2(false)}
                        />
                      ) : (
                        <VisibilityOffOutlined
                          className="eye-icon"
                          onClick={() => setOpenEye2(true)}
                        />
                      )}
                  </div>
                </div>
              )}
          </div>
          <button
            type="button"
            id="save-button"
            className="save-button"
            onClick={handleRequest}
          >
            <p className="text">
              {t('userProfileModal.saveButton').toUpperCase()}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserProfileModal;
