// Change: this file should be deleted when CTB problems are over or when inapps are completed

export default [
  {
    id: 1,
    productName: 'Latas Félix',
    maxUnits: 72,
    maxDisplays: 3,
    displayUnits: true,
    sku: false,
  },
  {
    id: 2,
    productName: 'Félix pouch',
    maxUnits: 15,
    maxDisplays: 4,
    displayUnits: false,
    sku: false,
  },
  {
    id: 3,
    productName: 'Dentalife Perro',
    maxUnits: 7,
    maxDisplays: 1,
    displayUnits: true,
    sku: true,
  },
  {
    id: 7,
    productName: 'Dentalife Gato',
    maxUnits: 12,
    maxDisplays: 1,
    displayUnits: true,
    sku: true,
  },
  {
    id: 4,
    productName: 'Doguitos',
    maxUnits: 15,
    maxDisplays: 1,
    displayUnits: false,
    sku: true,
  },
  {
    id: 5,
    productName: 'Dog Chow pouch',
    maxUnits: 15,
    maxDisplays: 3,
    displayUnits: false,
    sku: false,
  },
  {
    id: 6,
    productName: 'Felix Travesuras',
    maxUnits: 15,
    maxDisplays: 1,
    displayUnits: false,
    sku: true,
  },
];
