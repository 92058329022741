import { ReducerActionInterface, StateInterface } from '../interfaces';

export const initialState = {
  isLoading: false,
  isEmailConfirmed: false,
  user: {
    name: '',
    lastName: '',
    email: '',
  },
  store: {
    id: 0,
    name: '',
    rut: '',
    phone: '',
    businessName: '',
    order: '',
    storeSector: '',
    storeStreet: '',
    storeNumber: '',
    storeLocal: '',
    billingSector: '',
    billingStreet: '',
    billingNumber: '',
    billingLocal: '',
  },
  token: '',
  industryId: 0,
  cart: {},
  itemsCount: 0,
  activePurchase: false,
  passwordRecovery: false,
  sidebarOpen: false,
  sidebarCheckoutOpen: false,
};

export const reducer = (state: StateInterface, action: ReducerActionInterface): StateInterface => {
  switch (action.type) {
    case 'SIGN_OUT':
      return initialState;
    case 'LOG_IN':
      return {
        ...state,
        token: action.data.token,
        isEmailConfirmed: action.data.activated,
        industryId: action.data.industryId,
        user: action.data.user,
        store: action.data.store,
        activePurchase: action.data.purchase,
      };
    case 'LOADING':
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case 'SIGN_UP':
      return {
        ...state,
        token: action.data.token,
        isEmailConfirmed: false,
      };
    case 'EMAIL_CONFIRMED':
      return {
        ...state,
        isEmailConfirmed: action.data.activated,
        user: action.data.user,
        store: action.data.store,
        industryId: action.data.industryId,
        token: action.data.token,
        // Si se confirma el email, tiene que seguir cargando para que se cargue el catalogo
        isLoading: false,
      };
    case 'INITIALIZE':
      return {
        ...state,
        token: action.data.token,
        isEmailConfirmed: action.data.emailConfirmed,
        user: action.data.user,
        store: action.data.store,
        industryId: action.data.industryId,
        passwordRecovery: action.data.passwordRecovery,
        cart: action.data.cart,
        itemsCount: action.data.itemsCount,
      };
    case 'MODIFY_CART':
      return {
        ...state,
        cart: action.data.cart,
        itemsCount: action.data.itemsCount,
      };
    case 'CHECKOUT':
      return {
        ...state,
        cart: {},
        itemsCount: 0,
        activePurchase: true,
        isLoading: false,
      };
    case 'ACTIVE_PURCHASE':
      return {
        ...state,
        activePurchase: action.data.purchase,
      };
    case 'CHANGE_USER':
      return {
        ...state,
        user: action.data.user,
      };
    case 'SIDEBAR_SWITCH':
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case 'CHANGE_STORE':
      return {
        ...state,
        token: action.data.token,
        store: action.data.store,
      };
    case 'SIDEBAR_CHECKOUT_SWITCH':
      return {
        ...state,
        sidebarCheckoutOpen: !state.sidebarCheckoutOpen,
        sidebarOpen: false,
      };
    default:
      return state;
  }
};
