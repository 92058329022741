import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { Close } from '@material-ui/icons';

// Context
import GlobalContext from '../store/GlobalContext';

// Interfaces
import {
  StateInterface,
  ContextInterface,
  ContactUsModalProps,
} from '../interfaces';

// Helpers
import emailRegex from '../helpers/emailRegex';

// Style
import '../styles/ContactUsModalStyles.scss';

function ContactUsModal(props: ContactUsModalProps): ReactElement {
  const {
    visible,
    passwordRecovery = false,
  } = props;

  // Context
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { contactUs } = context;

  // Translation
  const { t } = useTranslation();

  // Component State
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [name, setName] = useState<string>((state.token.length > 0) ? `${state.user.name} ${state.user.lastName}` : '');
  const [mail, setMail] = useState<string>((state.token.length > 0) ? state.user.email : '');
  const [phone, setPhone] = useState<string>((state.token.length > 0) ? state.store.phone : '');
  const [message, setMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [correctRequest, setCorrectRequest] = useState<boolean>(false);

  function closeModal() {
    visible(false);
    setOpenModal(false);
  }

  useEffect(() => {
    if (openModal) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          closeModal();
        }
      };
    }
  }, [openModal]);

  // Validate Data
  useEffect(() => {
    if (name.length > 0 && mail.length > 0 && phone.length > 0 && message.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [name, mail, message, phone]);

  function handleRequest() {
    if (!isValid) {
      return;
    }
    if (!emailRegex.test(mail)) {
      setAlertMessage(t('contactUsModal.modal.incorrectMail'));
      setShowAlert(true);
      return;
    }
    const finalMessage = `Nombre: ${name}<br/><br/>Teléfono: ${phone}<br/><br/>Mensaje: ${message}`;
    contactUs('Web contact', finalMessage, mail)
      .then((res) => {
        if (res) {
          setAlertMessage(t('contactUsModal.modal.correct'));
          setCorrectRequest(true);
        } else {
          setAlertMessage(t('contactUsModal.modal.incorrect'));
        }
      })
      .catch(() => {
        setAlertMessage(t('contactUsModal.modal.incorrect'));
      });
    setShowAlert(true);
  }

  function closeAlert() {
    setShowAlert(false);
    if (correctRequest) {
      closeModal();
    }
  }

  // Close modal when press esc
  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (state.isLoading) {
        return;
      }
      if (event.code === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  // Render when the modal is close
  if (!openModal) {
    return null;
  }

  // Render of Alert
  if (showAlert) {
    return (
      <div className="App1">
        <div className="modal-alert">
          <div className="modal-alert-content">
            <div className="modal-alert-main">
              <p className="modal-alert-title">
                {alertMessage}
              </p>
              <button
                className="modal-alert-button"
                type="button"
                onClick={closeAlert}
              >
                <p>
                  {t('contactUsModal.modal.alertButton')}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render of modal
  return (
    <div className="App1">
      <div
        id="myModal"
        className="modal-contact"
      >
        <div className="modal-contact-content">
          <Close
            role="button"
            id="modal-close"
            onClick={() => closeModal()}
            onKeyPress={() => closeModal()}
          />
          <div className="modal-main">
            <p className="modal-title">
              {t('contactUsModal.modal.title')}
            </p>
            {(passwordRecovery) ? (
              <p className="modal-description">
                {t('contactUsModal.modal.descriptionPasswordRecovery')}
              </p>
            ) : (
              <p className="modal-description">
                {t('contactUsModal.modal.description1')}
                <br />
                {t('contactUsModal.modal.description2')}
              </p>

            )}
            <p className="modal-description-1">
              {t('contactUsModal.onlyMobile.modal.description1')}
            </p>
            <p className="modal-description-2">
              {t('contactUsModal.onlyMobile.modal.description2')}
            </p>
            <input
              type="text"
              placeholder={t('contactUsModal.modal.inputName')}
              id="modal-name"
              value={name}
              onChange={(element) => setName(element.currentTarget.value)}
            />
            <input
              type="email"
              placeholder={t('contactUsModal.modal.inputEmail')}
              id="modal-email"
              value={mail}
              onChange={(element) => setMail(element.currentTarget.value)}
            />
            <input
              type="text"
              placeholder={t('contactUsModal.modal.inputPhone')}
              id="modal-phone"
              value={phone}
              onChange={(e) => setPhone(e.currentTarget.value)}
            />
            <textarea
              placeholder={t('contactUsModal.modal.inputMessage')}
              id="modal-message"
              value={message}
              onChange={(element) => setMessage(element.currentTarget.value)}
            />
            <button
              className={isValid ? 'modal-button' : 'modal-button-not-ready'}
              type="button"
              onClick={isValid ? () => handleRequest() : null}
            >
              <p>
                {t('contactUsModal.modal.button')}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsModal;
