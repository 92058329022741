import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
} from 'react';

// Navigation history
import { useHistory } from 'react-router-dom';

// Translation
import { useTranslation } from 'react-i18next';

// Icons
import { ChevronRight, InfoOutlined } from '@material-ui/icons';

// Styles
import '../styles/PurchasesHistory.scss';

// Interfaces
import {
  StateInterface,
  ContextInterface,
  PurchaseHistoryResponse,
  CartArrayInterface,
  LocationState,
  CreditsInterface,
} from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

// Components
import Sidebar from '../components/Sidebar';
import Alert from '../components/Alert';
import Navbar from '../components/Navbar';
import SidebarCheckout from '../components/SidebarCheckout';
import CreditsModal from '../components/CreditsModal';

// Assets
import discount from '../assets/images/discount.png';
import formatNumber from '../helpers/formatNumber';

function PurchasesHistoryView() : ReactElement {
  // Translations strings
  const { t } = useTranslation();

  // Navigation history
  const history = useHistory();

  // Set Context variables
  const { state, context } : { state : StateInterface, context : ContextInterface } = useContext(GlobalContext);

  // Get context functions
  const {
    getPurchaseHistory,
    getPurchase,
  } = context;

  // Measure values data
  const displayMeasure: { [key: string]: string } = {
    unitario: t('purchasesHistoryView.measures.unity'),
    caja: t('purchasesHistoryView.measures.box'),
    display: t('purchasesHistoryView.measures.display'),
    bolsa: t('purchasesHistoryView.measures.bag'),
  };

  // Set state of component
  const [purchases, setPurchases] = useState<PurchaseHistoryResponse[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<string>('');
  const [cart, setCart] = useState<CartArrayInterface>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);
  const [failedLoadHistory, setFailedLoadHistory] = useState<boolean>(false);
  const [failedLoadPurchase, setFailedLoadPurchase] = useState<boolean>(false);
  const [credits, setCredits] = useState<CreditsInterface[]>([]);
  const [totalCredits, setTotalCredits] = useState<number>(0);
  const [showModalCredits, setShowModalCredits] = useState<boolean>(false);

  // Load Purchases
  useEffect(() => {
    getPurchaseHistory(state)
      .then((response) => setPurchases(response))
      .catch(() => {
        setFailedLoadHistory(true);
      });
  }, []);

  // Select a purchase
  function selectPurchase(purchase : PurchaseHistoryResponse) {
    getPurchase(state, purchase.verificationCode)
      .then((response) => {
        setSelectedOrder(purchase.verificationCode);
        setCart(Object.values(response.cart));
        setCartTotal(purchase.total);
        setCredits(response.credits);
      })
      .catch(() => {
        setFailedLoadPurchase(true);
      });
  }

  // Calculating total discount of credits
  useEffect(() => {
    if (credits.length > 0) {
      let total = 0;
      credits.forEach((credit) => {
        total += credit.dealingCreditsAmount;
      });
      setTotalCredits(total);
    }
  }, [credits]);

  // Load default selected purchase
  useEffect(() => {
    if (purchases.length > 0) {
      selectPurchase(purchases[0]);
      setMounted(true);
    }
  }, [purchases]);

  // Back handler
  function back(): void {
    if (history.location.state) {
      const locationState = history.location.state as LocationState;
      if (locationState.purchase) {
        history.push('/partners');
      } else if (locationState.parent) {
        history.push(locationState.parent);
      } else {
        history.goBack();
      }
    } else {
      history.push('/partners');
    }
  }

  function closeFailedHistory(value : boolean) : void {
    setFailedLoadHistory(value);
    back();
  }

  // Format date to render string
  function formatDate(date : string) {
    const newDate = new Date(date);
    return `${newDate.toLocaleDateString().replaceAll('-', '/')} - ${newDate.toLocaleTimeString().slice(0, 5)}`;
  }

  function formatPartner(partner: string): string {
    const formatedPartner = (partner.split('.')[1])
      ? partner.split('.')[1].substring(1).toUpperCase()
      : partner.toUpperCase();

    return formatedPartner;
  }

  // Render for Load or empty purchases
  if (!mounted || purchases.length === 0 || failedLoadHistory) {
    return (
      <div className="orders-container">
        <Navbar
          back
          backText={t('purchasesHistoryView.back')}
          backFunction={back}
        />
        {failedLoadHistory && (
          <Alert
            buttonText={t('purchasesHistoryView.alert.button')}
            buttonFunction={closeFailedHistory}
          >
            <p>
              {t('purchasesHistoryView.alert.failedHistory.line1')}
            </p>
            <p>
              {t('purchasesHistoryView.alert.failedHistory.line2')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('purchasesHistoryView.alert.failedHistory.line3')}
            </p>
          </Alert>
        )}
      </div>
    );
  }

  // Render of View
  return (
    <div className="orders-container">
      <Navbar
        back
        backText={t('purchasesHistoryView.back')}
        backFunction={back}
      />
      <Sidebar />
      <SidebarCheckout />
      <div className="history">
        <div className="orders-list">
          <div className="header">
            <div className="title">
              <ChevronRight className="icon" />
              <p className="text">
                {t('purchasesHistoryView.title').toUpperCase()}
              </p>
            </div>
            <p className="subtitle">
              {t('purchasesHistoryView.subtitle')}
            </p>
          </div>
          <div className="list">
            {purchases.map((purchase) => (
              <div
                key={purchase.id}
                className={(selectedOrder === purchase.verificationCode) ? 'element' : 'element not-click'}
                onClick={() => selectPurchase(purchase)}
              >
                <div className="sector left">
                  <p className={(selectedOrder === purchase.verificationCode)
                    ? 'text purchase-id'
                    : 'text purchase-id click-text'}
                  >
                    {purchase.verificationCode}
                  </p>
                  <p className={(selectedOrder === purchase.verificationCode) ? 'text' : 'text unselected click-text'}>
                    {`${formatDate(purchase.createdAt)} ${t('purchasesHistoryView.purchase.time')}.`}
                  </p>
                </div>
                <div className="sector right">
                  <p className={(selectedOrder === purchase.verificationCode) ? 'text hide' : 'text click-text'}>
                    {(selectedOrder === purchase.verificationCode) ? '.' : t('purchasesHistoryView.purchase.button')}
                  </p>
                  <p className={(selectedOrder === purchase.verificationCode) ? 'text' : 'text unselected click-text'}>
                    {`$${purchase.total.toLocaleString('de-DE')}`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="order-details">
          <div className="details">
            {cart.map((partner, index) => (
              <div
                key={partner.id}
                className="partner"
              >
                <div className={(index === 0) ? 'header none-border' : 'header'}>
                  <div className="name">
                    <ChevronRight className="icon" />
                    <p className="text">
                      {formatPartner(partner.name)}
                    </p>
                  </div>
                  <p className="total">
                    {`$${partner.total.toLocaleString('de-DE')}`}
                  </p>
                </div>
                <div className="products">
                  {Object.values(partner.cart).map((product, i) => (
                    <div
                      key={product.id}
                      className={(i === 0) ? 'product none-border' : 'product'}
                    >
                      {(product.specific.promotionExists) && (
                        <img
                          className="discount"
                          src={discount}
                          alt="discount logo"
                        />
                      )}
                      <img
                        src={product.image}
                        className="image"
                        alt={`${product.brand} - ${product.name}`}
                      />
                      <div className={(product.name.length > 20) ? 'product-data long-data' : 'product-data'}>
                        <p className="text">
                          {product.brand}
                        </p>
                        <p className="text name">
                          {product.name}
                        </p>
                        <p className="text">
                          {(displayMeasure[product.displayMeasure] === t('purchasesHistoryView.measures.unity'))
                            ? `${displayMeasure[product.displayMeasure]} ${product.weight}${product.measure}`
                            : `${displayMeasure[product.displayMeasure]} `
                            + `${product.display} x ${product.weight}${product.measure}`}
                        </p>
                      </div>
                      <div className="quatity-container">
                        <div className="quantity-circle">
                          <p className="number">
                            {product.quantity}
                          </p>
                        </div>
                      </div>
                      <div className="values">
                        <p className="text slim">
                          {t('purchasesHistoryView.details.total')}
                        </p>
                        <p className="text">
                          {`$${product.totalProduct.toLocaleString('de-DE')}`}
                        </p>
                        <p className="text slim">
                          {`${product.specific.price}/u`}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {(credits.length > 0) && (
            <div className="credits-container">
              <div className="info-container">
                <div className="left">
                  <p className="gray">
                    {t('sideBarCheckout.credits.subtotal')}
                  </p>
                  <p
                    className="blue"
                    onClick={() => setShowModalCredits(true)}
                  >
                    {t('sideBarCheckout.credits.name')}
                    <InfoOutlined className="icon" />
                  </p>
                </div>
                <div className="right">
                  <p className="gray">
                    {formatNumber(cartTotal + totalCredits, '$ ', '')}
                  </p>
                  <p className="blue">
                    {formatNumber(totalCredits, '- $ ', '')}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className={(credits.length > 0) ? 'price' : 'price shadow'}
          >
            <p className="text">
              {t('purchasesHistoryView.price.description')}
            </p>
            <p className="text number">
              {`$${cartTotal.toLocaleString('de-DE')}`}
            </p>
            <button
              type="button"
              className="button"
            >
              <p className="text">
                {t('purchasesHistoryView.price.button').toUpperCase()}
              </p>
            </button>
          </div>
        </div>
      </div>
      {(showModalCredits) && (
        <CreditsModal
          visible={setShowModalCredits}
          creditsFromCart={credits}
          isFromPurchasesHistory
        />
      )}
      {failedLoadPurchase && (
        <Alert
          buttonText={t('purchasesHistoryView.alert.button')}
          buttonFunction={setFailedLoadPurchase}
        >
          <p>
            {t('purchasesHistoryView.alert.failedPurchase.line1')}
          </p>
          <p>
            {t('purchasesHistoryView.alert.failedPurchase.line2')}
          </p>
          <p>
            &nbsp;
          </p>
          <p className="bold">
            {t('purchasesHistoryView.alert.failedPurchase.line3')}
          </p>
        </Alert>
      )}
    </div>
  );
}

export default PurchasesHistoryView;
