import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import Select from 'react-select';
import moment from 'moment';

// Icons
import {
  AccountCircleOutlined,
  AlarmOutlined,
  CheckCircleOutline,
  CheckCircleOutlined,
  ChevronRight,
  Close,
  EditOutlined,
  FileCopyOutlined,
  HelpOutline,
  InfoOutlined,
  LiveHelpOutlined,
  LocalShippingOutlined,
  LocationOn,
  SentimentDissatisfied,
  ToggleOffOutlined,
  ToggleOnOutlined,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

// Interfaces
import {
  ContextInterface,
  PartnerInterface,
  ProductInterface,
  StateInterface,
  StockIssues,
  SectorInterface,
  PredictedDeliveryResponse,
  CreditsInterface,
  CreditsBodyInterface,
} from '../interfaces';

// Components
import StockIssuesModal from './StockIssuesModal';
import Product from './Product';
import UserSavings from './UserSavings';
import CreditsModal from './CreditsModal';

// Helpers
import formatNumber from '../helpers/formatNumber';
import phoneRegex from '../helpers/phoneRegex';

// Style
import '../styles/SidebarCheckoutStyles.scss';

// Context
import GlobalContext from '../store/GlobalContext';

function SidebarCheckout() : ReactElement {
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const {
    sidebarCheckoutSwitch,
    checkFirstOrder,
    checkout,
    checkStock,
    replaceCart,
    getSectors,
    getDeliveryPredictions,
  } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  // States
  const [productsInCart, setProductsInCart] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalCorrect, setTotalCorrect] = useState<boolean>(false);
  const [userSaving, setUserSaving] = useState<boolean>(false);
  const [actualPartner, setActualPartner] = useState<PartnerInterface>();
  const [partnerView, setPartnerView] = useState<boolean>(true);
  const [loadingCart, setLoadingCart] = useState<boolean>(false);
  const [modalData, setModalData] = useState<StockIssues>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [checkoutError, setCheckoutError] = useState<boolean>(false);

  const [isFirstPurchase, setIsFirstPurchase] = useState<boolean>(true);
  const [dataOfficial, setDataOfficial] = useState<string[]>([]);
  const [isStoreAddress, setIsStoreAddress] = useState<boolean>(false);
  const [isBillingAddress, setIsBillingAddress] = useState<boolean>(false);
  const [isMount, setIsMount] = useState<boolean>(false);
  const [isFirstPurchaseConstant, setIsFirstPurchaseConstant] = useState<boolean>(true);
  const [useDifferentAddress, setUseDifferentAddress] = useState<boolean>(false);
  const [storeSector, setStoreSector] = useState<string>('');
  const [storeAddress, setStoreAddress] = useState<string>('');
  const [storeAddressSugestionPressed, setStoreAddressSugestionPressed] = useState<boolean>(false);
  const [askForStoreAddress, setAskForStoreAddress] = useState<boolean>(false);
  const [storeAddressStreet, setStoreAddressStreet] = useState<string>('');
  const [storeAddressNumber, setStoreAddressNumber] = useState<string>('');
  const [isValidStoreAddress, setIsValidStoreAddress] = useState<boolean>(false);
  const [storeLocal, setStoreLocal] = useState<string>('');
  const [storePhone, setStorePhone] = useState<string>('');
  const [storeInstructions, setStoreInstructions] = useState<string>('');
  const [billingSector, setBillingSector] = useState<string>('');
  const [billingAddress, setBillingAddress] = useState<string>('');
  const [billingAddressSugestionPressed, setBillingAddressSugestionPressed] = useState<boolean>(false);
  const [askForBillingAddress, setAskForBillingAddress] = useState<boolean>(false);
  const [billingAddressStreet, setBillingAddressStreet] = useState<string>('');
  const [billingAddressNumber, setBillingAddressNumber] = useState<string>('');
  const [isValidBillingAddress, setIsValidBillingAddress] = useState<boolean>(true);
  const [billingLocal, setBillingLocal] = useState<string>('');
  const [minData, setMinData] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [sectors, setSectors] = useState<SectorInterface[]>([]);
  const [storeAddressConstant, setStoreAddressConstant] = useState<string>('');
  const [storeSectorConstant, setStoreSectorConstant] = useState<string>('');
  const [storeLocalConstant, setStoreLocalConstant] = useState<string>('');
  const [billingAddressConstant, setBillingAddressConstant] = useState<string>('');
  const [billingSectorConstant, setBillingSectorConstant] = useState<string>('');
  const [billingLocalConstant, setBillingLocalConstant] = useState<string>('');
  const [askUpdateInfo, setAskUpdateInfo] = useState<boolean>(false);
  const [showSectorExplanation, setShowSectorExplanation] = useState<boolean>(false);
  const [searchSector, setSearchSector] = useState<string>('');
  const [predictions, setPredictions] = useState<PredictedDeliveryResponse['predictions']>({});
  const [credits, setCredits] = useState<PredictedDeliveryResponse['credits']>([]);
  const [totalCredits, setTotalCredits] = useState<number>(0);
  const [showModalCredits, setShowModalCredits] = useState<boolean>(false);
  // Change: Delete this when payment methods are integrated
  const [donPanchoAmount, setDonPanchoAmount] = useState<number>(0);
  const [donPanchoModalVisible, setDonPanchoModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setSearchSector(billingSector);
  }, [billingSector]);

  // Create a new session token.
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      sessionToken,
      componentRestrictions: { country: 'cl' },
    },
  });

  const outsideClickRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleSelect = (description: string, isDelivery: boolean) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    if (isDelivery) {
      setStoreAddress(description);
      setStoreAddressSugestionPressed(true);
    } else {
      setBillingAddress(description);
      setBillingAddressSugestionPressed(true);
    }
    setValue(description, false);
    clearSuggestions();
  };

  function setValueAux(val: string, isDelivery: boolean) {
    if (isDelivery) {
      setIsStoreAddress(true);
      setIsBillingAddress(false);
      setStoreAddress(val);
    } else {
      setIsStoreAddress(false);
      setIsBillingAddress(true);
      setBillingAddress(val);
    }
    setValue(val);
  }

  function setAddressInputStyle(isStore: boolean): string {
    if (isStore) {
      if (storeAddress.length === 0 || (storeAddressSugestionPressed && isValidStoreAddress)) {
        return '';
      } return 'not-valid';
    }
    if (billingAddress.length === 0 || (billingAddressSugestionPressed && isValidBillingAddress)) {
      return '';
    } return 'not-valid';
  }

  function acceptSuggestion() {
    if (askForStoreAddress) {
      setStoreAddressSugestionPressed(true);
      setAskForStoreAddress(false);
    } else {
      setBillingAddressSugestionPressed(true);
      setAskForBillingAddress(false);
    }
  }

  useEffect(() => {
    const aux: string[] = [];
    data.forEach((suggestion) => {
      aux.push(suggestion.structured_formatting.main_text);
    });
    setDataOfficial(Array.from(new Set(aux)));
  }, [data]);

  useEffect(() => {
    if (state.cart) {
      if (state.sidebarCheckoutOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
        document.body.style.overflowX = 'hidden';
      }
    }
  }, [state.sidebarCheckoutOpen]);

  function closeSidebar() {
    setPartnerView(true);
    setIsFirstPurchase(isFirstPurchaseConstant);
    sidebarCheckoutSwitch();
  }

  function changePartnerView(bool: boolean) {
    setPartnerView(bool);
    const myDiv = document.getElementById('container');
    myDiv.scrollTop = 0;
  }

  useEffect(() => {
    if (!partnerView && sectors.length === 0) {
      getSectors(true)
        .then((sectorsList: SectorInterface[]): void => setSectors(sectorsList))
        .catch(() => {
          setCheckoutError(true);
          setPartnerView(true);
        });
    }
  }, [partnerView]);

  useEffect(() => {
    const partnerIds = Object.values(state.cart).map((partner) => partner.id);
    getDeliveryPredictions(state, partnerIds)
      .then((response) => {
        setPredictions(response.predictions);
        const useCredits: CreditsInterface[] = [];
        let totalDiscount = 0;
        response.credits.forEach((credit) => {
          if (credit.partnerName === 'Dealing') {
            let total = 0;
            Object.values(state.cart).forEach((partner) => {
              total += partner.total;
            });
            if (total >= credit.minimumAmountOfMoney) {
              totalDiscount += credit.dealingCreditsAmount;
              useCredits.push(credit);
            }
          } else if (partnerIds.includes(credit.partnerId)) {
            const partnerCart: PartnerInterface[] = Object.values(state.cart).filter(
              (partner) => partner.id === credit.partnerId,
            );
            if (partnerCart[0].total >= credit.minimumAmountOfMoney) {
              totalDiscount += credit.dealingCreditsAmount;
              useCredits.push(credit);
            }
          }
        });
        setCredits(useCredits);
        setTotalCredits(totalDiscount);
        setLoadingCart(false);
      })
      .catch(() => {
        setCheckoutError(true);
        setLoadingCart(false);
      });
  }, [state.cart]);

  useEffect(() => {
    if (state.cart && Object.values(state.cart).length > 0) {
      setProductsInCart(true);
      let total = 0;
      setTotalCorrect(true);
      Object.values(state.cart).forEach((partner) => {
        total += partner.total;
        if (partner.minBuy > partner.total) {
          setTotalCorrect(false);
        }
      });
      setTotalPrice(total);
    } else {
      setTotalPrice(0);
      setProductsInCart(false);
    }
  }, [state.cart]);

  // Change: Delete this when payment methods are integrated
  useEffect(() => {
    Object.values(state.cart).forEach((partner) => {
      const partnerName = (partner.name.split('.')[1]) ? partner.name.split('.')[1].substring(1) : partner.name;
      if (partnerName === 'Don Pancho') {
        setDonPanchoAmount(partner.total);
      }
    });
  }, [state.cart]);

  useEffect(() => {
    if (!partnerView) {
      checkFirstOrder(state, true)
        .then((response) => {
          if (!response.response) {
            setIsFirstPurchase(true);
            setIsFirstPurchaseConstant(true);
            setIsMount(true);
            setStoreSector(response.details.storeSector);
          } else {
            setIsFirstPurchase(false);
            setIsFirstPurchaseConstant(false);
            const storeInfo = response.details.storeAddress.split(', ');
            setStoreAddress(storeInfo[0]);
            setStoreAddressConstant(storeInfo[0]);
            setStoreAddressSugestionPressed(true);
            if (storeInfo.length > 2) {
              setStoreLocal(storeInfo[1]);
              setStoreLocalConstant(storeInfo[1]);
              setStoreSector(storeInfo[2]);
              setStoreSectorConstant(storeInfo[2]);
            } else {
              setStoreSector(storeInfo[1]);
              setStoreSectorConstant(storeInfo[1]);
            }
            const billingInfo = response.details.billingAddress.split(', ');
            setBillingAddress(billingInfo[0]);
            setBillingAddressConstant(billingInfo[0]);
            setBillingAddressSugestionPressed(true);
            if (billingInfo.length > 2) {
              setBillingLocal(billingInfo[1]);
              setBillingLocalConstant(billingInfo[1]);
              setBillingSector(billingInfo[2]);
              setBillingSectorConstant(billingInfo[2]);
            } else {
              setBillingSector(billingInfo[1]);
              setBillingSectorConstant(billingInfo[1]);
            }
            if (response.details.phone.substr(0, 4) === `${t('sideBarCheckout.checkout.phoneCode')} `) {
              setStorePhone(response.details.phone.substr(4, 15));
            } else {
              setStorePhone(response.details.phone);
            }
            setIsMount(true);
          }
        })
        .catch(() => {
          setCheckoutError(true);
        });
    }
  }, [partnerView]);

  useEffect(() => {
    if (useDifferentAddress) {
      if (isValidStoreAddress
        && storeAddressSugestionPressed
        && storePhone.length === 11
        && isValidBillingAddress
        && billingAddressSugestionPressed
        && billingAddress) {
        setMinData(true);
      } else setMinData(false);
    } else if (isValidStoreAddress
      && storeAddressSugestionPressed
        && storePhone.length === 11) {
      setMinData(true);
    } else setMinData(false);
  }, [isValidStoreAddress, storeAddressSugestionPressed,
    storePhone, useDifferentAddress,
    billingSector,
    isValidBillingAddress, billingAddressSugestionPressed]);

  // Function that split the street and its number
  function splitAddress(address: string) {
    const selectedAddressSplited = address.split(' ');
    const selectedDeliveryAddressNumber = selectedAddressSplited.pop();
    const selectedDeliveryAddressStreet = selectedAddressSplited.join(' ');
    return [selectedDeliveryAddressStreet, selectedDeliveryAddressNumber];
  }

  function handleSelectedStoreAddress(address: string) {
    const [selectedStoreAddressStreet, selectedStoreAddressNumber] = splitAddress(address);
    // Check if 'selectedStoreAddressNumber' contains only numbers
    // +'<anyString>' transforms to number if possible or NaN if not possible
    if (selectedStoreAddressNumber.length > 0
      && !Number.isNaN(+selectedStoreAddressNumber)
    ) {
      setStoreAddressNumber(selectedStoreAddressNumber);
      setStoreAddressStreet(selectedStoreAddressStreet);
      setIsValidStoreAddress(true);
      if (!useDifferentAddress) {
        setIsValidBillingAddress(true);
      }
    } else {
      // No number in address
      setIsValidStoreAddress(false);
      if (!useDifferentAddress) {
        setIsValidBillingAddress(false);
      }
    }
  }

  function handleSelectedBillingAddress(address: string) {
    const [selectedBillingAddressStreet, selectedBillingAddressNumber] = splitAddress(address);
    // Check if 'selectedBillingAddressNumber' contains only numbers
    // +'<anyString>' transforms to number if possible or NaN if not possible
    if (selectedBillingAddressNumber.length > 0
      && !Number.isNaN(+selectedBillingAddressNumber)
    ) {
      setBillingAddressNumber(selectedBillingAddressNumber);
      setBillingAddressStreet(selectedBillingAddressStreet);
      setIsValidBillingAddress(true);
    } else {
      // No number in address
      setIsValidBillingAddress(false);
    }
  }

  function handleSubmit() {
    if (storeAddressSugestionPressed && billingAddressSugestionPressed) {
      const creditIds: CreditsBodyInterface = {};
      credits.forEach((credit) => {
        if (!creditIds[credit.partnerId]) {
          creditIds[credit.partnerId] = [credit.id];
        } else {
          creditIds[credit.partnerId].push(credit.id);
        }
      });
      checkout(state, {
        firstPurchase: isFirstPurchaseConstant,
        deliveryAddressSector: storeSector,
        deliveryAddressStreet: storeAddressStreet,
        deliveryAddressNumber: storeAddressNumber,
        deliveryAddressLocal: storeLocal,
        invoicingAddressSector: (useDifferentAddress) ? billingSector : storeSector,
        invoicingAddressStreet: (useDifferentAddress) ? billingAddressStreet : storeAddressStreet,
        invoicingAddressNumber: (useDifferentAddress) ? billingAddressNumber : storeAddressNumber,
        invoicingAddressLocal: (useDifferentAddress) ? billingLocal : storeLocal,
        phone: `${t('sideBarCheckout.checkout.phoneCode')} ${storePhone}`,
        business: state.store.order,
        businessName: state.store.businessName,
        instructions: storeInstructions,
        creditIds,
      })
        .then((response) => {
          setDonPanchoModalVisible(donPanchoAmount > 0);
          setVerificationCode(response.verificationCode);
          setIsFirstPurchase(false);
          setIsFirstPurchaseConstant(false);
          if (!useDifferentAddress) {
            setBillingSector(storeSector);
            setBillingAddress(storeAddress);
            setBillingAddressStreet(storeAddressStreet);
            setBillingAddressNumber(storeAddressNumber);
            setBillingLocal(storeLocal);
          }
          closeSidebar();
        })
        .catch(() => {
          setCheckoutError(true);
        });
    }
  }

  useEffect(() => {
    handleSelectedStoreAddress(storeAddress);
  }, [storeAddress]);

  useEffect(() => {
    handleSelectedBillingAddress(billingAddress);
  }, [billingAddress]);

  useEffect(() => {
    if (!useDifferentAddress) {
      setIsValidBillingAddress(isValidStoreAddress);
    }
  }, [useDifferentAddress]);

  useEffect(() => {
    if (!state.sidebarCheckoutOpen && verificationCode.length > 0) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          setVerificationCode('');
        }
      };
    }
  }, [verificationCode]);

  function editAddress() {
    if (storeAddress !== billingAddress
      || storeSector !== billingSector
      || storeLocal !== billingLocal) {
      setUseDifferentAddress(true);
    } else {
      setUseDifferentAddress(false);
    }
    setIsFirstPurchase(true);
  }

  function updateInfoResponse(bool: boolean) {
    setAskUpdateInfo(false);
    if (!bool) {
      setIsFirstPurchase(false);
      setStoreAddress(storeAddressConstant);
      setStoreSector(storeSectorConstant);
      setStoreLocal(storeLocalConstant);
      setBillingAddress(billingAddressConstant);
      setBillingSector(billingSectorConstant);
      setBillingLocal(billingLocalConstant);
    }
  }

  function verificateIsFirstPurchase() {
    if (isFirstPurchaseConstant) {
      changePartnerView(true);
    } else {
      setAskUpdateInfo(true);
    }
  }

  // When the modal data is updated, setModalVisible to true
  useEffect(() => { if (Object.values(modalData).length > 0) setModalVisible(true); }, [modalData]);

  if (!state.sidebarCheckoutOpen && verificationCode.length === 0) {
    return null;
  }

  if (!state.sidebarCheckoutOpen && verificationCode.length > 0) {
    // Change: Delete this when payment methods are integrated
    if (donPanchoModalVisible) {
      return (
        <div
          className="don-pancho-view"
          id="myModal"
        >
          <div className="don-pancho-container">
            <p className="title">
              {t('sideBarCheckout.checkout.donPanchoData.title')}
            </p>
            <div className="content">
              <p className="description">
                {t('sideBarCheckout.checkout.donPanchoData.description')}
              </p>
              <p className="label">
                {t('sideBarCheckout.checkout.donPanchoData.amount')}
              </p>
              <p className="information">
                {formatNumber(donPanchoAmount, '$ ', '')}
              </p>
              <p className="label">
                {t('sideBarCheckout.checkout.donPanchoData.rut')}
              </p>
              <p className="information">
                {t('sideBarCheckout.checkout.donPanchoData.rutValue')}
              </p>
              <p className="label">
                {t('sideBarCheckout.checkout.donPanchoData.account')}
              </p>
              <p className="information no-space">
                {t('sideBarCheckout.checkout.donPanchoData.accountValue')}
              </p>
              <p className="information">
                {t('sideBarCheckout.checkout.donPanchoData.accountType')}
              </p>
              <p className="label">
                {t('sideBarCheckout.checkout.donPanchoData.name')}
              </p>
              <p className="information">
                {t('sideBarCheckout.checkout.donPanchoData.nameValue')}
              </p>
              <p className="label">
                {t('sideBarCheckout.checkout.donPanchoData.mail')}
              </p>
              <p className="information last">
                {t('sideBarCheckout.checkout.donPanchoData.mailValue')}
              </p>
            </div>
            <button
              className="button"
              type="button"
              onClick={() => setDonPanchoModalVisible(false)}
            >
              {t('sideBarCheckout.checkout.donPanchoData.accept').toUpperCase()}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div
        className="checkout-finish-view"
        id="myModal"
      >
        <div className="checkout-finish-container">
          <Close
            className="close-icon"
            onClick={() => setVerificationCode('')}
          />
          <p className="title">
            {t('sideBarCheckout.checkout.successCheckout.title')}
          </p>
          <div className="information-container">
            <CheckCircleOutlined className="icon" />
            <p className="information">
              {verificationCode}
            </p>
            <p className="name">
              {t('sideBarCheckout.checkout.successCheckout.id')}
            </p>
          </div>
          <div className="information-container">
            <LocalShippingOutlined className="icon" />
            <p className="information2">
              {(storeLocal.length > 0)
                ? `${storeAddress}, ${storeLocal}, ${storeSector}`
                : `${storeAddress}, ${storeSector}`}
            </p>
            <p className="name">
              {t('sideBarCheckout.checkout.successCheckout.address')}
            </p>
          </div>
          <button
            type="button"
            className="specific-information"
            onClick={() => history.push('/purchases', { parent: history.location.pathname, purchase: true })}
          >
            {t('sideBarCheckout.checkout.successCheckout.specificInformation')}
          </button>
        </div>
      </div>
    );
  }

  if (productsInCart) {
    return (
      <div className="sidebar-checkout-view">
        <div className="sidebar-checkout-container">
          {(partnerView)
            ? (
              <Close
                className="close-icon"
                onClick={() => closeSidebar()}
              />
            ) : (
              <div
                className="sidebar-back-container"
                onClick={(isFirstPurchase) ? () => verificateIsFirstPurchase() : () => changePartnerView(true)}
              >
                <ChevronRight className="icon" />
                <p>
                  {t('sideBarCheckout.checkout.back')}
                </p>
              </div>
            )}
          <div className="info-store">
            <AccountCircleOutlined className="account-icon" />
            <p className="name">
              {state.store.name}
            </p>
          </div>
          {(partnerView)
            ? (
              <div
                className={(credits.length > 0)
                  ? 'partners-container partners-container-credits' : 'partners-container'}
                id="container"
              >
                {Object.values(state.cart).map((partner: PartnerInterface, i) => (
                  <div
                    className={i === 0 ? 'info-partner none-border' : 'info-partner'}
                    key={partner.id}
                  >
                    <div className="info1">
                      <div className="left">
                        <ChevronRight className="icon" />
                        <p className="name">
                          {(partner.name.split('.')[1])
                            ? partner.name.split('.')[1].substring(1).toUpperCase()
                            : partner.name.toUpperCase()}
                        </p>
                      </div>
                      <div className="right">
                        {(loadingCart)
                          ? (
                            <CircularProgress className="spinner" />
                          ) : (
                            <p className="mount">
                              {formatNumber(partner.total, '$ ', '')}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="info2">
                      <div className="left">
                        <CheckCircleOutline className="icon" />
                        <p className="info">
                          {`${t('sideBarCheckout.minBuy')} ${formatNumber(partner.minBuy, '$ ', '')}`}
                        </p>
                      </div>
                      {(partner.total >= partner.minBuy)
                        ? (
                          <div className="right">
                            {(partner.total < partner.totalNoPromo) && (
                              <p className="old-price">
                                {!loadingCart && formatNumber(partner.totalNoPromo, '$ ', '')}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="right">
                            <p className="info-min">
                              {t('sideBarCheckout.toMinBuy')}
                            </p>
                          </div>
                        )}
                    </div>
                    <div className="info2">
                      <div className="left">
                        <AlarmOutlined className="icon" />
                        <p className="info">
                          {`
                          ${t('catalogView.deliveryDate')} 
                          ${moment(predictions[partner.id]).format('dddd D')}`}
                        </p>
                      </div>
                      {(partner.total >= partner.minBuy)
                        ? (
                          <div className="right">
                            {(!loadingCart && partner.total < partner.totalNoPromo) && (
                              <div
                                className="info-discount"
                                onClick={() => { setUserSaving(true); setActualPartner(partner); }}
                              >
                                {t('sideBarCheckout.seeSavings')}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="right">
                            <p className="info-min">
                              {formatNumber(partner.minBuy - partner.total, '$ ', '')}
                            </p>
                          </div>
                        )}
                    </div>
                    <div className="products">
                      {Object.values(partner.cart).map((product: ProductInterface, j) => (
                        <Product
                          key={product.id}
                          product={product}
                          partner={partner}
                          cartView
                          loadingCart={loadingCart}
                          setLoadingCart={setLoadingCart}
                          isFirst={j === 0}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {(isFirstPurchase)
                  ? (
                    <div
                      className="checkout-container"
                      id="container"
                    >
                      <div className="info1">
                        <div className="left">
                          <ChevronRight className="icon" />
                          <p className="name">
                            {t('sideBarCheckout.checkout.title')}
                          </p>
                        </div>
                      </div>
                      {(isMount) && (
                        <div className="inputs-container-checkout">
                          <div className="title">
                            <LocalShippingOutlined className="icon" />
                            <p>
                              {t('sideBarCheckout.checkout.deliveryInformation')}
                            </p>
                          </div>
                          <div className="input-container">
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeSector')}
                            </p>
                            <input
                              type="text"
                              value={storeSector}
                              placeholder={t('sideBarCheckout.checkout.storeSectorExample')}
                              readOnly
                            />
                            <HelpOutline
                              className="help-icon"
                              onClick={() => setShowSectorExplanation(true)}
                            />
                          </div>
                          <div
                            className="input-container"
                            ref={outsideClickRef}
                          >
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeAddress')}
                            </p>
                            <input
                              value={storeAddress}
                              onChange={(e) => {
                                setStoreAddressSugestionPressed(false);
                                setValueAux(e.target.value, true);
                              }}
                              className={setAddressInputStyle(true)}
                              disabled={!ready}
                              placeholder={t('sideBarCheckout.checkout.storeAddressExample')}
                            />
                            {(storeAddress.length > 0
                            && isValidStoreAddress
                            && !storeAddressSugestionPressed) && (
                              <HelpOutline
                                className="help-icon"
                                onClick={() => setAskForStoreAddress(true)}
                              />
                            )}
                            {/* We can use the "status" to decide whether we should display the dropdown or not */}
                            {(status === 'OK' && isStoreAddress) && (
                              <ul>
                                {dataOfficial.map((suggestion, i) => (
                                  <li
                                    key={i.toString()}
                                    onClick={handleSelect(suggestion, true)}
                                    className={(i === 0) ? 'none-border' : ''}
                                  >
                                    <LocationOn className="icon" />
                                    <p>
                                      {suggestion}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          <div className="input-container">
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeLocal')}
                            </p>
                            <input
                              type="text"
                              value={storeLocal}
                              onChange={(e) => setStoreLocal(e.target.value)}
                              placeholder={t('sideBarCheckout.checkout.storeLocalExample')}
                            />
                          </div>
                          <div className="input-container">
                            <p className="label">
                              {t('sideBarCheckout.checkout.storePhone')}
                            </p>
                            <input
                              type="text"
                              className={(storePhone.length === 0 || storePhone.length === 11)
                                ? 'phone-input' : 'phone-input not-valid'}
                              value={storePhone}
                              onChange={(e) => setStorePhone(phoneRegex(e.target.value))}
                              placeholder={t('sideBarCheckout.checkout.storePhoneExample')}
                            />
                            <div className="phone-label-container">
                              <p className="phone-label">{t('sideBarCheckout.checkout.phoneCode')}</p>
                            </div>
                          </div>
                          <div className="different-address-container">
                            {(useDifferentAddress)
                              ? (
                                <ToggleOnOutlined
                                  className="icon on"
                                  onClick={() => setUseDifferentAddress(false)}
                                />
                              ) : (
                                <ToggleOffOutlined
                                  className="icon"
                                  onClick={() => setUseDifferentAddress(true)}
                                />
                              )}
                            <p className={(useDifferentAddress) ? 'on' : ''}>
                              {t('sideBarCheckout.checkout.useAnotherAddress')}
                            </p>
                          </div>
                        </div>
                      )}
                      {(useDifferentAddress) && (
                        <div className="inputs-container-checkout">
                          <div className="title">
                            <FileCopyOutlined className="icon" />
                            <p>
                              {t('sideBarCheckout.checkout.billingInformation')}
                            </p>
                          </div>
                          <div className="input-container">
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeSector')}
                            </p>
                            <Select
                              className="select-container"
                              classNamePrefix="select"
                              options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                              placeholder={t('sideBarCheckout.checkout.storeSectorExample')}
                              defaultValue={(billingSector.length > 0) && { value: 0, label: billingSector }}
                              onChange={(e) => { if (e) { setBillingSector(e.label); } }}
                              onFocus={() => setSearchSector(billingSector)}
                              onMenuClose={() => setSearchSector(billingSector)}
                              defaultInputValue={searchSector}
                              inputValue={searchSector}
                              onInputChange={(text) => setSearchSector(text)}
                            />
                          </div>
                          <div
                            className="input-container"
                            ref={outsideClickRef}
                          >
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeAddress')}
                            </p>
                            <input
                              value={billingAddress}
                              onChange={(e) => {
                                setBillingAddressSugestionPressed(false);
                                setValueAux(e.target.value, false);
                              }}
                              className={setAddressInputStyle(false)}
                              disabled={!ready}
                              placeholder={t('sideBarCheckout.checkout.storeAddressExample')}
                            />
                            {(billingAddress.length > 0
                            && isValidBillingAddress
                            && !billingAddressSugestionPressed) && (
                              <HelpOutline
                                className="help-icon"
                                onClick={() => setAskForBillingAddress(true)}
                              />
                            )}
                            {/* We can use the "status" to decide whether we should display the dropdown or not */}
                            {(status === 'OK' && isBillingAddress) && (
                              <ul>
                                {dataOfficial.map((suggestion, i) => (
                                  <li
                                    key={i.toString()}
                                    onClick={handleSelect(suggestion, false)}
                                    className={(i === 0) ? 'none-border' : ''}
                                  >
                                    <LocationOn className="icon" />
                                    <p>
                                      {suggestion}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          <div className="input-container">
                            <p className="label">
                              {t('sideBarCheckout.checkout.storeLocal')}
                            </p>
                            <input
                              type="text"
                              value={billingLocal}
                              onChange={(e) => setBillingLocal(e.target.value)}
                              placeholder={t('sideBarCheckout.checkout.storeLocalExample')}
                            />
                          </div>
                        </div>
                      )}
                      {(isMount) && (
                        <div className="inputs-container-checkout">
                          <div className="title">
                            <LiveHelpOutlined className="icon" />
                            <p>
                              {t('sideBarCheckout.checkout.deliveryInstructions')}
                            </p>
                          </div>
                          <div className="input-container">
                            <input
                              type="text"
                              value={storeInstructions}
                              onChange={(e) => setStoreInstructions(e.target.value)}
                              placeholder={t('sideBarCheckout.checkout.deliveryInstructionsPlaceholder')}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="checkout-container"
                      id="container"
                    >
                      <div className="info1">
                        <div className="left">
                          <ChevronRight className="icon" />
                          <p className="name">
                            {t('sideBarCheckout.checkout.title')}
                          </p>
                        </div>
                        <div
                          className="right"
                          onClick={() => editAddress()}
                        >
                          <p>
                            {t('sideBarCheckout.checkout.editInformation')}
                          </p>
                          <EditOutlined className="icon" />
                        </div>
                      </div>
                      <div className="inputs-container-checkout">
                        <div className="title">
                          <LocalShippingOutlined className="icon" />
                          <p>
                            {t('sideBarCheckout.checkout.deliveryInformationOld')}
                          </p>
                        </div>
                        <p className="address">
                          {(storeLocal.length > 0)
                            ? `${storeAddress}, ${storeLocal}, ${storeSector}`
                            : `${storeAddress}, ${storeSector}`}
                        </p>
                      </div>
                      <div className="inputs-container-checkout">
                        <div className="title">
                          <FileCopyOutlined className="icon" />
                          <p>
                            {t('sideBarCheckout.checkout.billingInformationOld')}
                          </p>
                        </div>
                        <p className="address">
                          {(billingLocal.length > 0)
                            ? `${billingAddress}, ${billingLocal}, ${billingSector}`
                            : `${billingAddress}, ${billingSector}`}
                        </p>
                      </div>
                      <div className="inputs-container-checkout">
                        <div className="title">
                          <LiveHelpOutlined className="icon" />
                          <p>
                            {t('sideBarCheckout.checkout.deliveryInstructions')}
                          </p>
                        </div>
                        <div className="input-container">
                          <input
                            type="text"
                            value={storeInstructions}
                            onChange={(e) => setStoreInstructions(e.target.value)}
                            placeholder={t('sideBarCheckout.checkout.deliveryInstructionsPlaceholder')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          {(credits.length > 0) && (
            <div className="credits-container">
              <div className="info-container">
                <div className="left">
                  <p className="gray">
                    {t('sideBarCheckout.credits.subtotal')}
                  </p>
                  <p
                    className="blue"
                    onClick={() => setShowModalCredits(true)}
                  >
                    {t('sideBarCheckout.credits.name')}
                    <InfoOutlined className="icon" />
                  </p>
                </div>
                {(loadingCart)
                  ? (
                    <div className="right">
                      <div className="info-loading">
                        <CircularProgress className="spinner" />
                      </div>
                    </div>
                  ) : (
                    <div className="right">
                      <p className="gray">
                        {formatNumber(totalPrice, '$ ', '')}
                      </p>
                      <p className="blue">
                        {formatNumber(totalCredits, '- $ ', '')}
                      </p>
                    </div>
                  )}
              </div>
            </div>
          )}
          <div
            className={(credits.length > 0) ? 'total-container' : 'total-container shadow'}
          >
            {(loadingCart)
              ? (
                <div className="info-loading">
                  <CircularProgress className="spinner" />
                </div>
              ) : (
                <div className="info">
                  <p className="total-tax">
                    {t('sideBarCheckout.totalWithTax')}
                  </p>
                  <p className="total">
                    {formatNumber((credits.length > 0)
                      ? totalPrice - totalCredits : totalPrice, '$ ', '')}
                  </p>
                </div>
              )}
            {(partnerView)
              ? (
                <button
                  type="button"
                  className={(totalCorrect && !loadingCart) ? '' : 'none'}
                  onClick={(totalCorrect && !loadingCart) ? () => {
                    checkStock(state, true)
                      .then(() => {
                        changePartnerView(false);
                      })
                      .catch((err) => {
                        if (err.response.status === 409) {
                          replaceCart(err.response.data.cart);
                          setModalData(err.response.data.productsNotAvailable);
                        }
                      });
                  } : undefined}
                >
                  {t('sideBarCheckout.confirmPurchase')}
                </button>
              ) : (
                <button
                  type="button"
                  className={(minData) ? '' : 'none'}
                  onClick={(minData) ? () => {
                    if (isFirstPurchase) {
                      if (!useDifferentAddress) {
                        setBillingSector(storeSector);
                        setBillingAddress(storeAddress);
                        setBillingLocal(storeLocal);
                        setBillingAddressSugestionPressed(storeAddressSugestionPressed);
                      }
                      setIsFirstPurchase(false);
                    } else {
                      checkStock(state)
                        .then(() => {
                          handleSubmit();
                        })
                        .catch((err) => {
                          if (err.response.status === 409) {
                            replaceCart(err.response.data.cart);
                            setModalData(err.response.data.productsNotAvailable);
                          }
                        });
                    }
                  } : undefined}
                >
                  {t('sideBarCheckout.confirmCheckout')}
                </button>
              )}
          </div>
        </div>
        {(askForStoreAddress || askForBillingAddress) && (
          <div className="ask-address-modal">
            <div className="modal-content">
              <p>
                {(askForStoreAddress)
                  ? t('sideBarCheckout.checkout.askAddress.title1') : t('sideBarCheckout.checkout.askAddress.title2')}
              </p>
              <p>
                {(askForStoreAddress) ? storeAddress : billingAddress}
              </p>
              <div className="buttons">
                <button
                  type="button"
                  onClick={() => acceptSuggestion()}
                >
                  {t('sideBarCheckout.checkout.askAddress.yesButton')}
                </button>
                <button
                  type="button"
                  onClick={
                    () => (
                      (askForStoreAddress)
                        ? setAskForStoreAddress(false)
                        : setAskForBillingAddress(false))
                  }
                >
                  {t('sideBarCheckout.checkout.askAddress.noButton')}
                </button>
              </div>
            </div>
          </div>
        )}
        {(askUpdateInfo) && (
          <div className="ask-address-modal">
            <div className="modal-content">
              <p className="update-info-p">
                {t('sideBarCheckout.checkout.askUpdateInfo.title1')}
              </p>
              <div className="buttons">
                <button
                  type="button"
                  onClick={() => updateInfoResponse(false)}
                >
                  {t('sideBarCheckout.checkout.askAddress.yesButton')}
                </button>
                <button
                  type="button"
                  onClick={() => updateInfoResponse(true)}
                >
                  {t('sideBarCheckout.checkout.askAddress.noButton')}
                </button>
              </div>
            </div>
          </div>
        )}
        {(checkoutError) && (
          <div className="error-modal">
            <div className="modal-content">
              <p>
                {t('sideBarCheckout.checkout.error.title1')}
              </p>
              <p>
                {t('sideBarCheckout.checkout.error.title2')}
              </p>
              <button
                type="button"
                onClick={() => setCheckoutError(false)}
              >
                {t('sideBarCheckout.checkout.error.button')}
              </button>
            </div>
          </div>
        )}
        {(showSectorExplanation) && (
          <div className="error-modal">
            <div className="modal-content">
              <p className="title1">
                {t('sideBarCheckout.checkout.showSectorExplanation.title1')}
              </p>
              <p className="title2">
                {t('sideBarCheckout.checkout.showSectorExplanation.title2')}
              </p>
              <button
                type="button"
                onClick={() => setShowSectorExplanation(false)}
              >
                {t('sideBarCheckout.checkout.error.button')}
              </button>
            </div>
          </div>
        )}
        {(modalVisible) && (
          <StockIssuesModal
            issues={modalData}
            visible={modalVisible}
            setModalVisible={setModalVisible}
          />
        )}
        {(userSaving) && (
          <UserSavings
            setUserSaving={setUserSaving}
            actualPartner={actualPartner}
          />
        )}
        {(showModalCredits) && (
          <CreditsModal
            visible={setShowModalCredits}
            creditsFromCart={credits}
          />
        )}
        <div
          className="out"
          onClick={() => closeSidebar()}
        />
      </div>
    );
  }

  return (
    <div className="sidebar-checkout-view">
      <div className="sidebar-checkout-container">
        <Close
          className="close-icon"
          onClick={() => closeSidebar()}
        />
        <div className="info-store">
          <AccountCircleOutlined className="account-icon" />
          <p className="name">
            {state.store.name}
          </p>
        </div>
        <div className="none-container">
          <SentimentDissatisfied className="icon" />
          <p>
            {t('sideBarCheckout.emptyCart')}
          </p>
        </div>
        <div className="total-container">
          <div className="info">
            <p className="total-tax">
              {t('sideBarCheckout.totalWithTax')}
            </p>
            <p className="total">
              {formatNumber(totalPrice, '$ ', '')}
            </p>
          </div>
          <button
            type="button"
            className="none"
          >
            {t('sideBarCheckout.confirmPurchase')}
          </button>
        </div>
      </div>
      <div
        className="out"
        onClick={() => closeSidebar()}
      />
    </div>
  );
}

export default SidebarCheckout;
