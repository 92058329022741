import React, {
  ReactElement,
} from 'react';

import { useTranslation } from 'react-i18next';

// Styles
import '../styles/StockIssuesModalStyles.scss';

// Icons
import { Close } from '@material-ui/icons';

// Interfaces
import { StockIssuesModalProps } from '../interfaces';

// Helpers
import capitalize from '../helpers/capitalize';

const displayMeasure: { [key: string]: string } = {
  unitario: 'Unidad',
  caja: 'Caja',
  display: 'Display',
  bolsa: 'Bolsa',
};

function StockIssuesModal(props: StockIssuesModalProps): ReactElement {
  // Props
  const {
    issues,
    visible,
    setModalVisible,
  } = props;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  if (visible) {
    return (
      <div className="stock-issues-view">
        <div className="stock-issues-container">
          <Close
            className="close-icon"
            onClick={() => setModalVisible(false)}
          />
          <p className="title">
            {t('stockIssues.title')}
          </p>
          <div className="partners">
            {Object.entries(issues).map(([partner, issuedProducts], i) => (
              <div
                key={i.toString()}
                className="partner-product"
              >
                <p className="partner">
                  {partner.slice(partner.indexOf('. ') + 1).toUpperCase()}
                </p>
                {issuedProducts.map((issuedProduct, j) => (
                  <div
                    key={issuedProduct.product.id}
                    className={j === 0 ? 'product none-border' : 'product'}
                  >
                    <div className="left">
                      <img
                        src={issuedProduct.product.image}
                        alt={`${issuedProduct.product.brand} - ${issuedProduct.product.name}`}
                      />
                      <div className="info">
                        <p className="brand">
                          {issuedProduct.product.brand}
                        </p>
                        <p className="name">
                          {issuedProduct.product.name}
                        </p>
                        {(displayMeasure[issuedProduct.product.displayMeasure])
                          ? (
                            <p className="brand">
                              {(displayMeasure[issuedProduct.product.displayMeasure] === 'Unidad')
                                ? `${displayMeasure[issuedProduct.product.displayMeasure]} 
                                ${issuedProduct.product.weight}${issuedProduct.product.measure}`
                                : `${displayMeasure[issuedProduct.product.displayMeasure]} 
                                ${issuedProduct.product.display} x 
                                ${issuedProduct.product.weight}${issuedProduct.product.measure}`}
                            </p>
                          ) : (
                            <p className="brand">
                              {`${capitalize(issuedProduct.product.displayMeasure)} 
                              ${issuedProduct.product.display} u`}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="right">
                      <p className="quantity">
                        {t('stockIssues.quantity')}
                      </p>
                      <p className="new-quantity">
                        {`${t('stockIssues.new')} ${issuedProduct.inStock}`}
                      </p>
                      <p className="old-quantity">
                        {`${t('stockIssues.old')} ${issuedProduct.outOfStock}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button
            type="button"
            className="accept-button"
            onClick={() => setModalVisible(false)}
          >
            {t('stockIssues.back')}
          </button>
        </div>
      </div>
    );
  } return null;
}

export default StockIssuesModal;
