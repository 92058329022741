import { ProductsByPartner, SearchResponse } from '../interfaces';

const getNumberOfResults = (p : ProductsByPartner) => p.products.length;

const groupByPartner = (results: SearchResponse[]): ProductsByPartner[] => {
  const grouped = results.reduce((groups: ProductsByPartner[], item) => {
    const group = groups.find((i) => i.id === item.partner_id);
    if (group) {
      group.products.push(item);
    } else {
      groups.push({
        id: item.partner_id,
        products: [item],
      });
    }
    return groups;
  }, []);
  return grouped.sort((p1, p2) => getNumberOfResults(p2) - getNumberOfResults(p1));
};

export default groupByPartner;
