import React, {
  ReactElement,
  useContext,
} from 'react';

// Translation
import { useTranslation } from 'react-i18next';

// Icons
import {
  ChevronRight,
  PersonOutlineOutlined,
  ShoppingCartOutlined,
} from '@material-ui/icons';

// Styles
import '../styles/NavbarStyles.scss';

// Assets
import logoWhite from '../assets/images/dealing.png';

// Interfaces
import {
  ContextInterface,
  StateInterface,
  NavbarProps,
} from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

function Navbar(props: NavbarProps): ReactElement {
  const { state, context } : { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  // Context
  const {
    sidebarCheckoutSwitch,
    sidebarSwitch,
  } = context;

  // Props
  const {
    back = false,
    backText = '',
    backFunction = () => {},
  } = props;

  // Translations
  const { t } = useTranslation();

  return (
    <nav className="navbar">
      {(back) ? (
        <button
          type="button"
          className="back-container"
          onClick={backFunction}
        >
          <ChevronRight className="icon" />
          <p>
            {backText}
          </p>
        </button>
      ) : (
        <img
          className="logo"
          src={logoWhite}
          alt="logo"
        />
      )}
      <div className="user">
        <div className="user-info">
          <p className="store-name">
            {state.store.name}
          </p>
        </div>
        <button
          type="button"
          onClick={sidebarSwitch}
        >
          <PersonOutlineOutlined className="icon" />
          <p>
            {`${t('global.hello')} ${state.user.name}`}
          </p>
        </button>
        <div className="cart-bubble">
          <ShoppingCartOutlined
            className="cart-icon"
            onClick={sidebarCheckoutSwitch}
          />
          {(state.itemsCount > 0) && (
            <div className="bubble">
              {state.itemsCount}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
