import React, {
  ReactElement,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import {
  InfoOutlined,
  ChevronRight,
} from '@material-ui/icons';

// Interfaces
import {
  SearchResultProps,
  ProductInterface,
  ContextInterface,
  StateInterface,
} from '../interfaces';

// Styles
import '../styles/SearchResultStyles.scss';

// Context
import GlobalContext from '../store/GlobalContext';

// Component
import Product from './Product';

function SearchResult(props: SearchResultProps): ReactElement {
  const {
    partner,
    informationClick = null,
    products,
    categoryId = null,
    subCategoryName = null,
    subCategoryId = null,
    setShowGetProductError,
  } = props;

  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { getProduct } = context;

  const [specificProduct, setSpecificProduct] = useState<ProductInterface>();
  const [showProduct, setShowProduct] = useState<boolean>(false);

  const history = useHistory();

  const { t } = useTranslation();

  const topProducts = useMemo(() => products
    .filter((product) => product.activated)
    .filter((product) => product.image !== null).slice(0, 15), [products]);

  function clickProduct(id: number) {
    getProduct(state.token, id)
      .then((res) => {
        setSpecificProduct(res.product);
        setShowProduct(true);
      })
      .catch(() => {
        setShowGetProductError(true);
      });
  }

  return (
    <div className="response-container">
      {(!subCategoryName) && (
        <InfoOutlined
          className="info-icon"
          onClick={() => informationClick(partner)}
        />
      )}
      <div className="response">
        {(subCategoryName) ? (
          <p className="name">
            {subCategoryName}
          </p>
        ) : (
          <img
            src={partner.image}
            alt={partner.name}
            onClick={() => informationClick(partner)}
            className="partner-image"
          />
        )}
        <div className="products-container">
          {topProducts.map((product) => (
            <div
              key={product.id}
              className="product"
            >
              <img
                src={product.image}
                alt={product.product}
                onClick={() => clickProduct(product.id)}
                className="product-image"
              />
            </div>
          ))}
        </div>
        <p className="number-found">
          {`${products.length} ${t('searchBar.numberFound')}`}
        </p>
        <p
          className="see-products"
          onClick={
            (subCategoryId && categoryId)
              ? () => history.push(`/catalog/${partner.id}/category/${categoryId}/${subCategoryId}`)
              : () => history.push(`/catalog/${partner.id}`)
          }
        >
          {t('searchBar.seeProducts')}
          <ChevronRight className="icon" />
        </p>
      </div>
      {(showProduct)
        && (
          <Product
            product={specificProduct}
            partner={partner}
            showProductExtern={showProduct}
            setShowProductExtern={setShowProduct}
          />
        )}
    </div>
  );
}

export default SearchResult;
