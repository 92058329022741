import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
} from 'react';

// Import translation module
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

// External pkgs
import Select from 'react-select';

// Icons
import {
  ChevronRight,
  ToggleOnOutlined,
  ToggleOffOutlined,
  Close,
  HelpOutline,
  LocationOn,
} from '@material-ui/icons';

// Interfaces
import {
  ContextInterface,
  StateInterface,
  SectorInterface,
  ModalProps,
  ChangeStoreParams,
} from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

// Styles
import '../styles/StoreProfileModal.scss';

// Components
import Alert from './Alert';
import Confirm from './Confirm';

// Helpers
import phoneRegex from '../helpers/phoneRegex';

function StoreProfileModal(props : ModalProps) : ReactElement {
  // Props
  const { visible } = props;

  // Translation
  const { t } = useTranslation();

  const history = useHistory();

  // Context
  const { state, context } : { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const {
    checkFirstOrder,
    getSectors,
    changeStore,
    replaceCart,
  } = context;

  // Render options
  const [hasData, setHasData] = useState<boolean>(false);
  const [getData, setGetData] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [toggleOn, setToggleOn] = useState<boolean>(true);
  const [menu, setMenu] = useState<boolean>(false);
  const [oldStoreSector, setOldStoreSector] = useState<string>('');
  const [confirm, setConfirm] = useState<boolean>(false);
  const [confirmChangeSector, setConfirmChangeSector] = useState<boolean>(false);
  const [dataOfficial, setDataOfficial] = useState<string[]>([]);

  // Form Data
  const [name, setName] = useState<string>(state.store.name);
  const [phone, setPhone] = useState<string>('');
  const [storeSector, setStoreSector] = useState<SectorInterface>({ id: 0, name: '' });
  const [storeAddress, setStoreAddress] = useState<string>('');
  const [storeAddressSugestionPressed, setStoreAddressSugestionPressed] = useState<boolean>(false);
  const [isStoreAddress, setIsStoreAddress] = useState<boolean>(false);
  const [isValidStoreAddress, setIsValidStoreAddress] = useState<boolean>(false);
  const [askForStoreAddress, setAskForStoreAddress] = useState<boolean>(false);
  const [storeStreet, setStoreStreet] = useState<string>('');
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeLocal, setStoreLocal] = useState<string>('');
  const [billingSector, setBillingSector] = useState<SectorInterface>({ id: 0, name: '' });
  const [billingAddress, setBillingAddress] = useState<string>('');
  const [billingAddressSugestionPressed, setBillingAddressSugestionPressed] = useState<boolean>(false);
  const [isBillingAddress, setIsBillingAddress] = useState<boolean>(false);
  const [isValidBillingAddress, setIsValidBillingAddress] = useState<boolean>(false);
  const [askForBillingAddress, setAskForBillingAddress] = useState<boolean>(false);
  const [billingStreet, setBillingStreet] = useState<string>('');
  const [billingNumber, setBillingNumber] = useState<string>('');
  const [billingLocal, setBillingLocal] = useState<string>('');

  // Error handling
  const [error, setError] = useState<boolean>(false);
  const [successResponse, setSuccessResponse] = useState<boolean>(false);
  const [failedResponse, setFailedResponse] = useState<boolean>(false);
  const [failedLoadData, setFailedLoadData] = useState<boolean>(false);

  // Sectors Data
  const [sectors, setSectors] = useState<SectorInterface[]>([]);

  const [searchSector, setSearchSector] = useState<string>('');
  const [searchBillingSector, setSearchBillingSector] = useState<string>('');

  // Create a new session token.
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      sessionToken,
      componentRestrictions: { country: 'cl' },
    },
  });

  const outsideClickRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleSelect = (description: string, isDelivery: boolean) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    if (isDelivery) {
      setStoreAddress(description);
      setStoreAddressSugestionPressed(true);
    } else {
      setBillingAddress(description);
      setBillingAddressSugestionPressed(true);
    }
    setValue(description, false);
    clearSuggestions();
  };

  function setValueAux(val: string, isDelivery: boolean) {
    if (isDelivery) {
      setIsStoreAddress(true);
      setIsBillingAddress(false);
      setStoreAddress(val);
    } else {
      setIsStoreAddress(false);
      setIsBillingAddress(true);
      setBillingAddress(val);
    }
    setValue(val);
  }

  function setAddressInputStyle(isStore: boolean): string {
    if (isStore) {
      if (storeAddress.length === 0 || (storeAddressSugestionPressed && isValidStoreAddress)) {
        return 'input-container';
      } return 'input-container not-valid';
    }
    if (billingAddress.length === 0 || (billingAddressSugestionPressed && isValidBillingAddress)) {
      return 'input-container';
    } return 'input-container not-valid';
  }

  function acceptSuggestion() {
    if (askForStoreAddress) {
      setStoreAddressSugestionPressed(true);
      setAskForStoreAddress(false);
    } else {
      setBillingAddressSugestionPressed(true);
      setAskForBillingAddress(false);
    }
  }

  // Function that split the street and its number
  function splitAddress(address: string) {
    const selectedAddressSplited = address.split(' ');
    const selectedDeliveryAddressNumber = selectedAddressSplited.pop();
    const selectedDeliveryAddressStreet = selectedAddressSplited.join(' ');
    return [selectedDeliveryAddressStreet, selectedDeliveryAddressNumber];
  }

  function handleSelectedStoreAddress(address: string) {
    const [selectedStoreAddressStreet, selectedStoreAddressNumber] = splitAddress(address);
    // Check if 'selectedStoreAddressNumber' contains only numbers
    // +'<anyString>' transforms to number if possible or NaN if not possible
    if (selectedStoreAddressNumber.length > 0
      && !Number.isNaN(+selectedStoreAddressNumber)
    ) {
      setStoreNumber(selectedStoreAddressNumber);
      setStoreStreet(selectedStoreAddressStreet);
      setIsValidStoreAddress(true);
      if (!toggleOn) {
        setIsValidBillingAddress(true);
      }
    } else {
      // No number in address
      setIsValidStoreAddress(false);
      if (!toggleOn) {
        setIsValidBillingAddress(false);
      }
    }
  }

  function handleSelectedBillingAddress(address: string) {
    const [selectedBillingAddressStreet, selectedBillingAddressNumber] = splitAddress(address);
    // Check if 'selectedBillingAddressNumber' contains only numbers
    // +'<anyString>' transforms to number if possible or NaN if not possible
    if (selectedBillingAddressNumber.length > 0
      && !Number.isNaN(+selectedBillingAddressNumber)
    ) {
      setBillingNumber(selectedBillingAddressNumber);
      setBillingStreet(selectedBillingAddressStreet);
      setIsValidBillingAddress(true);
    } else {
      // No number in address
      setIsValidBillingAddress(false);
    }
  }

  useEffect(() => {
    handleSelectedStoreAddress(storeAddress);
  }, [storeAddress]);

  useEffect(() => {
    handleSelectedBillingAddress(billingAddress);
  }, [billingAddress]);

  useEffect(() => {
    if (!toggleOn) {
      setIsValidBillingAddress(isValidStoreAddress);
    }
  }, [toggleOn]);

  useEffect(() => {
    const aux: string[] = [];
    data.forEach((suggestion) => {
      aux.push(suggestion.structured_formatting.main_text);
    });
    setDataOfficial(Array.from(new Set(aux)));
  }, [data]);

  useEffect(() => {
    setSearchSector(storeSector.name);
  }, [storeSector]);

  useEffect(() => {
    setSearchBillingSector(billingSector.name);
  }, [billingSector]);

  // Hide render
  function closeModal() {
    visible(false);
    setOpenModal(false);
  }

  function checkData() : boolean {
    if (hasData) {
      if (!name
        || !(phone.length === 11)
        || storeSector.id < 1
        || !isValidStoreAddress
        || !storeAddressSugestionPressed) {
        setError(true);
        return false;
      }
      if (toggleOn) {
        if (billingSector.id < 1
          || !isValidBillingAddress
          || !billingAddressSugestionPressed) {
          setError(true);
          return false;
        }
      }
    } else if (!name || storeSector.id < 1) {
      setError(true);
      return false;
    }
    return true;
  }

  // Request and validate data
  function saveChanges() {
    if (!checkData()) {
      return;
    }
    if (oldStoreSector !== storeSector.name && !confirmChangeSector) {
      setConfirm(true);
      return;
    }
    const dataChange: ChangeStoreParams = {
      storeId: state.store.id,
      name,
      phone: `+56 ${phone}`,
      storeStreet,
      storeNumber,
      storeLocal,
      billingStreet: (toggleOn) ? billingStreet : storeStreet,
      billingNumber: (toggleOn) ? billingNumber : storeNumber,
      billingLocal: (toggleOn) ? billingLocal : storeLocal,
      storeSector,
      billingSector: (toggleOn) ? billingSector : storeSector,
    };
    changeStore(state, dataChange)
      .then(() => {
        setSuccessResponse(true);
      })
      .catch(() => {
        setFailedResponse(true);
      });
  }

  // Load address information
  useEffect(() => {
    checkFirstOrder(state)
      .then((res) => {
        const { response, details } = res;
        setHasData(response);
        if (response) {
          if (details.phone.substr(0, 4) === `${t('sideBarCheckout.checkout.phoneCode')} `) {
            setPhone(details.phone.substr(4, 15));
          } else {
            setPhone(details.phone);
          }
          const storeInfo = details.storeAddress.split(', ');
          setStoreAddress(storeInfo[0]);
          setStoreAddressSugestionPressed(true);
          if (storeInfo.length > 2) {
            setStoreLocal(storeInfo[1]);
            setStoreSector({ id: 0, name: storeInfo[2] });
            setOldStoreSector(storeInfo[2]);
          } else {
            setStoreSector({ id: 0, name: storeInfo[1] });
            setOldStoreSector(storeInfo[1]);
          }
          const billingInfo = details.billingAddress.split(', ');
          setBillingAddress(billingInfo[0]);
          setBillingAddressSugestionPressed(true);
          if (billingInfo.length > 2) {
            setBillingLocal(billingInfo[1]);
            setBillingSector({ id: 0, name: billingInfo[2] });
          } else {
            setBillingSector({ id: 0, name: billingInfo[1] });
          }
          if (details.storeAddress === details.billingAddress) {
            setToggleOn(false);
          }
        } else {
          setOldStoreSector(details.storeSector);
          setStoreSector({ id: 0, name: details.storeSector });
        }
        setGetData(true);
      })
      .catch(() => {
        setFailedLoadData(true);
      });
  }, []);

  // Load Sectors Data
  useEffect(() => {
    getSectors()
      .then((res) => {
        setSectors(res);
        res.forEach((sector) => {
          if (sector.name === storeSector.name) setStoreSector(sector);
          if (hasData && sector.name === billingSector.name) setBillingSector(sector);
        });
      })
      .catch(() => {
        setFailedLoadData(true);
      });
  }, [getData]);

  useEffect(() => {
    if (openModal) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          closeModal();
        }
      };
    }
  }, [openModal]);

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (state.isLoading) {
        return;
      }
      if (event.code === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  function closeSectorAlert(value : boolean): void {
    setFailedLoadData(value);
    closeModal();
  }

  function scrollToBottom(): void {
    const form = document.getElementById('form');
    form.scrollTo(0, form.scrollHeight);
  }

  useEffect(() => {
    if (menu) {
      scrollToBottom();
    }
  }, [menu]);

  useEffect(() => {
    if (confirmChangeSector) {
      saveChanges();
    }
  }, [confirmChangeSector]);

  function closeConfirm(value: boolean): void {
    setConfirmChangeSector(value);
    setConfirm(false);
  }

  function closeSuccessAlert(value: boolean): void {
    setSuccessResponse(value);
    if (oldStoreSector !== storeSector.name) {
      replaceCart({});
      history.push('/partners');
    }
  }

  if (!openModal) {
    return null;
  }

  if (confirm) {
    return (
      <Confirm
        buttonPositiveText={t('storeProfileModal.alert.button')}
        buttonNegativeText={t('storeProfileModal.alert.cancel')}
        buttonFunction={closeConfirm}
      >
        <p>
          {t('storeProfileModal.alert.confirm.line1')}
        </p>
        <p>
          {t('storeProfileModal.alert.confirm.line2')}
        </p>
        <p>
          &nbsp;
        </p>
        <p className="bold">
          {t('storeProfileModal.alert.confirm.line3')}
        </p>
      </Confirm>
    );
  }

  // Alert for error in getSectors
  if (failedLoadData) {
    return (
      <Alert
        buttonText={t('storeProfileModal.alert.button')}
        buttonFunction={closeSectorAlert}
      >
        <p>
          {t('storeProfileModal.alert.failedLoadData.line1')}
        </p>
        <p>
          &nbsp;
        </p>
        <p className="bold">
          {t('storeProfileModal.alert.failedLoadData.line2')}
        </p>
      </Alert>
    );
  }

  // Alert for input error
  if (error) {
    return (
      <Alert
        buttonText={t('storeProfileModal.alert.button')}
        buttonFunction={setError}
      >
        <p>
          {t('storeProfileModal.alert.missingData.line1')}
        </p>
        <p>
          {t('storeProfileModal.alert.missingData.line2')}
        </p>
        <p>
          &nbsp;
        </p>
        <p className="bold">
          {t('storeProfileModal.alert.missingData.line3')}
        </p>
      </Alert>
    );
  }

  // Alert for error in endpoint request
  if (failedResponse) {
    return (
      <Alert
        buttonText={t('storeProfileModal.alert.button')}
        buttonFunction={setFailedResponse}
      >
        <p>
          {t('storeProfileModal.alert.failed.line1')}
        </p>
        <p>
          {t('storeProfileModal.alert.failed.line2')}
        </p>
        <p className="bold">
          {t('storeProfileModal.alert.failed.line3')}
        </p>
      </Alert>
    );
  }

  // Alert for success endpoint request
  if (successResponse) {
    return (
      <Alert
        buttonText={t('storeProfileModal.alert.button')}
        buttonFunction={closeSuccessAlert}
      >
        <p className="bold">
          {t('storeProfileModal.alert.success.line1')}
        </p>
        <p className="bold">
          {t('storeProfileModal.alert.success.line2')}
        </p>
      </Alert>
    );
  }

  return (
    <div
      id="myModal"
      className="modal-store"
    >
      <div className="content">
        <Close
          role="button"
          id="modal-close"
          onClick={() => closeModal()}
        />
        <div className="main">
          <p className="title">
            {t('storeProfileModal.title').toUpperCase()}
          </p>
          <div
            id="form"
            className={menu ? 'form-container menu' : 'form-container'}
          >
            <div className="section">
              <div className="title-section">
                <ChevronRight className="icon" />
                <p className="text">
                  {t('storeProfileModal.storeData.title').toUpperCase()}
                </p>
              </div>
              <p className="label">
                {t('storeProfileModal.storeData.name')}
              </p>
              <div className="input-container">
                <input
                  className="field"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <p className="label">
                {t('storeProfileModal.storeData.rut')}
              </p>
              <div className="input-container">
                <input
                  value={state.store.rut}
                  className="field-off"
                  type="text"
                  disabled
                />
              </div>
              <p className="label">
                {t('storeProfileModal.storeData.businessName')}
              </p>
              <div className="input-container">
                <input
                  className="field-off"
                  type="text"
                  disabled
                  value={state.store.businessName}
                />
              </div>
              <p className="label">
                {t('storeProfileModal.storeData.commercialBusiness')}
              </p>
              <div className="input-container">
                <input
                  value={state.store.order}
                  className="field-off"
                  type="text"
                  disabled
                />
              </div>
              {(hasData) ? (
                <p className="label">
                  {t('storeProfileModal.storeData.phone')}
                </p>
              ) : (
                <p className="label">
                  {t('storeProfileModal.billAddress.city')}
                </p>
              )}
              {(hasData) ? (
                <div
                  className={(phone.length === 0 || phone.length === 11)
                    ? 'input-container' : 'input-container not-valid'}
                >
                  <input
                    className="field phone-field"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(phoneRegex(e.target.value))}
                  />
                  <div className="phone-label-container">
                    <p className="phone-label">{t('sideBarCheckout.checkout.phoneCode')}</p>
                  </div>
                </div>
              ) : (
                <Select
                  isSearchable
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  value={storeSector.id < 1 ? null : { value: storeSector.id, label: storeSector.name }}
                  options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                  className="select-container"
                  classNamePrefix="select"
                  onChange={(e) => { if (e) { setStoreSector({ id: e.value, name: e.label }); } }}
                  noOptionsMessage={() => t('storeProfileModal.select.noCoincidence')}
                  placeholder={t('storeProfileModal.select.placeholder')}
                  maxMenuHeight={250}
                  onMenuOpen={() => setMenu(true)}
                  onFocus={() => setSearchSector(storeSector.name)}
                  onMenuClose={() => {
                    setSearchSector(storeSector.name);
                    setMenu(false);
                  }}
                  defaultInputValue={searchSector}
                  inputValue={searchSector}
                  onInputChange={(text) => setSearchSector(text)}
                />
              )}
            </div>
            {(hasData) && (
              <div className="section">
                <div className="title-section">
                  <ChevronRight className="icon" />
                  <p className="text">
                    {t('storeProfileModal.storeAddress.title').toUpperCase()}
                  </p>
                </div>
                <p className="label">
                  {t('storeProfileModal.storeAddress.city')}
                </p>
                <Select
                  isSearchable
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  value={storeSector.id < 1 ? null : { value: storeSector.id, label: storeSector.name }}
                  options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                  className="select-container"
                  classNamePrefix="select"
                  onChange={(e) => { if (e) { setStoreSector({ id: e.value, name: e.label }); } }}
                  noOptionsMessage={() => t('storeProfileModal.select.noCoincidence')}
                  placeholder={t('storeProfileModal.select.placeholder')}
                  onFocus={() => setSearchSector(storeSector.name)}
                  onMenuClose={() => setSearchSector(storeSector.name)}
                  defaultInputValue={searchSector}
                  inputValue={searchSector}
                  onInputChange={(text) => setSearchSector(text)}
                />
                <p className="label">
                  {t('storeProfileModal.storeAddress.address')}
                </p>
                <div
                  className={setAddressInputStyle(true)}
                  ref={outsideClickRef}
                >
                  <input
                    value={storeAddress}
                    onChange={(e) => {
                      setStoreAddressSugestionPressed(false);
                      setValueAux(e.target.value, true);
                    }}
                    className="field"
                    disabled={!ready}
                    placeholder={t('sideBarCheckout.checkout.storeAddressExample')}
                  />
                  {(storeAddress.length > 0
                  && isValidStoreAddress
                  && !storeAddressSugestionPressed) && (
                    <HelpOutline
                      className="help-icon"
                      onClick={() => setAskForStoreAddress(true)}
                    />
                  )}
                  {/* We can use the "status" to decide whether we should display the dropdown or not */}
                  {(status === 'OK' && isStoreAddress) && (
                    <ul>
                      {dataOfficial.map((suggestion, i) => (
                        <li
                          key={i.toString()}
                          onClick={handleSelect(suggestion, true)}
                          className={(i === 0) ? 'none-border' : ''}
                        >
                          <LocationOn className="icon" />
                          <p>
                            {suggestion}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <p className="label">
                  {t('storeProfileModal.storeAddress.local')}
                </p>
                <div className="input-container">
                  <input
                    className="field"
                    type="text"
                    value={storeLocal}
                    onChange={(e) => setStoreLocal(e.target.value)}
                  />
                </div>
              </div>
            )}
            {(hasData) && (
              <div className="toggle">
                {toggleOn
                  ? (
                    <ToggleOnOutlined
                      className="icon"
                      onClick={() => setToggleOn(false)}
                    />
                  )
                  : (
                    <ToggleOffOutlined
                      className="icon off"
                      onClick={() => setToggleOn(true)}
                    />
                  )}
                <p className={toggleOn ? 'text' : 'text off'}>
                  {t('storeProfileModal.differentAddress')}
                </p>
              </div>
            )}
            {(hasData && toggleOn) && (
              <div className="section">
                <div className="title-section">
                  <ChevronRight className="icon" />
                  <p className="text">
                    {t('storeProfileModal.billAddress.title').toUpperCase()}
                  </p>
                </div>
                <p className="label">
                  {t('storeProfileModal.billAddress.city')}
                </p>
                <Select
                  isSearchable
                  isDisabled={!toggleOn}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  value={billingSector.id < 1 ? null : { value: billingSector.id, label: billingSector.name }}
                  options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                  className={toggleOn ? 'select-container' : 'select-off-container'}
                  classNamePrefix={toggleOn ? 'select' : 'select-off'}
                  onChange={(e) => { if (e) { setBillingSector({ id: e.value, name: e.label }); } }}
                  noOptionsMessage={() => t('storeProfileModal.select.noCoincidence')}
                  placeholder={t('storeProfileModal.select.placeholder')}
                  onFocus={() => setSearchBillingSector(billingSector.name)}
                  onMenuClose={() => setSearchBillingSector(billingSector.name)}
                  defaultInputValue={searchBillingSector}
                  inputValue={searchBillingSector}
                  onInputChange={(text) => setSearchBillingSector(text)}
                />
                <p className="label">
                  {t('storeProfileModal.storeAddress.address')}
                </p>
                <div
                  className={setAddressInputStyle(false)}
                  ref={outsideClickRef}
                >
                  <input
                    value={billingAddress}
                    onChange={(e) => {
                      setBillingAddressSugestionPressed(false);
                      setValueAux(e.target.value, false);
                    }}
                    className="field"
                    disabled={!ready}
                    placeholder={t('sideBarCheckout.checkout.storeAddressExample')}
                  />
                  {(billingAddress.length > 0
                  && isValidBillingAddress
                  && !billingAddressSugestionPressed) && (
                    <HelpOutline
                      className="help-icon"
                      onClick={() => setAskForBillingAddress(true)}
                    />
                  )}
                  {/* We can use the "status" to decide whether we should display the dropdown or not */}
                  {(status === 'OK' && isBillingAddress) && (
                    <ul>
                      {dataOfficial.map((suggestion, i) => (
                        <li
                          key={i.toString()}
                          onClick={handleSelect(suggestion, false)}
                          className={(i === 0) ? 'none-border' : ''}
                        >
                          <LocationOn className="icon" />
                          <p>
                            {suggestion}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <p className="label">
                  {t('storeProfileModal.billAddress.local')}
                </p>
                <div className="input-container">
                  <input
                    className={toggleOn ? 'field' : 'field-off'}
                    type="text"
                    disabled={!toggleOn}
                    value={billingLocal}
                    onChange={(e) => setBillingLocal(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <button
            type="button"
            className="save-button"
            onClick={saveChanges}
          >
            <p className="text">
              {t('storeProfileModal.saveButton').toUpperCase()}
            </p>
          </button>
        </div>
      </div>
      {(askForStoreAddress || askForBillingAddress) && (
        <div className="ask-address-modal">
          <div className="modal-content">
            <p>
              {(askForStoreAddress)
                ? t('sideBarCheckout.checkout.askAddress.title1') : t('sideBarCheckout.checkout.askAddress.title2')}
            </p>
            <p>
              {(askForStoreAddress) ? storeAddress : billingAddress}
            </p>
            <div className="buttons">
              <button
                type="button"
                onClick={() => acceptSuggestion()}
              >
                {t('sideBarCheckout.checkout.askAddress.yesButton')}
              </button>
              <button
                type="button"
                onClick={
                  () => (
                    (askForStoreAddress)
                      ? setAskForStoreAddress(false)
                      : setAskForBillingAddress(false))
                }
              >
                {t('sideBarCheckout.checkout.askAddress.noButton')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StoreProfileModal;
