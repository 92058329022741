import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

// Icons
import {
  Close,
} from '@material-ui/icons';

// Style
import '../styles/UserSavingsStyles.scss';

// Interfaces
import {
  UserSavingsProps,
  ActivatedPromosInterface,
} from '../interfaces';

// Helpers
import formatNumber from '../helpers/formatNumber';

function UserSavings(props: UserSavingsProps) : ReactElement {
  const {
    setUserSaving,
    actualPartner,
  } = props;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const [promotions, setPromotions] = useState<ActivatedPromosInterface[]>([]);

  const partnerName = (actualPartner.name.split('.')[1])
    ? actualPartner.name.split('.')[1].substring(1).toUpperCase()
    : actualPartner.name.toUpperCase();

  useEffect(() => {
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = (event: MouseEvent) => {
      const modal = document.getElementById('modal');
      if (event.target === modal) {
        setUserSaving(false);
      }
    };
  });

  // Group promotions
  useEffect(() => {
    const newPromos: {[key: number]: ActivatedPromosInterface} = {};
    Object.values(actualPartner.cart).forEach((product) => {
      if (product.activatedPromotions?.length > 0) {
        product.activatedPromotions.forEach((promo) => {
          if (newPromos[promo.promotionId]) {
            newPromos[promo.promotionId].discount += promo.discount;
          } else {
            newPromos[promo.promotionId] = promo;
          }
        });
      }
    });
    setPromotions(Object.values(newPromos));
  }, []);

  return (
    <div
      id="modal"
      className="user-saving-modal"
    >
      <div className="modal-content">
        <Close
          className="close-icon"
          onClick={() => setUserSaving(false)}
        />
        <p className="title">
          {t('sideBarCheckout.userSavings.userSavings')}
        </p>
        <div className="partner">
          <p className="name">
            {partnerName}
          </p>
          <div className="information">
            <div
              className="promotions-container"
            >
              {promotions.map((promotion, i) => (
                <div
                  key={promotion.promotionId}
                  className={(i === 0) ? 'promotions none-border' : 'promotions'}
                >
                  <div className="info1">
                    <div className="promotion">
                      {promotion.shortDescription}
                    </div>
                    <p className="save">
                      {t('sideBarCheckout.userSavings.save')}
                    </p>
                  </div>
                  <div className="info2">
                    <p className="long-promotion">
                      {promotion.longDescription}
                    </p>
                    <p className="mount">
                      {formatNumber(promotion.discount, '$ ', '')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <button
          type="button"
          className="accept-button"
          onClick={() => setUserSaving(false)}
        >
          {t('sideBarCheckout.userSavings.accept')}
        </button>
      </div>
    </div>
  );
}

export default UserSavings;
