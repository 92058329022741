import React, {
  ReactElement,
} from 'react';

// Styles
import '../styles/AlertStyles.scss';

// Interfaces
import { AlertInterface } from '../interfaces';

function Alert(props: AlertInterface): ReactElement {
  const {
    children,
    buttonText,
    buttonFunction,
  } = props;

  return (
    <div className="alert-screen">
      <div className="alert-container">
        <div className="content">
          {children}
        </div>
        <button
          className="button"
          type="button"
          onClick={() => buttonFunction(false)}
        >
          <p className="text">
            {buttonText.toUpperCase()}
          </p>
        </button>
      </div>
    </div>
  );
}

export default Alert;
