import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
} from 'react';

// Translation
import { useTranslation } from 'react-i18next';

// History
import { useHistory } from 'react-router-dom';

// Icons
import {
  PersonOutline,
  ShoppingCartOutlined,
  StoreOutlined,
  SwapHoriz,
  PhoneOutlined,
  VerifiedUserOutlined,
  CancelOutlined,
  Close,
  LocalActivityOutlined,
} from '@material-ui/icons';

// Version
import appJson from '../../package.json';

// Interfaces
import {
  ContextInterface,
  StateInterface,
} from '../interfaces';

// Style
import '../styles/SidebarStyles.scss';

// Assets
import GlobalContext from '../store/GlobalContext';

// Components
import ContactUsModal from './ContactUsModal';
import UserProfileModal from './UserProfileModal';
import StoreProfileModal from './StoreProfileModal';
import Alert from './Alert';
import CreditsModal from './CreditsModal';

function Sidebar() : ReactElement {
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const {
    sidebarSwitch,
    signOut,
    getPurchaseHistory,
  } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  // Location
  const history = useHistory();

  // Component state
  const [openContactUs, setOpenContactUs] = useState<boolean>(false);
  const [openUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [openStoreProfile, setOpenStoreProfile] = useState<boolean>(false);
  const [openCredits, setOpenCredits] = useState<boolean>(false);
  const [emptyPurchases, setEmptyPurchases] = useState<boolean>(false);
  const [failedLoadHistory, setFailedLoadHistory] = useState<boolean>(false);

  // Disable/enable scroll of view
  useEffect(() => {
    if (state.sidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [state.sidebarOpen]);

  // Verify if user has past purchases
  function checkHistory() {
    if (history.location.pathname === '/purchases') {
      return;
    }
    getPurchaseHistory(state)
      .then((purchases) => {
        if (purchases.length === 0) {
          setEmptyPurchases(true);
        } else {
          history.push('/purchases', { parent: history.location.pathname });
          sidebarSwitch();
        }
      })
      .catch(() => {
        setFailedLoadHistory(true);
      });
  }

  // Close the sidebar when press escape
  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (state.isLoading) {
        return;
      }
      if (openContactUs || openUserProfile || openStoreProfile) {
        return;
      }
      if (event.code === 'Escape' && state.sidebarOpen) {
        sidebarSwitch();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [state.sidebarOpen]);

  // Render of modals
  if (openContactUs) {
    return (<ContactUsModal visible={setOpenContactUs} />);
  }

  if (openUserProfile) {
    return (<UserProfileModal visible={setOpenUserProfile} />);
  }

  if (openStoreProfile) {
    return (<StoreProfileModal visible={setOpenStoreProfile} />);
  }

  if (openCredits) {
    return (<CreditsModal visible={setOpenCredits} />);
  }

  // Render of Alert
  if (emptyPurchases) {
    return (
      <Alert
        buttonText={t('sidebar.emptyPurchases.button')}
        buttonFunction={setEmptyPurchases}
      >
        <p className="bold">
          {t('sidebar.emptyPurchases.line1')}
        </p>
        <p>
          {t('sidebar.emptyPurchases.line2')}
        </p>
        <p>
          {t('sidebar.emptyPurchases.line3')}
        </p>
      </Alert>
    );
  }

  if (failedLoadHistory) {
    return (
      <Alert
        buttonText={t('sidebar.failedHistory.button')}
        buttonFunction={setFailedLoadHistory}
      >
        <p>
          {t('sidebar.failedHistory.line1')}
        </p>
        <p>
          {t('sidebar.failedHistory.line2')}
        </p>
        <p>
          &nbsp;
        </p>
        <p className="bold">
          {t('sidebar.failedHistory.line3')}
        </p>
      </Alert>
    );
  }

  // Render when sidebar is close
  if (!state.sidebarOpen) {
    return null;
  }

  return (
    <div className="sidebar-view">
      <div className="sidebar-container">
        <Close
          className="close-icon"
          onClick={sidebarSwitch}
        />
        <div
          className="option"
          onClick={() => setOpenUserProfile(true)}
        >
          <PersonOutline className="icon" />
          <p className="text">
            {`${t('sidebar.options.userProfile')}`}
          </p>
        </div>
        <div
          className="option"
          onClick={() => setOpenStoreProfile(true)}
        >
          <StoreOutlined className="icon" />
          <p className="text">
            {`${t('sidebar.options.storeProfile')}`}
          </p>
        </div>
        <div
          className="option"
          onClick={checkHistory}
        >
          <ShoppingCartOutlined className="icon" />
          <p className="text">
            {`${t('sidebar.options.ordersHistory')}`}
          </p>
        </div>
        <div
          className="option"
          onClick={() => setOpenCredits(true)}
        >
          <LocalActivityOutlined className="icon" />
          <p className="text">
            {`${t('sidebar.options.credits')}`}
          </p>
        </div>
        <div className="option hide">
          <SwapHoriz className="icon" />
          <p className="text">
            {`${t('sidebar.options.changeIndustry')}`}
          </p>
        </div>
        <div className="separator-1" />
        <div
          className="option"
          onClick={() => setOpenContactUs(true)}
        >
          <PhoneOutlined className="icon" />
          <p className="text">
            {`${t('sidebar.options.contactUs')}`}
          </p>
        </div>
        <div className="option">
          <VerifiedUserOutlined className="icon" />
          <a
            className="link"
            href="https://dealing-legal.s3.amazonaws.com/politicas.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text">
              {`${t('sidebar.options.privacyPolicy')}`}
            </p>
          </a>
        </div>
        <div className="separator-2" />
        <div
          className="option"
          onClick={() => signOut()}
        >
          <CancelOutlined className="icon" />
          <p className="text">
            {`${t('sidebar.options.logout')}`}
          </p>
        </div>
        <p className="version">
          {`${t('sidebar.version')} ${appJson.version}`}
        </p>
      </div>
      <div
        className="out"
        onClick={sidebarSwitch}
      />
    </div>
  );
}

export default Sidebar;
