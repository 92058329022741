import React, {
  ReactElement,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import '../styles/LandingStyles.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ChevronRight } from '@material-ui/icons';

// Assets
// Registered logo
import logoWhite from '../assets/images/dealing.png';
import getAppStore from '../assets/images/get-app-store.png';
import getPlayStore from '../assets/images/get-play-store.png';
import value from '../assets/images/landing/value.jpg';
import valueMobile from '../assets/images/landing/value-mobile.jpg';
import store from '../assets/images/landing/store.jpg';
import partner from '../assets/images/landing/partner.jpg';
import cellPhone from '../assets/images/landing/cell-phone.png';
import wingsFooterWeb from '../assets/images/landing/wings-footer-web.png';
import wingsFooterMobile from '../assets/images/landing/wings-footer-mobile.png';

// Context
import GlobalContext from '../store/GlobalContext';

// Interfaces
import { StateInterface } from '../interfaces';

// Components
import WingsBackground from '../components/WingsBackground';
import ContactUsModal from '../components/ContactUsModal';

function LandingView(): ReactElement {
  // Context
  const { state }: { state: StateInterface } = useContext(GlobalContext);

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  // Bool for open/close modal block + modal variables
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className={(state.isLoading) ? 'App no-scroll' : 'App'}>
      <nav className="navbar-landing">
        <img
          className="logo"
          src={logoWhite}
          alt="Logo"
        />
        <div className="links">
          <p
            onClick={() => history.push('/signup')}
            className="signup-link"
          >
            {t('landingView.navbar.signup')}
          </p>
          <button
            className="login-button"
            type="button"
            onClick={() => history.push('/login')}
          >
            {t('landingView.navbar.login')}
          </button>
        </div>
      </nav>
      <div id="landing-container">
        <WingsBackground />
        <div className="text-container">
          <img
            className="logo-mobile"
            src={logoWhite}
            alt="Logo"
          />
          <h1 className="title">
            {t('landingView.landingContainer.title')}
          </h1>
          <p className="description">
            {t('landingView.landingContainer.description')}
          </p>
        </div>
        <div className="stores-container">
          <a
            href="https://play.google.com/store/apps/details?id=cl.dealing.dealingapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="store-button"
              src={getPlayStore}
              alt="Botón descarga aplicación en Google Play Store"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/dealing/id1531800536"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="store-button"
              src={getAppStore}
              alt="Botón descarga aplicación en App Store"
            />
          </a>
        </div>
        <div className="links">
          <p onClick={() => { window.location.href = 'https://links.dealingapp.com/download/'; }}>
            {t('landingView.onlyMobile.signup')}
          </p>
          <button
            className="login-button"
            type="button"
            onClick={() => { window.location.href = 'https://links.dealingapp.com/download/'; }}
          >
            {t('landingView.onlyMobile.login')}
          </button>
        </div>
        <div className="scroll-down">
          <p>
            {t('landingView.scrollDown')}
          </p>
          <a href="#phones">
            <ChevronRight className="icon" />
          </a>
        </div>
      </div>
      <div id="phones">
        <div className="scroll-fix" />
        <p className="title">
          {t('landingView.phones')}
        </p>
        <p className="title-mobile">
          {t('landingView.onlyMobile.phones.title1')}
        </p>
        <p className="title-mobile">
          {t('landingView.onlyMobile.phones.title2')}
        </p>
        <img
          id="value-image-web"
          src={value}
          alt="Vista aplicación desde aplicación en teléfono móvil"
        />
        <img
          id="value-image-mobile"
          src={valueMobile}
          alt="Vista aplicación desde aplicación en teléfono móvil"
        />
      </div>
      <div className="store-container">
        <img
          id="store-image-web"
          src={store}
          alt="Imagen productos en almacen"
        />
        <div className="message-container">
          <p id="title">
            {t('landingView.store.title')}
          </p>
          <p id="message">
            {t('landingView.store.message1')}
          </p>
          <p id="message">
            {t('landingView.store.message2')}
          </p>
          <p id="message-web">
            {t('landingView.store.message3')}
          </p>
          <p id="message-mobile">
            {t('landingView.onlyMobile.storeMessage3')}
          </p>
        </div>
      </div>
      <div id="text-web">
        <div className="bubble">
          <div className="inner-contact-container">
            <p id="p-text1">
              &rdquo;
              {t('landingView.text.message1')}
            </p>
            <p id="p-text2">
              {t('landingView.text.message2')}
              &rdquo;
            </p>
          </div>
        </div>
      </div>
      <div id="text-mobile">
        <div className="bubble">
          <div className="inner-contact-container">
            <p id="p-text1">
              &rdquo;
              {t('landingView.onlyMobile.text.message1')}
            </p>
            <p id="p-text2">
              {t('landingView.onlyMobile.text.message2')}
              &rdquo;
            </p>
          </div>
        </div>
      </div>
      <div className="partners-container-landing">
        <img
          id="partners-image-web"
          src={partner}
          alt="iPad información de aplicación"
        />
        <div className="message-container">
          <p id="title">
            {t('landingView.partners.title')}
          </p>
          <p id="message">
            {t('landingView.partners.message1')}
          </p>
          <p id="message">
            {t('landingView.partners.message2')}
          </p>
          <p id="message">
            {t('landingView.partners.message3')}
          </p>
        </div>
      </div>
      <div id="contact-us">
        <div className="mobile-bubble" />
        <div className="bubble">
          <div className="inner-contact-container">
            <p>
              {t('landingView.contactUs.title')}
            </p>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
            >
              <p>
                {t('landingView.contactUs.button')}
              </p>
            </button>
          </div>
        </div>
        <img
          className="cell-phone"
          src={cellPhone}
          alt="Vista logo desde aplicación en celular"
        />
      </div>
      <div className="footer">
        <img
          className="wings-footer-web"
          src={wingsFooterWeb}
          alt="Alas superiores"
        />
        <img
          className="wings-footer-mobile"
          src={wingsFooterMobile}
          alt="Alas superiores"
        />
        <a
          className="go-top"
          href="#landing-container"
        >
          <ChevronRight className="icon" />
        </a>
        <div className="footer-info-container">
          <img
            className="footer-logo"
            src={logoWhite}
            alt="Logo"
          />
          <a
            className="footer-link"
            href="#contacto"
          >
            <p className="footer-info">
              {t('landingView.footer.info1')}
            </p>
          </a>
          <a
            className="footer-link"
            href="#contacto"
          >
            <p className="footer-info">
              {t('landingView.footer.info2')}
            </p>
          </a>
          <a
            className="footer-link"
            href="https://dealing-legal.s3.amazonaws.com/terminosycondiciones.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <p className="footer-info">
              {t('landingView.footer.info3')}
            </p>
          </a>
          <a
            className="footer-link"
            href="https://dealing-legal.s3.amazonaws.com/politicas.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <p className="footer-info">
              {t('landingView.footer.info4')}
            </p>
          </a>
          <div className="footer-icons-container">
            <a
              href="https://www.facebook.com/dealingapp"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className="footer-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/dealingapp/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="footer-icon"
              />
            </a>
          </div>
        </div>
        <div className="footer-buttons-container">
          <a
            href="https://play.google.com/store/apps/details?id=cl.dealing.dealingapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-buttons"
              src={getPlayStore}
              alt="Botón descarga aplicación en Google Play Store"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/dealing/id1531800536"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-buttons"
              src={getAppStore}
              alt="Botón descarga aplicación en App Store"
            />
          </a>
        </div>
      </div>
      {(openModal) && (
        <ContactUsModal visible={setOpenModal} />
      )}
    </div>
  );
}

export default LandingView;
