import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry(): void {
  Sentry.init({
    dsn: 'https://6d85101437414c06989f30fd1c32d0c9@o441687.ingest.sentry.io/5511732',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    debug: process.env.REACT_APP_ENV_NAME !== 'production',
    environment: process.env.REACT_APP_ENV_NAME,
  });
}

export function setSentryTags(user: string, store: string): void {
  Sentry.setTag('email', user);
  Sentry.setTag('store', store);
}

export function fireSentryEvent(exception: Error): void {
  Sentry.captureException(exception);
}

export function setContextTag(route: string, message: string): void {
  Sentry.setTag('route', route);
  Sentry.setTag('message', message);
}

export default initSentry;
