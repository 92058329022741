import React, {
  ReactElement,
} from 'react';

// Styles
import '../styles/ConfirmStyles.scss';

// Interfaces
import { ConfirmInterface } from '../interfaces';

function Confirm(props: ConfirmInterface): ReactElement {
  const {
    children,
    buttonPositiveText,
    buttonNegativeText,
    buttonFunction,
  } = props;

  return (
    <div className="confirm-screen">
      <div className="confirm-container">
        <div className="content">
          {children}
        </div>
        <div className="options">
          <button
            className="button"
            type="button"
            onClick={() => buttonFunction(true)}
          >
            <p className="text">
              {buttonPositiveText.toUpperCase()}
            </p>
          </button>
          <button
            className="button"
            type="button"
            onClick={() => buttonFunction(false)}
          >
            <p className="text">
              {buttonNegativeText.toUpperCase()}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Confirm;
