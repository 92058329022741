const SENSIBILITY = 1.3;

export function getAllSubstrings(str: string): string[] {
  const result: string[] = [];

  for (let i = 0; i < str.length; i += 1) {
    for (let j = i + 1; j < str.length + 1; j += 1) {
      result.push(str.slice(i, j));
    }
  }
  return result;
}

export function normalizeString(str: string): string {
  // Input: Crème Brulée
  // Output: creme brulee
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function compareStrings(
  storeSector: string,
  storeSectorSubStrings: string[],
  str1: string,
  str2: string,
): number {
  // Lowercase and remove accentuated characters from strings
  const normalizedStr1 = normalizeString(str1);
  const normalizedStr2 = normalizeString(str2);

  // If one of the strings includes the searched string, prioritaze it
  if (normalizedStr1.includes(storeSector.toLocaleLowerCase())) {
    if (!normalizedStr2.includes(storeSector.toLocaleLowerCase())) {
      return -1;
    }
  } else if (normalizedStr2.includes(storeSector.toLocaleLowerCase())) {
    return 1;
  }

  let total = 0;
  let count1 = 0;
  let count2 = 0;

  storeSectorSubStrings.forEach((el) => {
    if (normalizedStr1.includes(el)) {
      count1 += SENSIBILITY ** el.length;
    }
    if (normalizedStr2.includes(el)) {
      count2 += SENSIBILITY ** el.length;
    }
    total += SENSIBILITY ** el.length;
  });

  return (count2 / total) - (count1 / total);
}

export function calculateRating(searchSubstrings: string[], prodName: string): number {
  // Lowercase and remove accentuated characters from strings
  const normalizedProd = normalizeString(prodName);

  let total = 0;
  let count = 0;

  searchSubstrings.forEach((el) => {
    if (normalizedProd.includes(el)) {
      count += SENSIBILITY ** el.length;
    }
    total += SENSIBILITY ** el.length;
  });

  return count / total;
}
