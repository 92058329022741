import React, { ReactElement } from 'react';

// Style
import '../styles/WingsBackground.scss';

// Assets
import wingsMobileBot from '../assets/images/wings-mobile-bot.png';
import wingsMobileTop from '../assets/images/wings-mobile-top.png';
import wingsWebBot from '../assets/images/wings-web-bot.png';
import wingsWebTop from '../assets/images/wings-web-top.png';

function WingsBackground(): ReactElement {
  return (
    <div className="wings-div">
      <img
        className="wings-web-top"
        src={wingsWebTop}
        alt="Alas superiores"
      />
      <img
        className="wings-web-bot"
        src={wingsWebBot}
        alt="Alas inferiores"
      />
      <img
        className="wings-mobile-top"
        src={wingsMobileTop}
        alt="Alas superiores"
      />
      <img
        className="wings-mobile-bot"
        src={wingsMobileBot}
        alt="Alas inferiores"
      />
    </div>
  );
}

export default WingsBackground;
