import React, { ReactElement, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import '../styles/ImageLoaderStyles.scss';

// Interfaces
import { ImageLoaderProps } from '../interfaces';

function ImageLoader(props: ImageLoaderProps): ReactElement {
  const {
    src,
    alt,
    className,
    onClick,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className={`image-loader ${className}`}
      onClick={(onClick !== undefined) ? () => onClick(true) : undefined}
    >
      {(isLoading) && (
        <CircularProgress
          className="spinner"
        />
      )}
      <img
        src={src}
        alt={alt}
        className={(isLoading) ? 'hide' : undefined}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}

export default ImageLoader;
