import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';

// Icons
import {
  SearchOutlined,
  ChevronRight,
  SentimentDissatisfied,
} from '@material-ui/icons';

// Context
import GlobalContext from '../store/GlobalContext';

// Interfaces
import {
  StateInterface,
  ContextInterface,
  SubCategoryInterface,
  PartnerInterface,
  CategoryInterface,
  CategoryUrlParams,
  ProductInterface,
} from '../interfaces';

// Components
import SubCategory from '../components/SubCategory';
import SidebarCheckout from '../components/SidebarCheckout';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

// Helpers
import {
  calculateRating,
  compareStrings,
  getAllSubstrings,
  normalizeString,
} from '../helpers/compareStrings';

// Styles
import '../styles/CategoryViewStyles.scss';

// Other Constants
const MIN_RATING = 0.30;

function Category(): ReactElement {
  // Context
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { getSubCategories } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();
  const {
    id,
    idCategory,
    idSubCategory = '',
  } : CategoryUrlParams = useParams();

  // State
  const [partner, setPartner] = useState<PartnerInterface>();
  const [category, setCategory] = useState<CategoryInterface>();
  const [subCategories, setSubCategories] = useState<SubCategoryInterface[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchReady, setSearchReady] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [productsFiltered, setProductsFiltered] = useState<ProductInterface[]>([]);

  // Api Call function
  function apiCall() {
    getSubCategories(
      state.token,
      +id,
      +idCategory,
      state.industryId,
    )
      .then((data) => {
        const searchCategory = data.categories.filter((elem) => elem.id === Number(idCategory));
        if (searchCategory.length > 0) {
          setCategory(searchCategory[0]);
        }
        setPartner(data.partner);
        const newSubCategories: SubCategoryInterface[] = [];
        data.subcategories.forEach((subCategory) => newSubCategories.push({
          id: subCategory.id,
          title: subCategory.name,
          data: subCategory.products,
        }));
        return newSubCategories;
      })
      .then((newSubCategories) => {
        setProducts(newSubCategories.reduce((acc: ProductInterface[], obj) => (acc.concat(obj.data)), []));
        if (idSubCategory.length > 0) {
          const auxSubCategories = newSubCategories.filter((item) => item.id.toString() !== idSubCategory);
          const firstElement = newSubCategories.filter((item) => item.id.toString() === idSubCategory);
          setSubCategories(firstElement.concat(auxSubCategories));
        } else {
          setSubCategories(newSubCategories);
        }
      })
      .catch(() => {
        setShowError(true);
      });
  }

  // Load SubCategories
  useEffect(() => {
    if (!id.match(/^\d+$/)) {
      setShowError(true);
      return;
    }
    if (!idCategory.match(/^\d+$/)) {
      setShowError(true);
      return;
    }
    apiCall();
  }, []);

  useEffect(() => {
    if (subCategories.length > 0) {
      setMounted(true);
    }
  }, [subCategories]);

  // Search products algorithm
  useEffect(() => {
    if (search.length > 0) {
      setSearchReady(false);
      const searchSubStrings = getAllSubstrings(normalizeString(search));
      const newProducts = products.filter(
        (prod) => calculateRating(searchSubStrings, `${prod.name} ${prod.brand}`) > MIN_RATING,
      );
      newProducts.sort((prod1, prod2) => compareStrings(search, searchSubStrings,
        `${prod1.name} ${prod1.brand}`, `${prod2.name} ${prod2.brand}`));
      setProductsFiltered(newProducts);
      setSearchReady(true);
    } else {
      setSearchReady(false);
    }
  }, [search]);

  if (mounted) {
    return (
      <div className="category-view-container">
        <Navbar
          back
          backText={t('categoryView.back')}
          backFunction={() => history.push(`/catalog/${partner.id}`)}
        />
        <Sidebar />
        <SidebarCheckout />
        <div className="info-bar">
          <div className="info1">
            <ChevronRight className="icon" />
            <div className="label-container">
              <p className="p-label">
                {category.name.toUpperCase()}
              </p>
            </div>
          </div>
          <div className="info2">
            <input
              type="text"
              className="search-input"
              placeholder={t('partnersView.searchInput')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchOutlined className="search-icon" />
          </div>
        </div>
        {(searchReady) ? (
          <div className="sub-categories">
            <SubCategory
              startsExpanded
              partner={partner}
              isFromSearch
              searchResponse={productsFiltered}
              searchText={search}
            />
          </div>
        ) : (
          <div className="sub-categories">
            {subCategories.map((subCategory) => (
              <SubCategory
                key={subCategory.id}
                startsExpanded={idSubCategory === subCategory.id.toString()}
                subCategory={subCategory}
                partner={partner}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="category-view-container">
      <Navbar
        back
        backText={t('categoryView.back')}
        backFunction={() => history.push(`/catalog/${partner.id}`)}
      />
      {(showError) && (
        <div className="modal">
          <div className="modal-content2">
            <div className="modal-main2">
              <SentimentDissatisfied className="icon-face" />
              <p className="modal-title2">
                {t('catalogView.modalError.title')}
              </p>
              <p className="modal-message">
                {t('catalogView.modalError.message1')}
              </p>
              <p className="modal-message">
                {t('catalogView.modalError.message2')}
              </p>
              <p className="back" onClick={() => history.push(`/catalog/${partner.id}`)}>
                {t('catalogView.modalError.back')}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Category;
