import React, {
  ReactElement,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

// External pkgs
import Select from 'react-select';

// Styles
import '../styles/SignUpStyles.scss';

// Icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { ChevronRight } from '@material-ui/icons';

// Assets
import logoWhite from '../assets/images/dealing.png';

// Context
import GlobalContext from '../store/GlobalContext';

// Interfaces
import {
  ContextInterface,
  StateInterface,
  SectorInterface,
  ResponseSII,
  StoresResponseExists,
  SelectOption,
} from '../interfaces';

// Components
import WingsBackground from '../components/WingsBackground';
import Alert from '../components/Alert';

// Helpers
import emailRegex from '../helpers/emailRegex';
import rutRegex from '../helpers/rutRegex';
import validatePassword from '../helpers/validatePassword';
import validateRut from '../helpers/validateRut';

function SignUpView(): ReactElement {
  // Context
  const { context, state }: { context: ContextInterface, state: StateInterface } = useContext(GlobalContext);
  const {
    verifyEmail,
    getStores,
    signUp,
    getSectors,
    confirmEmail,
  } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  // bool for open/close eye (password visible/not visible)
  const [openEye, setOpenEye] = useState<boolean>(false);

  // user email state
  const [email, setEmail] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

  // user password state
  const [password, setPassword] = useState<string>('');
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);

  // store rut state
  const [rut, setRut] = useState<string>('');
  const [isValidRut, setIsValidRut] = useState<boolean>(false);

  // Set blank button when email exists
  const [blankButton, setBlankButton] = useState<boolean>(false);

  // for define blank/no blank button
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const [storeOrder, setStoreOrder] = useState<string>('');
  const [storeOrders, setStoreOrders] = useState<string[]>([]);
  const [storeReason, setStoreReason] = useState<string>('');
  const [storeExists, setStoreExists] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [checkedRut, setCheckedRut] = useState<boolean>(false);
  const [, setCorrectSignUpModal] = useState<boolean>(false);
  // const [industries, setIndustries] = useState<string[]>([]);
  // const [actualIndustry, setActualIndustry] = useState<number>(0);

  // Sector list
  const [sectors, setSectors] = useState<SectorInterface[]>([]);
  const [storeSector, setStoreSector] = useState<SectorInterface>({ id: -1, name: '' });
  const [searchSector, setSearchSector] = useState<string>('');

  const [selectedOrder, setSelectedOrder] = useState<SelectOption>({ value: -1, label: '' });

  useEffect(() => {
    setSearchSector(storeSector.name);
  }, [storeSector]);

  const alertTypes = {
    errorSectors: 'errorSectors',
    errorSignUp: 'errorSignUp',
    errorEmail: 'errorEmail',
    emailInUse: 'emailInUse',
    successSignUp: 'successSignUp',
    errorCheckRut: 'errorCheckRut',
    errorConfirm: 'errorConfirm',
  };

  function checkSII(checkRut: string): void {
    getStores(checkRut.replace('k', 'K'))
      .then((res) => {
        setCheckedRut(true);
        setStoreExists(res.exists);
        if (!res.exists) {
          setStoreName('');
          setStoreSector({ id: -1, name: '' });
          const dataSII = res.rut[0] as ResponseSII;
          if (dataSII.inicioActividades) {
            setStoreReason(dataSII.razonSocial);
            if (dataSII.actividades.length > 0) {
              setStoreOrder(dataSII.actividades[0].glosa);
              setSelectedOrder({ value: 0, label: dataSII.actividades[0].glosa });
            }
            setStoreOrders(dataSII.actividades.map((elem) => elem.glosa));
            setIsValidRut(true);
          } else {
            setStoreReason('');
            setStoreOrder('');
            setStoreOrders([]);
            setIsValidRut(false);
          }
        } else {
          const dataStore = res.rut[0] as StoresResponseExists;
          setStoreName(dataStore.name);
          setStoreSector({ id: 0, name: dataStore.sector });
          setStoreReason(dataStore.businessName);
          setStoreOrder(dataStore.order);
          setStoreOrders([dataStore.order]);
          setIsValidRut(true);
        }
      })
      .catch(() => {
        setAlertMessage(alertTypes.errorCheckRut);
        setOpenAlert(true);
      });
  }

  // Load Stores and Sectors
  useEffect(() => {
    // setIndustries(['Almacenes', 'Dentistas']);
    getSectors()
      .then((data) => setSectors(data))
      .catch(() => {
        setAlertMessage(alertTypes.errorSectors);
        setOpenAlert(true);
      });
  }, []);

  useEffect(() => {
    setCheckedRut(false);
    if (validateRut(rut)) {
      setIsValidRut(true);
    } else {
      setStoreName('');
      setStoreSector({ id: -1, name: '' });
      setStoreExists(false);
      setStoreReason('');
      setStoreOrder('');
      setStoreOrders([]);
      setIsValidRut(false);
    }
  }, [rut]);

  useEffect(() => {
    if (emailRegex.test(email)) {
      setIsValidEmail(true);
    } else if (email) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (validatePassword(password)) {
      setIsValidPassword(true);
    } else if (password) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(false);
    }
  }, [password]);

  useEffect(() => {
    if (
      name
      && lastName
      && isValidEmail
      && isValidPassword
      && isValidRut
      && storeName
      && storeSector.name
      && storeOrder
      && storeOrders.length > 0
      && storeReason) {
      setBlankButton(true);
    } else {
      setBlankButton(false);
    }
  }, [
    name,
    lastName,
    isValidEmail,
    isValidPassword,
    isValidRut,
    storeName,
    storeReason,
    storeOrder,
    storeOrders,
    storeSector,
  ]);

  function handleSubmit() {
    verifyEmail(email)
      .then(() => {
        const user = {
          firstName: name,
          lastName,
          email,
          password,
        };
        const store = {
          storeName,
          rut,
          storeSector: storeSector.name,
          order: storeOrder,
          businessName: storeReason,
          storeExists,
        };
        signUp(user, store)
          .then((response) => {
            if (response) {
              setCorrectSignUpModal(true);
              setToken(response);
            }
          })
          .catch(() => {
            setAlertMessage(alertTypes.errorSignUp);
            setOpenAlert(true);
          });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAlertMessage(alertTypes.emailInUse);
          setOpenAlert(true);
        } else {
          setAlertMessage(alertTypes.errorEmail);
          setOpenAlert(true);
        }
      });
  }

  useEffect(() => {
    if (token.length > 0) {
      confirmEmail(token, state.user)
        .catch(() => {
          setAlertMessage(alertTypes.errorConfirm);
          setOpenAlert(true);
        });
    }
  }, [token]);

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (blankButton) {
          handleSubmit();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  function closeAlert(value: boolean): void {
    setOpenAlert(value);
    setAlertMessage('');
  }

  function closeErrorConfirm(value: boolean): void {
    closeAlert(value);
    history.push('/login');
  }

  function renderAlert() {
    switch (alertMessage) {
      case alertTypes.errorSectors:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeAlert}
          >
            <p>
              {t('signUpView.alert.errorSectors.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.errorSectors.line2')}
            </p>
          </Alert>
        );
      case alertTypes.emailInUse:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeAlert}
          >
            <p>
              {t('signUpView.alert.emailInUse.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.emailInUse.line2')}
            </p>
          </Alert>
        );
      case alertTypes.errorEmail:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeAlert}
          >
            <p>
              {t('signUpView.alert.errorEmail.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.errorEmail.line2')}
            </p>
          </Alert>
        );
      case alertTypes.errorCheckRut:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeAlert}
          >
            <p>
              {t('signUpView.alert.errorCheckRut.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.errorCheckRut.line2')}
            </p>
          </Alert>
        );
      case alertTypes.errorSignUp:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeAlert}
          >
            <p>
              {t('signUpView.alert.errorSignUp.line1')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.errorSignUp.line2')}
            </p>
          </Alert>
        );
      case alertTypes.errorConfirm:
        return (
          <Alert
            buttonText={t('signUpView.alert.buttonText')}
            buttonFunction={closeErrorConfirm}
          >
            <p>
              {t('signUpView.alert.errorConfirm.line1')}
            </p>
            <p>
              {t('signUpView.alert.errorConfirm.line2')}
            </p>
            <p>
              &nbsp;
            </p>
            <p className="bold">
              {t('signUpView.alert.errorConfirm.line3')}
            </p>
          </Alert>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      <div className="signup-container">
        {(openAlert) && renderAlert()}
        <WingsBackground />
        <button
          type="button"
          className="back-link"
          onClick={() => history.push('/login')}
        >
          <ChevronRight className="icon" />
        </button>
        <div
          className="landing-link"
          onClick={() => history.push('/')}
        >
          <img
            className="logo"
            src={logoWhite}
            alt="logo"
          />
        </div>
        <div className="data-container">
          <div className="user-data">
            <div className="signup-label">
              <ChevronRight className="icon" />
              <p className="message">
                {t('signUpView.label1')}
              </p>
            </div>
            <div className="inputs-container">
              <input
                value={name}
                type="text"
                className="signup-input"
                onChange={(e) => setName(e.target.value)}
                placeholder={t('signUpView.userData.name')}
              />
              <input
                value={lastName}
                type="text"
                className="signup-input"
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t('signUpView.userData.lastName')}
              />
              {(isValidEmail)
                ? (
                  <div className="input-container">
                    <input
                      value={email}
                      type="email"
                      className="signup-input2 valid"
                      placeholder={t('signUpView.userData.email')}
                      onChange={(e) => setEmail(e.target.value.trim())}
                      autoFocus
                      name="email"
                    />
                  </div>
                ) : (
                  <div className="input-container">
                    {(email.length === 0)
                      ? (
                        <input
                          value={email}
                          type="email"
                          className="signup-input2"
                          placeholder={t('signUpView.userData.email')}
                          onChange={(e) => setEmail(e.target.value.trim())}
                          name="email"
                        />
                      ) : (
                        <div className="input-container">
                          <input
                            value={email}
                            type="email"
                            className="signup-input2"
                            placeholder={t('signUpView.userData.email')}
                            onChange={(e) => setEmail(e.target.value.trim())}
                            autoFocus
                            name="email"
                          />
                          <p className="not-valid-email">
                            {t('signUpView.userData.notValidEmail')}
                          </p>
                        </div>
                      )}
                  </div>
                )}
              {(isValidPassword)
                ? (
                  <div className="input-container">
                    <input
                      value={password}
                      type={openEye ? 'text' : 'password'}
                      className="signup-input2 valid"
                      placeholder={t('signUpView.userData.password')}
                      onChange={(e) => setPassword(e.target.value)}
                      autoFocus
                    />
                    {(openEye)
                      ? (
                        <VisibilityOutlinedIcon
                          className="icon-eye"
                          onClick={() => setOpenEye(false)}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          className="icon-eye"
                          onClick={() => setOpenEye(true)}
                        />
                      )}
                  </div>
                ) : (
                  <div className="input-container">
                    {(password.length === 0)
                      ? (
                        <input
                          value={password}
                          type={openEye ? 'text' : 'password'}
                          className="signup-input2"
                          placeholder={t('signUpView.userData.password')}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      ) : (
                        <div className="input-container">
                          <input
                            value={password}
                            type={openEye ? 'text' : 'password'}
                            className="signup-input2"
                            placeholder={t('signUpView.userData.password')}
                            onChange={(e) => setPassword(e.target.value)}
                            autoFocus
                          />
                          <p className="not-valid-email">
                            {t('signUpView.userData.infoPassword')}
                          </p>
                        </div>
                      )}
                    {(openEye)
                      ? (
                        <VisibilityOutlinedIcon
                          className="icon-eye"
                          onClick={() => setOpenEye(false)}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          className="icon-eye"
                          onClick={() => setOpenEye(true)}
                        />
                      )}
                  </div>
                )}
            </div>
          </div>
          <div className="store-data">
            <div className="signup-label">
              <ChevronRight className="icon" />
              <p className="message">
                {t('signUpView.label2')}
              </p>
            </div>
            {(isValidRut)
              ? (
                <div className="inputs-container">
                  <div className="input-container">
                    <input
                      value={rut}
                      type="text"
                      className="signup-input2 valid"
                      placeholder={t('signUpView.storeData.id')}
                      onChange={(e) => setRut(rutRegex(e.target.value))}
                      onBlur={(!checkedRut) ? () => checkSII(rut) : undefined}
                      autoFocus
                      name="rut"
                    />
                  </div>
                  <input
                    value={storeName}
                    type="text"
                    className="signup-input"
                    onChange={(e) => setStoreName(e.target.value)}
                    placeholder={t('signUpView.storeData.name')}
                    readOnly={storeExists}
                  />
                  <Select
                    className="select-container"
                    classNamePrefix="select"
                    isSearchable
                    isDisabled={storeExists}
                    value={storeSector.id >= 0 ? ({ value: storeSector.id, label: storeSector.name }) : null}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    placeholder={t('signUpView.storeData.location')}
                    options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                    onChange={(e) => { if (e) { setStoreSector({ id: e.value, name: e.label }); } }}
                    noOptionsMessage={() => t('signUpView.storeData.noMatch')}
                    onFocus={() => setSearchSector(storeSector.name)}
                    onMenuClose={() => setSearchSector(storeSector.name)}
                    defaultInputValue={searchSector}
                    inputValue={searchSector}
                    onInputChange={(text) => setSearchSector(text)}
                  />
                  {(storeOrders.length < 2) ? (
                    <input
                      value={storeOrder}
                      type="text"
                      className="signup-input"
                      onChange={(e) => setStoreOrder(e.target.value)}
                      placeholder={t('signUpView.storeData.reason')}
                      readOnly
                    />
                  ) : (
                    <Select
                      className="select-container"
                      classNamePrefix="select-order"
                      isSearchable={false}
                      value={selectedOrder}
                      onChange={(e) => {
                        if (e) {
                          setSelectedOrder(e);
                          setStoreOrder(e.label);
                        }
                      }}
                      options={storeOrders.map((order, i) => ({ value: i, label: order }))}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      placeholder={t('signUpView.storeData.reason')}
                    />
                  )}
                </div>
              ) : (
                <div className="inputs-container">
                  <div className="input-container">
                    {(rut.length === 0)
                      ? (
                        <input
                          value={rut}
                          type="text"
                          className="signup-input2"
                          placeholder={t('signUpView.storeData.id')}
                          onChange={(e) => setRut(rutRegex(e.target.value))}
                          name="rut"
                        />
                      ) : (
                        <div className="input-container">
                          <input
                            value={rut}
                            type="text"
                            className="signup-input2"
                            placeholder={t('signUpView.storeData.id')}
                            onChange={(e) => setRut(rutRegex(e.target.value))}
                            autoFocus
                            name="rut"
                          />
                          <p className="not-valid-email">
                            {t('signUpView.storeData.notValidId')}
                          </p>
                        </div>
                      )}
                  </div>
                  <input
                    value={storeName}
                    type="text"
                    className="signup-input not-valid"
                    placeholder={t('signUpView.storeData.name')}
                    readOnly
                  />
                  <Select
                    className="select-container"
                    classNamePrefix="select-disabled"
                    isSearchable
                    isDisabled
                    value={null}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    placeholder={t('signUpView.storeData.location')}
                    options={sectors.map((sector) => ({ value: sector.id, label: sector.name }))}
                    onChange={(e) => { if (e) { setStoreSector({ id: e.value, name: e.label }); } }}
                    noOptionsMessage={() => t('signUpView.storeData.noMatch')}
                  />
                  <input
                    value={storeReason}
                    type="text"
                    className="signup-input not-valid"
                    placeholder={t('signUpView.storeData.reason')}
                    readOnly
                  />
                </div>
              )}
          </div>
        </div>
        <button
          className={blankButton ? 'confirm-button-white' : 'confirm-button'}
          type="button"
          onClick={blankButton ? () => handleSubmit() : null}
        >
          {t('signUpView.button')}
        </button>
        <p className="accept-label">
          {t('signUpView.acceptLabel')}
        </p>
        <div>
          <a
            className="accept-link"
            href="https://dealing-legal.s3.amazonaws.com/terminosycondiciones.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {t('signUpView.terms')}
          </a>
          <p className="accept-label2">
            {t('signUpView.and')}
          </p>
          <a
            className="accept-link"
            href="https://dealing-legal.s3.amazonaws.com/politicas.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {t('signUpView.politics')}
          </a>
        </div>
      </div>
      {/* {(correctSignUpModal) && (
        <div className="modal-signup">
          <div className="modal-content">
            <div className="modal-main">
              <p className="modal-title">
                {t('signUpView.industries.pickOne')}
              </p>
              <p className="modal-description">
                {t('signUpView.industries.infoToChange')}
              </p>
              {industries.map((industry, i) => (
                <div
                  className={(actualIndustry === i) ? 'industry' : 'industry none-interest'}
                  key={i.toString()}
                  onClick={() => setActualIndustry(i)}
                >
                  {industry}
                </div>
              ))}
              <button
                className="modal-button"
                type="button"
                onClick={() => history.push('/')}
              >
                <p>
                  {t('signUpView.industries.accept')}
                </p>
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default SignUpView;
