import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import {
  ArrowRightSharp,
  AddCircle,
  ChevronRight,
} from '@material-ui/icons';

// Interfaces
import { CategoryProps } from '../interfaces';

// Style
import '../styles/CategoryStyles.scss';

// Components
import Product from './Product';

// Helpers
import useMediaQuery from '../helpers/screenWidth';

function Category(props: CategoryProps): ReactElement {
  const {
    category,
    partner,
    listView = false,
  } = props;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  const [width] = useMediaQuery();

  const buttonRight = document.getElementById(`slideRight${category.id}`);
  const buttonLeft = document.getElementById(`slideLeft${category.id}`);

  const buttonContainer = document.getElementById(`container${category.id}`);

  const toCategory = () => {
    history.push(`/catalog/${partner.id}/category/${category.id}`);
  };

  if (buttonRight) {
    if (buttonContainer) {
      if (buttonContainer.scrollLeft === buttonContainer.scrollWidth - buttonContainer.clientWidth) {
        buttonRight.classList.add('none');
      }
    }
    buttonRight.onclick = function rightClick() {
      // if scroll is in max to the left
      if (buttonContainer.scrollLeft !== buttonContainer.scrollWidth - buttonContainer.clientWidth) {
        buttonContainer.scrollLeft += (width * 0.121 + 2) * 6;
        buttonLeft.classList.remove('none');
        if (buttonContainer.scrollLeft === buttonContainer.scrollWidth - buttonContainer.clientWidth) {
          buttonRight.classList.add('none');
        }
      }
    };
  }

  if (buttonLeft) {
    buttonLeft.onclick = function leftClick() {
      if (buttonContainer.scrollLeft !== 0) {
        buttonContainer.scrollLeft -= (width * 0.121 + 2) * 6;
        buttonRight.classList.remove('none');
        if (buttonContainer.scrollLeft === 0) {
          buttonLeft.classList.add('none');
        }
      }
    };
  }

  if (listView) {
    return (
      <div
        className="category-list-container"
        onClick={toCategory}
      >
        <p>
          {category.name}
        </p>
      </div>
    );
  }

  return (
    <div className="category-container">
      <div className="name-link">
        <p
          className="name"
          onClick={toCategory}
        >
          <ArrowRightSharp className="icon" />
          {category.name}
        </p>
        <button
          type="button"
          className="link"
          onClick={toCategory}
        >
          {`${t('catalogView.category.seeMore')} +`}
        </button>
      </div>
      <div className="products-container">
        <button
          id={`slideLeft${category.id}`}
          type="button"
          className="arrow-button left none"
        >
          <ChevronRight className="icon" />
        </button>
        <div
          className="products"
          id={`container${category.id}`}
        >
          {category.products.map((product, i) => (
            <Product
              key={product.id}
              index={i}
              product={product}
              partner={partner}
            />
          ))}
          <div
            className="add-container"
            onClick={toCategory}
          >
            <AddCircle className="add-icon" />
            <p className="add-message">
              {t('catalogView.category.seeMore')}
            </p>
          </div>
        </div>
        <button
          id={`slideRight${category.id}`}
          type="button"
          className="arrow-button right"
        >
          <ChevronRight className="icon" />
        </button>
      </div>
    </div>
  );
}

export default Category;
