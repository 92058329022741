import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import {
  Close,
  InfoOutlined,
  SentimentDissatisfied,
} from '@material-ui/icons';

// Interfaces
import {
  ContextInterface,
  CreditsInterface,
  ModalProps,
  StateInterface,
} from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

// Styles
import '../styles/CreditsModalStyles.scss';

// Components
import Alert from './Alert';

// Helpers
import formatNumber from '../helpers/formatNumber';

function CreditsModal(props: ModalProps): ReactElement {
  // Props
  const {
    visible,
    creditsFromCart = [],
    isFromPurchasesHistory = false,
  } = props;

  // Context
  const { state, context } : { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { getCredits } = context;

  // Translation
  const { t } = useTranslation();

  // States
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [credits, setCredits] = useState<CreditsInterface[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [failedResponse, setFailedResponse] = useState<boolean>(false);

  const closeModal = () => {
    visible(false);
    setOpenModal(false);
  };

  // Partner name without id number
  function partnerName(name : string): string {
    return (name.split('.')[1])
      ? name.split('.')[1].substring(1)
      : name;
  }

  useEffect(() => {
    if (creditsFromCart.length === 0) {
      getCredits(state)
        .then((response) => {
          setCredits(response);
          setMounted(true);
        })
        .catch(() => {
          setFailedResponse(true);
        });
    } else {
      setCredits(creditsFromCart);
      setMounted(true);
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('myModal');
        if (event.target === modal) {
          closeModal();
        }
      };
    }
    const listener = (event: { code: string; }) => {
      if (state.isLoading) {
        return;
      }
      if (event.code === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [openModal]);

  if (failedResponse) {
    return (
      <Alert
        buttonText={t('userProfileModal.alert.button')}
        buttonFunction={visible}
      >
        <p>
          {t('creditsModal.failedResponse.line1')}
        </p>
        <p className="bold">
          {t('creditsModal.failedResponse.line2')}
        </p>
      </Alert>
    );
  }

  if (!mounted) {
    return (
      <div
        className="modal-credits"
      >
        <div className="content">
          <Close
            id="modal-close"
            onClick={() => closeModal()}
          />
          <div className="main">
            <p className="title">
              {t('creditsModal.title')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (mounted && credits.length === 0) {
    return (
      <div
        id="myModal"
        className="modal-credits"
      >
        <div className="content">
          <Close
            id="modal-close"
            onClick={() => closeModal()}
          />
          <div className="main">
            <p className="title">
              {t('creditsModal.title')}
            </p>
            <div className="none-credits-container">
              <SentimentDissatisfied className="icon" />
              <p className="text1">
                {t('creditsModal.noneCredits1')}
              </p>
              <p className="text2">
                {t('creditsModal.noneCredits2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="myModal"
      className="modal-credits"
    >
      <div className="content">
        <Close
          id="modal-close"
          onClick={() => closeModal()}
        />
        <div className="main">
          <p className="title">
            {t('creditsModal.title')}
          </p>
          <div className="info-container">
            {(creditsFromCart.length === 0) && (
              <InfoOutlined className="icon" />
            )}
            {(creditsFromCart.length > 0)
              ? (
                <p className="info-from-cart">
                  {(isFromPurchasesHistory)
                    ? t('creditsModal.informationFromPurchasesHistory')
                    : t('creditsModal.informationFromCart')}
                </p>
              ) : (
                <p className="info">
                  {t('creditsModal.information')}
                </p>
              )}
          </div>
          <div className="credits-container">
            {credits.map((credit) => (
              <div
                key={credit.id}
                className="credit-container"
              >
                <img
                  src={credit.partnerImage}
                  alt="Créditos Dealing"
                  className="logo"
                />
                <p className="amount">
                  {formatNumber(credit.dealingCreditsAmount, '$ ', '')}
                </p>
                <p className="info">
                  {`${t('creditsModal.valid')} 
                  ${(credit.partnerName !== 'Dealing')
                    ? `${t('creditsModal.onePartner')} ${partnerName(credit.partnerName)}`
                    : t('creditsModal.dealingCredit')}
                  ${(credit.dealingCreditsExpiration)
                      ? `${t('creditsModal.expiration')} ${credit.dealingCreditsExpiration}`
                      : ''}`}
                </p>
                <p className="info bold">
                  {`${t('creditsModal.minBuy')} ${formatNumber(credit.minimumAmountOfMoney, '$ ', '')}`}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditsModal;
