import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import '../styles/PasswordRecoveryStyles.scss';

// Icons
import { ChevronRight, LockOutlined } from '@material-ui/icons';

// Components
import WingsBackground from '../components/WingsBackground';
import ContactUsModal from '../components/ContactUsModal';

// Helpers
import emailRegex from '../helpers/emailRegex';
import { ContextInterface } from '../interfaces';
import GlobalContext from '../store/GlobalContext';

function PasswordRecoveryView(): ReactElement {
  // context
  const { context }: { context: ContextInterface } = useContext(GlobalContext);
  const { passwordRecovery } = context;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

  // Bool for open/close modal block
  const [openModalCorrect, setOpenModalCorrect] = useState<boolean>(false);
  const [openModalIncorrect, setOpenModalIncorrect] = useState<boolean>(false);
  const [openModalProblem, setOpenModalProblem] = useState<boolean>(false);
  const [inSubmit, setInSubmit] = useState<boolean>(false);
  const [inModalCorrect, setInModalCorrect] = useState<boolean>(false);
  const [inModalIncorrect, setInModalIncorrect] = useState<boolean>(false);

  // set blank button when email exists
  const [blankButton, setBlankButton] = useState<boolean>(false);

  useEffect(() => {
    if (emailRegex.test(email)) {
      setIsValidEmail(true);
      setBlankButton(true);
    } else if (email) {
      setIsValidEmail(false);
      setBlankButton(false);
    } else {
      setIsValidEmail(false);
      setBlankButton(false);
    }
  }, [email]);

  useEffect(() => {
    if (openModalProblem) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: { target: unknown; }) => {
        const modal = document.getElementById('modalProblem');
        if (event.target === modal) {
          setOpenModalProblem(false);
        }
      };
    }
  }, [openModalProblem]);

  function setModalIncorrect(b: boolean) {
    setOpenModalIncorrect(b);
    setInSubmit(false);
    setInModalIncorrect(b);
  }

  function handleSubmit() {
    setInSubmit(true);
    passwordRecovery(email)
      .then((res) => {
        if (res) {
          setInModalCorrect(true);
          setInSubmit(false);
          setOpenModalCorrect(true);
        } else {
          setModalIncorrect(true);
        }
      })
      .catch(() => {
        setModalIncorrect(true);
      });
  }

  useEffect(() => {
    if (inSubmit) {
      return null;
    }
    const listener = (event: { code: string; }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (blankButton && !inModalCorrect && !inModalIncorrect) {
          handleSubmit();
        } else if (inModalCorrect) {
          history.push('/login');
        } else if (inModalIncorrect) {
          setModalIncorrect(false);
          setInModalIncorrect(false);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return (
    <div className="password-recovery-container">
      <WingsBackground />
      <button
        type="button"
        className="back-link"
        onClick={() => history.push('/login')}
      >
        <ChevronRight className="icon" />
      </button>
      <LockOutlined className="lock-icon" />
      <p className="title">
        {t('passwordRecoveryView.title')}
      </p>
      <p className="message1">
        {t('passwordRecoveryView.message1')}
      </p>
      <p className="message2">
        {t('passwordRecoveryView.message2')}
      </p>
      <p className="message-mobile1">
        {t('passwordRecoveryView.messageMobile1')}
      </p>
      <p className="message-mobile2">
        {t('passwordRecoveryView.messageMobile2')}
      </p>
      <div className="input-container">
        <input
          value={email}
          type="email"
          className={(isValidEmail) ? 'recovery-email valid' : 'recovery-email'}
          placeholder={t('passwordRecoveryView.email')}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          name="email"
        />
        {(!isValidEmail && email.length !== 0)
          && (
            <p className="not-valid-email">
              {t('passwordRecoveryView.notValidEmail')}
            </p>
          )}
      </div>
      <button
        className={(blankButton) ? 'web-confirm-button-white' : 'web-confirm-button'}
        type="button"
        onClick={(blankButton) ? (() => handleSubmit()) : undefined}
      >
        {t('passwordRecoveryView.button')}
      </button>
      <button
        className={(blankButton) ? 'mobile-confirm-button-white' : 'mobile-confirm-button'}
        type="button"
        onClick={(blankButton) ? (() => handleSubmit()) : undefined}
      >
        {t('passwordRecoveryView.button')}
      </button>
      <p className="problem-message">
        {t('passwordRecoveryView.problemMessage')}
      </p>
      <button
        type="button"
        onClick={() => setOpenModalProblem(true)}
        className="problem-link"
      >
        {t('passwordRecoveryView.problemLink')}
      </button>
      {(openModalCorrect) && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-main">
              <p className="modal-title-correct">
                {t('passwordRecoveryView.modalCorrect.message')}
              </p>
              <button
                className="modal-button"
                type="button"
                onClick={() => history.push('/login')}
              >
                <p>
                  {t('passwordRecoveryView.modalCorrect.button')}
                </p>
              </button>
            </div>
          </div>
        </div>
      )}
      {(openModalProblem) && (
        <ContactUsModal
          visible={setOpenModalProblem}
          passwordRecovery
        />
      )}
      {(openModalIncorrect) && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-main">
              <p className="modal-title">
                {t('passwordRecoveryView.modalIncorrect.message')}
              </p>
              <p className="modal-title2">
                {t('passwordRecoveryView.modalIncorrect.message2')}
              </p>
              <button
                className="modal-button"
                type="button"
                onClick={() => setOpenModalIncorrect(false)}
              >
                <p>
                  {t('passwordRecoveryView.modalIncorrect.button')}
                </p>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordRecoveryView;
