import { ProductsBySubcategory, SearchResponse } from '../interfaces';

const groupBySubCategory = (results: SearchResponse[]): ProductsBySubcategory[] => {
  const grouped = results.reduce((groups: ProductsBySubcategory[], item) => {
    const group = groups.find((i) => i.id === item.subcategories[0].id);
    if (group) {
      group.products.push(item);
    } else {
      groups.push({
        id: item.subcategories[0].id,
        name: item.subcategories[0].name,
        products: [item],
        categoryId: item.subcategories[0].category_id,
        partnerId: item.partner_id,
      });
    }
    return groups;
  }, []);
  return grouped.sort((p1, p2) => p2.products.length - p1.products.length);
};

export default groupBySubCategory;
