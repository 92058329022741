import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import {
  Add,
  Remove,
  InfoOutlined,
  Close,
  ChevronRight,
} from '@material-ui/icons';

// Interfaces
import { ContextInterface, ProductProps, StateInterface } from '../interfaces';

// Context
import GlobalContext from '../store/GlobalContext';

// Style
import '../styles/ProductStyles.scss';

// Components
import ImageLoader from './ImageLoader';

// Helpers
import productUrl from '../helpers/productUrl';
import capitalize from '../helpers/capitalize';
import formatNumber from '../helpers/formatNumber';

// Assets
import discount from '../assets/images/discount.png';

const displayMeasure: { [key: string]: string } = {
  unitario: 'Unidad',
  caja: 'Caja',
  display: 'Display',
  bolsa: 'Bolsa',
};

function Product(props: ProductProps): ReactElement {
  // Context
  const { state, context }: { state: StateInterface, context: ContextInterface } = useContext(GlobalContext);
  const { updateCart } = context;

  const {
    index,
    product,
    partner,
    showProductExtern = false,
    setShowProductExtern,
    cartView = false,
    loadingCart = false,
    setLoadingCart,
    isFirst = false,
  } = props;

  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  // States
  const [quantity, setQuantity] = useState<string>('0');
  const [interest, setInterest] = useState<boolean>(false);
  const [inCart, setInCart] = useState<boolean>(false);
  const [showProduct, setShowProduct] = useState<boolean>(false);
  const [showPromotionInfo, setShowPromotionInfo] = useState<boolean>(false);

  // Constants
  const imageUrl = productUrl(product.barCode, 180, 180);
  // \xB7 = middot
  const name = `${product.brand} \xB7 ${product.name}`;
  const productDisplay: string = displayMeasure[product.displayMeasure];
  // If the product has unit discount among it's promotions
  let unitDiscount = false;
  let unitDiscountPrice = 0;
  product.specific.promotions?.forEach((promo) => {
    // If the current promo it's a Unit Discount, save the params
    if (promo.type === 1) {
      unitDiscount = true;
      unitDiscountPrice = promo.promoPrice;
    }
  });

  function addRemoveProduct(action: number) {
    if (action === 0) {
      setQuantity((Number(quantity) + 1).toString());
      setInterest(true);
    } else if (Number(quantity) - 1 <= 0) {
      setQuantity('0');
      setInterest(false);
    } else {
      setQuantity((Number(quantity) - 1).toString());
    }
  }

  function TextAbstract(text: string) {
    if (text == null) {
      return '';
    }
    if (text.length <= 35) {
      return text;
    }
    const newText = text.substring(0, 35);
    return `${newText}...`;
  }

  function checkInput(value: string) {
    let newValue = '';
    newValue = value.replace(/\D/g, '');
    if (newValue === '' || (newValue[0] === '0' && newValue[1] === '0')) {
      newValue = '0';
    } else if (newValue[0] === '0') {
      newValue = newValue.slice(1);
    }
    if (Number(newValue) > product.specific.stock) {
      setQuantity(product.specific.stock.toString());
    } else {
      setQuantity(newValue);
    }
    if (newValue !== '0') {
      setInterest(true);
    }
  }

  function toCart(q: string) {
    if (cartView) {
      setLoadingCart(true);
    }
    updateCart(state, {
      ...product,
      quantity: Number(q),
    }, partner, cartView);
    if (q === '0') {
      setQuantity('0');
      setInterest(false);
      setInCart(false);
    } else {
      setInterest(true);
      setInCart(true);
    }
  }

  function toShowProduct(bool: boolean) {
    setShowProduct(bool);
    if (!bool) {
      toCart(quantity);
    }
  }

  function fromShowProductToShowPromotionInfo(bool: boolean, banner = false) {
    if (!banner) {
      if (bool) {
        setShowProduct(false);
        setShowPromotionInfo(true);
      } else {
        setShowPromotionInfo(false);
        setShowProduct(true);
      }
    } else {
      setShowPromotionInfo(bool);
    }
  }

  function productInCart() {
    if (state) {
      if (state.cart) {
        if (state.cart[partner.id]) {
          if (state.cart[partner.id].cart) {
            if (state.cart[partner.id].cart[product.id]) {
              if (state.cart[partner.id].cart[product.id].quantity === Number(quantity)) {
                return true;
              }
            }
          }
        }
      }
    } return false;
  }

  useEffect(() => {
    if (quantity === '' && !inCart) {
      setQuantity('0');
      setInterest(false);
    } else if (quantity === '' && inCart) {
      setQuantity('0');
    }
  }, [quantity]);

  useEffect(() => {
    if (showProduct || showProductExtern || showPromotionInfo) {
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = (event: MouseEvent) => {
        const modal = document.getElementById('product-modal');
        if (event.target === modal && showProduct) {
          setShowProduct(false);
        } else if (event.target === modal && showProductExtern) {
          setShowProductExtern(false);
        } else if (event.target === modal && showPromotionInfo) {
          setShowPromotionInfo(false);
        }
      };
    }
  }, [showProduct, showProductExtern, showPromotionInfo]);

  useEffect(() => {
    if (state.cart) {
      if (state.cart[partner.id]) {
        if (state.cart[partner.id].cart[product.id]) {
          setInCart(true);
          setInterest(true);
          setQuantity(state.cart[partner.id].cart[product.id].quantity.toString());
        } else {
          setInCart(false);
          if (Object.values(state.cart).length === 0) {
            setInterest(false);
            setQuantity('0');
          }
        }
      } else {
        setInCart(false);
        if (Object.values(state.cart).length === 0) {
          setInterest(false);
          setQuantity('0');
        }
      }
    }
  }, [state.cart, partner]);

  useEffect(() => {
    if (cartView && product.quantity > 0 && quantity === '0') {
      setQuantity(product.quantity.toString());
    }
  }, [product]);

  // If the user click on banner product
  if (showProductExtern) {
    return (
      <div className="product-container">
        {(showPromotionInfo)
          ? (
            <div
              className="modal"
              id="product-modal"
            >
              <div className="modal-content">
                <Close
                  className="modal-close"
                  onClick={() => setShowProductExtern(false)}
                />
                <div className="modal-main">
                  <p className="modal-promotions">
                    {t('catalogView.category.product.promotion.title')}
                  </p>
                  {product.specific.promotions.map((promo) => (
                    <div
                      className="specific-promotion"
                      key={promo.promotionId}
                    >
                      <div className="short">
                        {promo.type === 1
                          ? `${promo.shortDescription} ${t('catalogView.category.product.promotion.discount')}`
                          : promo.shortDescription}
                      </div>
                      <div className="long">
                        {promo.longDescription}
                      </div>
                      {(promo.details) && (
                        <div className="details">
                          {promo.details.map((detail, i) => (
                            <div
                              className="detail-container"
                              key={i.toString()}
                            >
                              <p>
                                {t('catalogView.category.product.promotion.ifAdd')}
                              </p>
                              <p className="info">
                                {detail[0]}
                              </p>
                              <p>
                                {t('catalogView.category.product.promotion.youGot')}
                              </p>
                              <p className="info">
                                {detail[1]}
                              </p>
                              <p>
                                {t('catalogView.category.product.promotion.ofDiscount')}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="back-container"
                  onClick={() => fromShowProductToShowPromotionInfo(false)}
                >
                  <ChevronRight className="icon" />
                  <p>
                    {t('catalogView.category.product.promotion.back')}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="modal"
              id="product-modal"
            >
              <div className="modal-content">
                <Close
                  className="modal-close"
                  onClick={() => setShowProductExtern(false)}
                />
                <div className="modal-main">
                  <ImageLoader
                    src={product.image}
                    alt={`${product.brand} - ${product.name}`}
                    className="img"
                  />
                  <p className="modal-brand">
                    {product.brand}
                  </p>
                  <p className="modal-name">
                    {product.name}
                  </p>
                  {(product.specific.promotions.length > 0) && (
                    <div
                      className="modal-promotion"
                      onClick={() => fromShowProductToShowPromotionInfo(true, true)}
                    >
                      <InfoOutlined className="info-icon" />
                      <div className="short-promotion">
                        {product.specific.promotions.map((promo) => (
                          <div key={promo.promotionId}>
                            {promo.shortDescription}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="product-info">
                    <div className="flex-div">
                      <p className="blue">
                        {t('catalogView.category.product.tax')}
                      </p>
                      {(unitDiscount)
                        ? (
                          <div className="promo-container">
                            <p className="price-old">
                              {formatNumber(product.specific.price, '$ ', '')}
                            </p>
                            <p className="blue">
                              {formatNumber(unitDiscountPrice, '$ ', '')}
                            </p>
                          </div>
                        ) : (
                          <p className="blue">
                            {formatNumber(product.specific.price, '$ ', '')}
                          </p>
                        )}
                    </div>
                    <div className="flex-div">
                      <p className="blue">
                        {t('catalogView.category.product.display')}
                      </p>
                      {(productDisplay)
                        ? (
                          <p className="blue">
                            {(productDisplay === 'Unidad')
                              ? `${productDisplay} ${product.weight}${product.measure}`
                              : `${productDisplay} ${product.display} x ${product.weight}${product.measure}`}
                          </p>
                        ) : (
                          <p className="blue">
                            {`${capitalize(product.displayMeasure)} ${product.display} u`}
                          </p>
                        )}
                    </div>
                    <div className="flex-div">
                      <p className="blue">
                        {t('catalogView.category.product.stock')}
                      </p>
                      <p
                        className={(product.specific.stock === parseInt(quantity, 10)) ? 'red' : 'blue'}
                      >
                        {product.specific.stock}
                      </p>
                    </div>
                    {(productDisplay !== 'Unidad') && (
                    <div className="flex-div">
                      <p className="gray">
                        {t('catalogView.category.product.unitFormat')}
                      </p>
                      <p className="gray">
                        {`${product.weight}${product.measure}`}
                      </p>
                    </div>
                    )}
                    {(productDisplay !== 'Unidad') && (
                    <div className="flex-div">
                      <p className="gray">
                        {t('catalogView.category.product.unitPrice')}
                      </p>
                      {(unitDiscount)
                        ? (
                          <div className="promo-container">
                            <p className="price-old-gray">
                              {formatNumber(product.specific.price / product.display, '$ ', '')}
                            </p>
                            <p className="gray">
                              {formatNumber(unitDiscountPrice / product.display, '$ ', '')}
                            </p>
                          </div>
                        ) : (
                          <p className="gray">
                            {formatNumber(product.specific.price / product.display, '$ ', '')}
                          </p>
                        )}
                    </div>
                    )}
                    {(product.description.length > 0) && (
                    <div className="description-container">
                      <p className="label">
                        {t('catalogView.category.product.description')}
                      </p>
                      <p className="text">
                        {product.description}
                      </p>
                    </div>
                    )}
                  </div>
                  <div className="modal-bottom">
                    <div className="modal-total-container">
                      <p className="label">
                        {t('catalogView.category.product.total')}
                      </p>
                      <p className="total">
                        {(unitDiscount)
                          ? formatNumber(parseInt(quantity, 10) * unitDiscountPrice, '$ ', '')
                          : formatNumber(parseInt(quantity, 10) * product.specific.price, '$ ', '')}
                      </p>
                    </div>
                    <div className="modal-user-quantity">
                      <div className="modal-quantity">
                        <div className="add-remove-container">
                          <Remove
                            className={(quantity === '0') ? 'action none' : 'action'}
                            type="button"
                            onClick={(quantity === '0') ? undefined : () => addRemoveProduct(1)}
                          />
                          <input
                            className="quantity"
                            value={quantity}
                            onChange={(value) => checkInput(value.target.value)}
                          />
                          <Add
                            className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
                            type="button"
                            onClick={(Number(quantity) < product.specific.stock)
                              ? () => addRemoveProduct(0) : undefined}
                          />
                        </div>
                      </div>
                      {(inCart)
                        ? (
                          <div className="modal-button-quantity">
                            {(productInCart())
                              ? (
                                <button
                                  className="in-cart"
                                  type="button"
                                >
                                  {t('catalogView.category.product.inCart')}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={(Number(quantity) > 0) ? '' : 'button-remove'}
                                  onClick={() => toShowProduct(false)}
                                >
                                  {(Number(quantity) > 0)
                                    ? t('catalogView.category.product.updateProduct')
                                    : t('catalogView.category.product.removeProduct')}
                                </button>
                              )}
                          </div>
                        ) : (
                          <div className="modal-button-quantity">
                            <button
                              type="button"
                              className={(Number(quantity) > 0) ? '' : 'none'}
                              onClick={(Number(quantity) > 0) ? () => toShowProduct(false) : undefined}
                            >
                              {t('catalogView.category.product.addProduct')}
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }

  if (cartView) {
    return (
      <div className={(isFirst) ? 'product-cart-container none-border' : 'product-cart-container'}>
        {(product.specific.promotions[0]) && (
          <img
            src={discount}
            className="info-promotion"
            alt="discount logo"
            onClick={() => setShowProduct(true)}
          />
        )}
        <ImageLoader
          src={product.image}
          alt={`${product.brand} - ${product.name}`}
          className="img"
          onClick={setShowProduct}
        />
        <div
          className={(product.name.length <= 20) ? 'info' : 'info long-info'}
          onClick={() => setShowProduct(true)}
        >
          <p className="brand">
            {product.brand}
          </p>
          <p className="name">
            {TextAbstract(product.name)}
          </p>
          {(productDisplay)
            ? (
              <p className="brand">
                {(productDisplay === 'Unidad')
                  ? `${productDisplay} ${product.weight}${product.measure}`
                  : `${productDisplay} ${product.display} x ${product.weight}${product.measure}`}
              </p>
            ) : (
              <p className="brand">
                {`${capitalize(product.displayMeasure)} ${product.display} u`}
              </p>
            )}
        </div>
        <div className="product-quantity">
          <div className="add-remove-container">
            <Remove
              className={(quantity === '0') ? 'action none' : 'action'}
              type="button"
              onClick={(quantity === '0') ? undefined : (() => addRemoveProduct(1))}
            />
            <input
              className="quantity"
              value={quantity}
              onChange={(value) => checkInput(value.target.value)}
            />
            <Add
              className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
              type="button"
              onClick={(Number(quantity) < product.specific.stock) ? (() => addRemoveProduct(0)) : undefined}
            />
          </div>
          {(Number(quantity) === product.quantity)
            ? (
              <button
                type="button"
                className="in-cart"
              >
                {t('catalogView.category.product.inCartCartView')}
              </button>
            ) : (
              <button
                type="button"
                className={quantity === '0' ? 'remove' : 'add'}
                onClick={() => toCart(quantity)}
              >
                {quantity === '0'
                  ? t('catalogView.category.product.removeProductCatalog')
                  : t('catalogView.category.product.updateProductCatalog')}
              </button>
            )}
        </div>
        <div className="info-total">
          <p className="name">
            {t('catalogView.category.product.total')}
          </p>
          <p className="brand">
            {(!loadingCart) && formatNumber(product.totalProduct, '$ ', '')}
          </p>
          <p className="name">
            {(!loadingCart) && `${formatNumber(product.totalProduct / product.quantity, '', '/u')}`}
          </p>
        </div>
        {(showProduct) && (
          <div
            className="modal"
            id="product-modal"
          >
            <div className="modal-content">
              <Close
                className="modal-close"
                onClick={() => setShowProduct(false)}
              />
              <div className="modal-main">
                <ImageLoader
                  src={product.image}
                  alt={`${product.brand} - ${product.name}`}
                  className="img"
                />
                <p className="modal-brand">
                  {product.brand}
                </p>
                <p className="modal-name">
                  {product.name}
                </p>
                {(product.specific.promotions.length > 0) && (
                  <div
                    className="modal-promotion"
                    onClick={() => fromShowProductToShowPromotionInfo(true)}
                  >
                    <InfoOutlined className="info-icon" />
                    <div className="short-promotion">
                      {product.specific.promotions.map((promo) => (
                        <div key={promo.promotionId}>
                          {(promo.type === 1)
                            ? `${promo.shortDescription} ${t('catalogView.category.product.promotion.discount')}`
                            : promo.shortDescription}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="product-info">
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.tax')}
                    </p>
                    {(unitDiscount)
                      ? (
                        <div className="promo-container">
                          <p className="price-old">
                            {formatNumber(product.specific.price, '$ ', '')}
                          </p>
                          <p className="blue">
                            {formatNumber(unitDiscountPrice, '$ ', '')}
                          </p>
                        </div>
                      ) : (
                        <p className="blue">
                          {formatNumber(product.specific.price, '$ ', '')}
                        </p>
                      )}
                  </div>
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.display')}
                    </p>
                    {(productDisplay)
                      ? (
                        <p className="blue">
                          {(productDisplay === 'Unidad')
                            ? `${productDisplay} ${product.weight}${product.measure}`
                            : `${productDisplay} ${product.display} x ${product.weight}${product.measure}`}
                        </p>
                      ) : (
                        <p className="blue">
                          {`${capitalize(product.displayMeasure)} ${product.display} u`}
                        </p>
                      )}
                  </div>
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.stock')}
                    </p>
                    <p className="blue">
                      {product.specific.stock}
                    </p>
                  </div>
                  {(productDisplay !== 'Unidad') && (
                    <div className="flex-div">
                      <p className="gray">
                        {t('catalogView.category.product.unitFormat')}
                      </p>
                      <p className="gray">
                        {`${product.weight}${product.measure}`}
                      </p>
                    </div>
                  )}
                  {(productDisplay !== 'Unidad') && (
                    <div className="flex-div">
                      <p className="gray">
                        {t('catalogView.category.product.unitPrice')}
                      </p>
                      {(unitDiscount)
                        ? (
                          <div className="promo-container">
                            <p className="price-old-gray">
                              {formatNumber(product.specific.price / product.display, '$ ', '')}
                            </p>
                            <p className="gray">
                              {formatNumber(unitDiscountPrice / product.display, '$ ', '')}
                            </p>
                          </div>
                        ) : (
                          <p className="gray">
                            {formatNumber(product.specific.price / product.display, '$ ', '')}
                          </p>
                        )}
                    </div>
                  )}
                  {(product.description.length > 0) && (
                    <div className="description-container">
                      <p className="label">
                        {t('catalogView.category.product.description')}
                      </p>
                      <p className="text">
                        {product.description}
                      </p>
                    </div>
                  )}
                </div>
                <div className="modal-bottom">
                  <div className="modal-total-container">
                    <p className="label">
                      {t('catalogView.category.product.total')}
                    </p>
                    <p className="total">
                      {(unitDiscount)
                        ? formatNumber(parseInt(quantity, 10) * unitDiscountPrice, '$ ', '')
                        : formatNumber(parseInt(quantity, 10) * product.specific.price, '$ ', '')}
                    </p>
                  </div>
                  <div className="modal-user-quantity">
                    <div className="modal-quantity">
                      <div className="add-remove-container">
                        <Remove
                          className={(quantity === '0') ? 'action none' : 'action'}
                          type="button"
                          onClick={(quantity === '0') ? undefined : () => addRemoveProduct(1)}
                        />
                        <input
                          className="quantity"
                          value={quantity}
                          onChange={(value) => checkInput(value.target.value)}
                        />
                        <Add
                          className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
                          type="button"
                          onClick={(Number(quantity) < product.specific.stock) ? () => addRemoveProduct(0) : undefined}
                        />
                      </div>
                    </div>
                    {(inCart)
                      ? (
                        <div className="modal-button-quantity">
                          {(productInCart())
                            ? (
                              <button
                                className="in-cart"
                                type="button"
                              >
                                {t('catalogView.category.product.inCart')}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={(Number(quantity) > 0) ? '' : 'button-remove'}
                                onClick={() => toShowProduct(false)}
                              >
                                {(Number(quantity) > 0)
                                  ? t('catalogView.category.product.updateProduct')
                                  : t('catalogView.category.product.removeProduct')}
                              </button>
                            )}
                        </div>
                      ) : (
                        <div className="modal-button-quantity">
                          <button
                            type="button"
                            className={(Number(quantity) > 0) ? '' : 'none'}
                            onClick={(Number(quantity) > 0) ? () => toShowProduct(false) : undefined}
                          >
                            {t('catalogView.category.product.addProduct')}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(showPromotionInfo) && (
          <div
            className="modal"
            id="product-modal"
          >
            <div className="modal-content">
              <Close
                className="modal-close"
                onClick={() => setShowPromotionInfo(false)}
              />
              <div className="modal-main">
                <p className="modal-promotions">
                  {t('catalogView.category.product.promotion.title')}
                </p>
                {product.specific.promotions.map((promo) => (
                  <div
                    className="specific-promotion"
                    key={promo.promotionId}
                  >
                    <div className="short">
                      {(promo.type === 1)
                        ? `${promo.shortDescription} ${t('catalogView.category.product.promotion.discount')}`
                        : promo.shortDescription}
                    </div>
                    <div className="long">
                      {promo.longDescription}
                    </div>
                    {(promo.details) && (
                      <div className="details">
                        {promo.details.map((detail, i) => (
                          <div
                            className="detail-container"
                            key={i.toString()}
                          >
                            <p>
                              {t('catalogView.category.product.promotion.ifAdd')}
                            </p>
                            <p className="info">
                              {detail[0]}
                            </p>
                            <p>
                              {t('catalogView.category.product.promotion.youGot')}
                            </p>
                            <p className="info">
                              {detail[1]}
                            </p>
                            <p>
                              {t('catalogView.category.product.promotion.ofDiscount')}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="back-container"
                onClick={() => fromShowProductToShowPromotionInfo(false)}
              >
                <ChevronRight className="icon" />
                <p>
                  {t('catalogView.category.product.promotion.back')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (state) {
    return (
      <div
        className={index > 0 ? 'product-container' : 'product-container none-left-border'}
      >
        {(product.specific.promotions[0]) && (
          <img
            src={discount}
            className="info-promotion"
            alt="discount logo"
            onClick={(product.specific.stock === 0) ? undefined : () => toShowProduct(true)}
          />
        )}
        <ImageLoader
          src={imageUrl}
          alt={`${product.brand} - ${product.name}`}
          className={(product.specific.stock === 0) ? 'product-image none-stock' : 'product-image'}
          onClick={(product.specific.stock === 0) ? undefined : toShowProduct}
        />
        <div
          className={(product.specific.stock === 0) ? 'info-container none-stock' : 'info-container'}
          onClick={(product.specific.stock === 0) ? undefined : () => toShowProduct(true)}
        >
          <p className="name">
            {TextAbstract(name)}
          </p>
          {(unitDiscount)
            ? (
              <div className="promo-container">
                <p className="price-old">
                  {formatNumber(product.specific.price, '$ ', '')}
                </p>
                <p className="price-new">
                  {formatNumber(unitDiscountPrice, '$ ', '')}
                </p>
              </div>
            ) : (
              <p className="price-normal">
                {formatNumber(product.specific.price, '$ ', '')}
              </p>
            )}
          {(productDisplay)
            ? (
              <p className="type">
                {(productDisplay === 'Unidad')
                  ? `${productDisplay} ${product.weight}${product.measure}`
                  : `${productDisplay} ${product.display} x ${product.weight}${product.measure}`}
              </p>
            ) : (
              <p className="type">
                {`${capitalize(product.displayMeasure)} ${product.display} u`}
              </p>
            )}
        </div>
        {(inCart)
          ? (
            <div className="interest-container">
              <div className="add-remove-container">
                <Remove
                  className={(quantity === '0') ? 'action none' : 'action'}
                  type="button"
                  onClick={(quantity === '0') ? undefined : () => addRemoveProduct(1)}
                />
                <input
                  className="quantity"
                  value={quantity}
                  onChange={(value) => checkInput(value.target.value)}
                />
                <Add
                  className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
                  type="button"
                  onClick={(Number(quantity) < product.specific.stock) ? () => addRemoveProduct(0) : undefined}
                />
              </div>
              {(productInCart())
                ? (
                  <button
                    className="in-cart"
                    type="button"
                  >
                    {t('catalogView.category.product.inCart')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={(quantity === '0') ? 'remove' : 'add'}
                    onClick={() => toCart(quantity)}
                  >
                    {(quantity === '0')
                      ? t('catalogView.category.product.removeProductCatalog')
                      : t('catalogView.category.product.updateProductCatalog')}
                  </button>
                )}
            </div>
          ) : (
            <div className="interest-container">
              {(product.specific.stock === 0)
                ? (
                  <div className="none-stock-message">
                    {t('catalogView.category.product.noneStock')}
                  </div>
                ) : (
                  <div className="interest-container">
                    <div className="add-remove-container">
                      <Remove
                        className={(interest) ? 'action' : 'action none'}
                        type="button"
                        onClick={(interest) ? () => addRemoveProduct(1) : undefined}
                      />
                      <input
                        className="quantity"
                        value={quantity}
                        onChange={(value) => checkInput(value.target.value)}
                      />
                      <Add
                        className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
                        type="button"
                        onClick={(Number(quantity) < product.specific.stock) ? () => addRemoveProduct(0) : undefined}
                      />
                    </div>
                    <button
                      type="button"
                      className="add"
                      onClick={() => toCart(quantity)}
                      disabled={!interest}
                    >
                      {t('catalogView.category.product.addProduct')}
                    </button>
                  </div>
                )}
            </div>
          )}
        {(showProduct) && (
          <div
            className="modal"
            id="product-modal"
          >
            <div className="modal-content">
              <Close
                className="modal-close"
                onClick={() => setShowProduct(false)}
              />
              <div className="modal-main">
                <ImageLoader
                  src={product.image}
                  alt={`${product.brand} - ${product.name}`}
                  className="img"
                />
                <p className="modal-brand">
                  {product.brand}
                </p>
                <p className="modal-name">
                  {product.name}
                </p>
                {(product.specific.promotions.length > 0) && (
                  <div
                    className="modal-promotion"
                    onClick={() => fromShowProductToShowPromotionInfo(true)}
                  >
                    <InfoOutlined className="info-icon" />
                    <div className="short-promotion">
                      {product.specific.promotions.map((promo) => (
                        <div key={promo.promotionId}>
                          {(promo.type === 1)
                            ? `${promo.shortDescription} ${t('catalogView.category.product.promotion.discount')}`
                            : promo.shortDescription}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="product-info">
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.tax')}
                    </p>
                    {(unitDiscount)
                      ? (
                        <div className="promo-container">
                          <p className="price-old">
                            {formatNumber(product.specific.price, '$ ', '')}
                          </p>
                          <p className="blue">
                            {formatNumber(unitDiscountPrice, '$ ', '')}
                          </p>
                        </div>
                      ) : (
                        <p className="blue">
                          {formatNumber(product.specific.price, '$ ', '')}
                        </p>
                      )}
                  </div>
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.display')}
                    </p>
                    {(productDisplay)
                      ? (
                        <p className="blue">
                          {(productDisplay === 'Unidad')
                            ? `${productDisplay} ${product.weight}${product.measure}`
                            : `${productDisplay} ${product.display} x ${product.weight}${product.measure}`}
                        </p>
                      ) : (
                        <p className="blue">
                          {`${capitalize(product.displayMeasure)} ${product.display} u`}
                        </p>
                      )}
                  </div>
                  <div className="flex-div">
                    <p className="blue">
                      {t('catalogView.category.product.stock')}
                    </p>
                    <p
                      className={(product.specific.stock === parseInt(quantity, 10)) ? 'red' : 'blue'}
                    >
                      {product.specific.stock}
                    </p>
                  </div>
                  {(productDisplay !== 'Unidad') && (
                  <div className="flex-div">
                    <p className="gray">
                      {t('catalogView.category.product.unitFormat')}
                    </p>
                    <p className="gray">
                      {`${product.weight}${product.measure}`}
                    </p>
                  </div>
                  )}
                  {(productDisplay !== 'Unidad') && (
                  <div className="flex-div">
                    <p className="gray">
                      {t('catalogView.category.product.unitPrice')}
                    </p>
                    {(unitDiscount)
                      ? (
                        <div className="promo-container">
                          <p className="price-old-gray">
                            {formatNumber(product.specific.price / product.display, '$ ', '')}
                          </p>
                          <p className="gray">
                            {formatNumber(unitDiscountPrice / product.display, '$ ', '')}
                          </p>
                        </div>
                      ) : (
                        <p className="gray">
                          {formatNumber(product.specific.price / product.display, '$ ', '')}
                        </p>
                      )}
                  </div>
                  )}
                  {(product.description.length > 0) && (
                    <div className="description-container">
                      <p className="label">
                        {t('catalogView.category.product.description')}
                      </p>
                      <p className="text">
                        {product.description}
                      </p>
                    </div>
                  )}
                </div>
                <div className="modal-bottom">
                  <div className="modal-total-container">
                    <p className="label">
                      {t('catalogView.category.product.total')}
                    </p>
                    <p className="total">
                      {(unitDiscount)
                        ? formatNumber(parseInt(quantity, 10) * unitDiscountPrice, '$ ', '')
                        : formatNumber(parseInt(quantity, 10) * product.specific.price, '$ ', '')}
                    </p>
                  </div>
                  <div className="modal-user-quantity">
                    <div className="modal-quantity">
                      <div className="add-remove-container">
                        <Remove
                          className={(quantity === '0') ? 'action none' : 'action'}
                          type="button"
                          onClick={(quantity === '0') ? undefined : () => addRemoveProduct(1)}
                        />
                        <input
                          className="quantity"
                          value={quantity}
                          onChange={(value) => checkInput(value.target.value)}
                        />
                        <Add
                          className={(Number(quantity) < product.specific.stock) ? 'action' : 'action none'}
                          type="button"
                          onClick={(Number(quantity) < product.specific.stock) ? () => addRemoveProduct(0) : undefined}
                        />
                      </div>
                    </div>
                    {(inCart)
                      ? (
                        <div className="modal-button-quantity">
                          {(productInCart())
                            ? (
                              <button
                                className="in-cart"
                                type="button"
                              >
                                {t('catalogView.category.product.inCart')}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={(Number(quantity) > 0) ? '' : 'button-remove'}
                                onClick={() => toShowProduct(false)}
                              >
                                {(Number(quantity) > 0)
                                  ? t('catalogView.category.product.updateProduct')
                                  : t('catalogView.category.product.removeProduct')}
                              </button>
                            )}
                        </div>
                      ) : (
                        <div className="modal-button-quantity">
                          <button
                            type="button"
                            className={(Number(quantity) > 0) ? '' : 'none'}
                            onClick={(Number(quantity) > 0) ? () => toShowProduct(false) : undefined}
                          >
                            {t('catalogView.category.product.addProduct')}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(showPromotionInfo) && (
          <div
            className="modal"
            id="product-modal"
          >
            <div className="modal-content">
              <Close
                className="modal-close"
                onClick={() => setShowPromotionInfo(false)}
              />
              <div className="modal-main">
                <p className="modal-promotions">
                  {t('catalogView.category.product.promotion.title')}
                </p>
                {product.specific.promotions.map((promo) => (
                  <div
                    className="specific-promotion"
                    key={promo.promotionId}
                  >
                    <div className="short">
                      {(promo.type === 1)
                        ? `${promo.shortDescription} ${t('catalogView.category.product.promotion.discount')}`
                        : promo.shortDescription}
                    </div>
                    <div className="long">
                      {promo.longDescription}
                    </div>
                    {(promo.details) && (
                      <div className="details">
                        {promo.details.map((detail, i) => (
                          <div
                            className="detail-container"
                            key={i.toString()}
                          >
                            <p>
                              {t('catalogView.category.product.promotion.ifAdd')}
                            </p>
                            <p className="info">
                              {detail[0]}
                            </p>
                            <p>
                              {t('catalogView.category.product.promotion.youGot')}
                            </p>
                            <p className="info">
                              {detail[1]}
                            </p>
                            <p>
                              {t('catalogView.category.product.promotion.ofDiscount')}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="back-container"
                onClick={() => fromShowProductToShowPromotionInfo(false)}
              >
                <ChevronRight className="icon" />
                <p>
                  {t('catalogView.category.product.promotion.back')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
}

export default Product;
